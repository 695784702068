import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';
import { Alert, Box } from '@mantine/core';

import EditCard from 'areas/cards/credit-cards/card-details/edit-card';
import CreditCardActions, {
  CardAction,
} from 'areas/cards/credit-cards/card-details/card-actions';

import 'areas/cards/lithic-frame.css';
import { flexbaseOnboardingClient } from 'services/flexbase-client';
import { ApplicationState } from '../recoil-state/application/product-onboarding';
import { formatCurrency } from '@utilities/formatters';
import { CardFrame } from 'areas/cards/credit-cards/card-details/card-frame';
import {
  CardExpensesTypes,
  CardMonthToDateSpends,
  CardStatus,
  CardType,
  CreditCard,
  IsEmbedUrlResponse,
  ToDateSpend,
} from '@services/flexbase/card.model';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters/format-strings';
import { CardConfirmCancel } from 'areas/cards/credit-cards/card-details/card-confirm-cancel';
import { CardConfirmStatusUpdate } from 'areas/cards/credit-cards/card-details/card-confirm-status-update';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { notifications } from '@mantine/notifications';
import {
  useActivatePersonCard,
  useCancelPersonCard,
  useCardCategory,
  useFreezePersonCard,
  useUpdateCreditCardStatusMutation,
} from '@queries/use-credit-cards';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import { useGetSpendPlansByCardId } from '@queries/use-spend-plans';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import {
  CardUtilization,
  CardUtilizations,
} from 'areas/cards/credit-cards/card-details/card-utilization';
import { Card, Line } from '@flexbase-eng/sdk-typescript/models/components';
import { DateTime } from 'luxon';

function formatCardInterval(interval: string | null | undefined) {
  let asLowerCase = interval?.toLowerCase() || '';

  if (asLowerCase === 'annually') {
    asLowerCase = 'yearly';
  }

  return capitalizeOnlyFirstLetter(asLowerCase);
}

function formatPlanFrequency(frequency: string) {
  if (!frequency) {
    return '';
  }

  let asLowerCase = frequency.toLowerCase();

  if (asLowerCase === 'onetime') {
    asLowerCase = 'one time';
  }

  return capitalizeOnlyFirstLetter(asLowerCase);
}

/**
 * Just the properties we need to render the details modal.
 *
 * API and Platform are returning different card shapes, so map them to a common type.
 */
export type CardDetailsModel = {
  id: string;
  personId: string;
  holder: string;
  limits: CardExpensesTypes | undefined;
  name: string;
  notifyUse: boolean;
  monthToDateSpends: CardMonthToDateSpends | undefined;
  status: CardStatus;
  suspendDate: string | undefined;
  toDateSpend: ToDateSpend | undefined;
  type: CardType;
};

export function creditCardToDetailsModel(card: CreditCard): CardDetailsModel {
  const {
    id,
    userId,
    cardName,
    cardType,
    expensesTypes,
    holder,
    monthToDateSpends,
    notifyUse,
    status,
    terminateAt,
    toDateSpend,
  } = card;

  return {
    id,
    personId: userId,
    holder,
    limits: expensesTypes,
    name: cardName || '',
    monthToDateSpends,
    notifyUse: !!notifyUse,
    status,
    suspendDate: terminateAt ?? undefined,
    toDateSpend,
    type: cardType,
  };
}

export function platformCardToDetailsModel(card: Card): CardDetailsModel {
  const suspendDate = card.suspendDate
    ? DateTime.fromJSDate(card.suspendDate).toFormat('yyyy-MM-dd')
    : undefined;

  const holder = (() => {
    const { displayName, givenName, familyName } = card.cardHolder;

    return (displayName || `${givenName} ${familyName}` || '').trim();
  })();

  const limits: CardExpensesTypes | undefined = card.limits
    ? {
        amount: card.limits.amount ?? 0,
        interval: card.limits.interval ?? null,
        groups: (card.limits.groups ?? []) as CardExpensesTypes['groups'],
      }
    : undefined;

  const status = ((): CardStatus => {
    switch (card.state) {
      case 'active':
        return 'active';
      case 'inactive':
        return 'issued';
      case 'suspended':
        return 'suspended';
      case 'terminated':
        return 'terminated';
      default:
        return 'issued';
    }
  })();

  return {
    id: card.id,
    personId: card.personId,
    holder,
    limits,
    name: card.name ?? '',
    monthToDateSpends: undefined,
    notifyUse: true,
    status,
    suspendDate,
    toDateSpend: undefined,
    type: card.form === 'physical' ? 'PHYSICAL' : 'VIRTUAL',
  };
}

type ICardDetails = {
  card: CardDetailsModel;
  creditLine?: Line;
};

type BottomSection = 'actions' | Exclude<CardAction, 'freeze' | 'thaw'>;

const CardDetails = ({ creditLine, ...props }: ICardDetails) => {
  const { accountId, company } = useRecoilValue(ApplicationState);
  const [errorMessage, setErrorMessage] = useState('');
  const [bottomSection, setBottomSection] = useState<BottomSection>('actions');

  // This state controls the iframe that we display secure card data in.
  const [embedUrl, setEmbedUrl] = useState('');
  const [embedUrlError, setEmbedUrlError] = useState('');
  const [embedUrlLoading, setEmbedUrlLoading] = useState(true);

  // This makes a local copy of the card in state. After the modal is dispatched it can no longer receive updates
  // to the card prop, so this is necessary to update certain card state variables.
  const [card, setCard] = useState<CardDetailsModel>(props.card);

  // API (Lithic)
  const { mutate: updateApiCardStatus, isPending: isPendingApiUpdateStatus } =
    useUpdateCreditCardStatusMutation();

  // Platform (Marqeta)
  const { mutate: activateCard, isPending: isPendingActivate } =
    useActivatePersonCard();
  const { mutate: cancelCard, isPending: isPendingCancel } =
    useCancelPersonCard();
  const { mutate: freezeCard, isPending: isPendingFreeze } =
    useFreezePersonCard();

  const { expenseLink, connectionId = '' } = useActiveExpenseLink();
  const shouldRenderCategory = !!connectionId && !!expenseLink?.enabledExpenses;
  const { data: cardCategory } = useCardCategory({
    connectionId,
    cardId: card.id,
  });
  const spendPlanFlagEnabled = useSpendPlansFeatureFlag();
  const { data: spendPlansByCardId } = useGetSpendPlansByCardId({
    enabled: spendPlanFlagEnabled,
    accountId: accountId,
    cardId: card.id,
  });

  const isPendingUpdateStatus =
    isPendingApiUpdateStatus ||
    isPendingActivate ||
    isPendingCancel ||
    isPendingFreeze;

  const activeSpendPlans = spendPlansByCardId?.filter(
    (plan) => plan.isPlanActive,
  );
  const primarySpendPlan = activeSpendPlans?.at(0);

  const spentThisMonth = primarySpendPlan
    ? primarySpendPlan.spent
    : (card.monthToDateSpends?.mtdSpend ?? 0);

  const utilizations: CardUtilization[] =
    creditLine?.issuer === 'marqeta'
      ? []
      : [
          {
            name: `Default card limit`,
            frequency: formatCardInterval(
              card.toDateSpend?.interval ?? 'monthly',
            ),
            value: card.toDateSpend?.toDateSpend ?? 0,
            total: card.limits?.amount || 0,
          },
        ];

  activeSpendPlans?.forEach((plan) => {
    utilizations.push({
      name: `${plan.name || 'Spend plan'}`,
      subtitle: 'Spend plan',
      frequency: formatPlanFrequency(plan.frequency),
      value: plan.spent,
      total: plan.limit,
    });
  });

  const handleCardUpdated = (updatedCard: CardDetailsModel) => {
    setCard(updatedCard);
    setBottomSection('actions');
  };

  const getCardHiddenInfo = async (cardId: string) => {
    setEmbedUrlLoading(true);
    try {
      const response = await flexbaseOnboardingClient.getCardHiddenInfo(
        cardId,
        true,
      );

      if (IsEmbedUrlResponse(response)) {
        setEmbedUrl(response.embedUrl);
      } else {
        setEmbedUrlError('Could not process secure card information response');
      }
    } catch (error) {
      setEmbedUrlError('Unable to load secure card information');
    } finally {
      setEmbedUrlLoading(false);
    }
  };

  const handleCardAction = (action: CardAction) => {
    if (action !== 'freeze' && action !== 'thaw') {
      setBottomSection(action);
    } else {
      handleUpdateStatus(action === 'freeze' ? 'suspended' : 'active');
    }
  };

  const handleUpdateStatus = (
    newStatus: 'active' | 'suspended' | 'terminated',
  ) => {
    if (creditLine?.issuer === 'marqeta') {
      handleUpdateStatusPlatform();
    } else {
      handleUpdateStatusApi();
    }

    function handleUpdateStatusApi() {
      updateApiCardStatus(
        {
          cardId: card.id,
          status: newStatus,
        },
        {
          onSuccess: (updatedCard) => {
            handleCardUpdated(creditCardToDetailsModel(updatedCard));
            notifications.show({
              title: 'Success',
              message: `Successfully updated card. ${
                newStatus === 'active'
                  ? 'This card is now active and can be used immediately.'
                  : 'This card is now  inactive and transactions will be declined.'
              }`,
            });
          },
          onError: () => {
            const prettyAction =
              newStatus === 'active'
                ? 'activate'
                : newStatus === 'suspended'
                  ? 'freeze'
                  : 'cancel';
            setErrorMessage(
              `An error occurred while updating card status. Unable to ${prettyAction} card.`,
            );
          },
        },
      );
    }

    function handleUpdateStatusPlatform() {
      switch (newStatus) {
        case 'active':
          activateCard(
            {
              accountId,
              personId: card.personId,
              cardId: card.id,
            },
            {
              onSuccess: (updatedCard) => {
                handleCardUpdated(platformCardToDetailsModel(updatedCard));
              },
            },
          );
          break;
        case 'suspended':
          freezeCard(
            {
              accountId,
              personId: card.personId,
              cardId: card.id,
            },
            {
              onSuccess: (updatedCard) => {
                handleCardUpdated(platformCardToDetailsModel(updatedCard));
              },
            },
          );
          break;
        case 'terminated':
          cancelCard(
            {
              accountId,
              personId: card.personId,
              cardId: card.id,
            },
            {
              onSuccess: (updatedCard) => {
                handleCardUpdated(platformCardToDetailsModel(updatedCard));
                setBottomSection('actions');
              },
            },
          );
          break;
        default:
          return;
      }
    }
  };

  useEffect(() => {
    getCardHiddenInfo(card.id);
  }, []);

  // TODO: Need to better architect this, as some of the components do updates themselves while others expect props
  const BOTTOM_SECTION: Record<BottomSection, JSX.Element> = {
    edit: (
      <EditCard
        card={card}
        creditLine={creditLine}
        onCancelClick={() => setBottomSection('actions')}
        shouldRenderCategory={shouldRenderCategory}
        connectionId={connectionId}
        onCardUpdated={handleCardUpdated}
        spendPlan={primarySpendPlan}
        formatFrequency={formatPlanFrequency}
      />
    ),
    cancel: (
      <CardConfirmCancel
        onConfirmClick={() => handleUpdateStatus('terminated')}
        onCloseClick={() => setBottomSection('actions')}
      />
    ),
    actions: (
      <>
        {card.status !== 'terminated' && (
          <CreditCardActions
            card={card}
            onCardActionClick={handleCardAction}
            showLoadingDots={isPendingUpdateStatus}
          />
        )}

        {utilizations.length > 0 && (
          <>
            <RightContentModal.Divider />
            <CardUtilizations utilizations={utilizations} />
          </>
        )}

        {shouldRenderCategory ? (
          <>
            <RightContentModal.Divider />
            <RightContentModal.TitleValueLine
              title="Accounting category"
              value={
                cardCategory?.card?.category.displayName ??
                cardCategory?.card?.category.name ??
                'No category'
              }
            />
          </>
        ) : null}

        <RightContentModal.Divider />
        <RightContentModal.AddressSection
          address={company.address}
          title="Billing address"
        />
        <RightContentModal.Divider />
        <RightContentModal.TitleValueLine
          title="Card type"
          value={capitalizeOnlyFirstLetter(card.type)}
        />
      </>
    ),
    activate: (
      <CardConfirmStatusUpdate
        card={card}
        creditLine={creditLine}
        prettyStatusLabel="activate"
        newStatus="active"
        onCardUpdated={handleCardUpdated}
        onCancel={() => setBottomSection('actions')}
        connectionId={connectionId}
      />
    ),
  };

  return (
    <RightContentModal>
      <RightContentModal.Header
        title={card.name}
        rightTitle={formatCurrency(spentThisMonth)}
        subtitle={card.holder}
        rightSubtitle="Spent this month"
      />

      <RightContentModal.Body>
        <RightContentModal.Card>
          <CardFrame
            iframeUrl={embedUrl}
            status={card.status}
            error={embedUrlError}
            loading={embedUrlLoading}
          />
        </RightContentModal.Card>

        <Box mt={32}>{BOTTOM_SECTION[bottomSection]}</Box>

        {errorMessage && (
          <Alert mt="md" onClose={() => setErrorMessage('')}>
            {errorMessage}
          </Alert>
        )}
      </RightContentModal.Body>
    </RightContentModal>
  );
};

export default CardDetails;
