import { SelectItem } from '@common/composites/flexbase-select';
import { ExpenseLink } from '@flexbase-eng/types/dist/accounting';
import { ModalProps, Select } from '@mantine/core';
import { useState } from 'react';
import { StepName } from './bulk-categorize-transactions.models';
import { BulkCategorizeTransactionsStep } from './bulk-categorize-transactions-step';

type Props = {
  count: number;
  expenseLink: ExpenseLink;
  onConfirm: (args: { categoryId: string }) => void | Promise<void>;
  onClose: ModalProps['onClose'];
};

export const BulkCategorizeTransactions = ({
  expenseLink,
  count,
  onConfirm,
  onClose,
}: Props) => {
  const [step, setStep] = useState<StepName>('start');
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<SelectItem | null>(
    null,
  );

  const selectItems =
    expenseLink.accounts?.map<SelectItem>((acct) => {
      return {
        label: acct.displayName || acct.name,
        value: acct.id,
      };
    }) || [];

  const transactionOrTransactions =
    count === 1 ? 'transaction' : 'transactions';

  const handleSelect = (value: string | null) => {
    const selectedItem = selectItems?.find((item) => item.value === value);

    if (!selectedItem) {
      return;
    }

    setSelectedCategory(selectedItem);
  };

  const handleConfirm = async () => {
    if (!selectedCategory) {
      return;
    }

    try {
      setLoading(true);
      await onConfirm({ categoryId: selectedCategory.value });
      onClose();
    } catch (e) {
      console.error(
        `An unexpected error occurred while confirming bulk categorize action:`,
        e,
      );
    } finally {
      setLoading(false);
    }
  };

  const startActions = [
    {
      key: 'cancel',
      label: 'Cancel',
      variant: 'neutral-outline' as const,
      onClick: onClose,
    },
    {
      key: 'next',
      label: 'Next',
      disabled: !selectedCategory,
      onClick: () => setStep('confirm'),
    },
  ];

  const confirmActions = [
    {
      key: 'back',
      label: 'Back',
      variant: 'neutral-outline' as const,
      disabled: loading,
      onClick: () => setStep('start'),
    },
    {
      key: 'confirm',
      label: 'Confirm',
      loading,
      onClick: handleConfirm,
    },
  ];

  return step === 'confirm' && selectedCategory ? (
    <BulkCategorizeTransactionsStep actions={confirmActions}>
      Are you sure you want to categorize {count} {transactionOrTransactions} as{' '}
      {selectedCategory.label}?
    </BulkCategorizeTransactionsStep>
  ) : (
    <BulkCategorizeTransactionsStep actions={startActions}>
      <Select
        label={`Select a category to sync ${transactionOrTransactions} to`}
        placeholder="Select a category"
        value={selectedCategory?.value}
        data={selectItems}
        onChange={handleSelect}
        searchable
      />
    </BulkCategorizeTransactionsStep>
  );
};
