import {
  ActionIcon,
  Alert,
  Button,
  colorsTuple,
  createTheme,
  Input,
  MantineTheme,
  MantineThemeOverride,
  Menu,
  MultiSelect,
  PasswordInput,
  Radio,
  rem,
  SwitchProps,
  TextInput,
} from '@mantine/core';
import { DateInput, DatePicker, DatePickerInput } from '@mantine/dates';
import { getThemedButtonStyles } from './themed-button-styles';

export const corporateThemeConfig: MantineThemeOverride = {
  fontFamily: 'PP Neue Montreal',
  headings: {
    fontFamily: 'Redaction',
    sizes: {
      h2: {
        fontSize: '28px',
        fontWeight: '400',
        lineHeight: '34px',
      },
      h3: {
        fontSize: '26px',
        fontWeight: '500',
        lineHeight: 'normal',
      },
    },
  },
  primaryColor: 'primary',
  primaryShade: 7,
  defaultRadius: 2,
  colors: {
    // primary color palette for flexbase-web (referred to as "corporate" in figma)
    primary: [
      'rgba(236, 242, 239, 1)', // [0] / 50
      'rgba(161, 190, 173, 1)', // [1] / 100
      'rgba(67, 126, 91, 1)', //   [2] / 200
      'rgba(54, 101, 73, 1)', //   [3] / 300
      'rgba(47, 88, 64, 1)', //    [4] / 400
      'rgba(40, 76, 55, 1)', //    [5] / 500
      'rgba(34, 63, 46, 1)', //    [6] / 600
      'rgba(20, 38, 27, 1)', //    [7] / 700
      'rgba(7, 28, 20, 1)', //     [8] / 800
      'rgba(1, 2, 2, 1)', //       [9] / 900
    ],
    // `neutral` has 11 shades - the [index] does _not_ always line up with the hue
    neutral: [
      'rgba(255, 255, 255, 1)', // [0] / 0
      'rgba(242, 242, 243, 1)', // [1] / 50
      'rgba(230, 231, 233, 1)', // [2] / 100
      'rgba(246, 243, 238, 1)', // [3] / 200
      'rgba(197, 194, 190, 1)', // [4] / 300
      'rgba(172, 170, 167, 1)', // [5] / 400
      'rgba(148, 146, 143, 1)', // [6] / 500
      'rgba(123, 122, 119, 1)', // [7] / 600
      'rgba(74, 73, 71, 1)', //    [8] / 700
      'rgba(49, 49, 48, 1)', //    [9] / 800
      'rgba(25, 24, 24, 1)', //    [10] / 900
    ],
    critical: [
      'rgba(249, 238, 236, 1)', // [0] / 50
      'rgba(227, 170, 158, 1)', // [1] / 100
      'rgba(200, 85, 61, 1)', //   [2] / 200
      'rgba(160, 68, 49, 1)', //   [3] / 300
      'rgba(140, 60, 43, 1)', //   [4] / 400
      'rgba(120, 51, 37, 1)', //   [5] / 500
      'rgba(100, 43, 31, 1)', //   [6] / 600
      'rgba(60, 26, 18, 1)', //    [7] / 700
      'rgba(40, 17, 12, 1)', //    [8] / 800
      'rgba(20, 9, 6, 1)', //      [9] / 900
    ],
    warning: [
      'rgba(252, 246, 238, 1)', // [0] / 50
      'rgba(239, 213, 171, 1)', // [1] / 100
      'rgba(222, 170, 87, 1)', //  [2] / 200
      'rgba(178, 136, 70, 1)', //  [3] / 300
      'rgba(155, 119, 61, 1)', //  [4] / 400
      'rgba(133, 102, 52, 1)', //  [5] / 500
      'rgba(111, 85, 44, 1)', //   [6] / 600
      'rgba(67, 51, 26, 1)', //    [7] / 700
      'rgba(44, 34, 17, 1)', //    [8] / 800
      'rgba(22, 17, 9, 1)', //     [9] / 900
    ],
    promote: [
      'rgba(244, 246, 248, 1)', // [0] / 50
      'rgba(198, 211, 223, 1)', // [1] / 100
      'rgba(141, 167, 190, 1)', // [2] / 200
      'rgba(113, 134, 152, 1)', // [3] / 300
      'rgba(99, 117, 133, 1)', //  [4] / 400
      'rgba(85, 100, 114, 1)', //  [5] / 500
      'rgba(71, 84, 95, 1)', //    [6] / 600
      'rgba(42, 50, 57, 1)', //    [7] / 700
      'rgba(28, 33, 38, 1)', //    [8] / 800
      'rgba(14, 17, 19, 1)', //    [9] / 900
    ],
    sage: [
      'rgba(241, 246, 244, 1)', // [0] / 50
      'rgba(227, 236, 233, 1)', // [1] / 100
      'rgba(101, 140, 128, 1)', // [2] / 200
      'rgba(95, 131, 120, 1)', //  [3] / 300
      'rgba(89, 123, 112, 1)', //  [4] / 400
      'rgba(83, 114, 104, 1)', //  [5] / 500
      'rgba(76, 101, 94, 1)', //   [6] / 600
      'rgba(63, 87, 79, 1)', //    [7] / 700
      'rgba(50, 69, 62, 1)', //    [8] / 800
      'rgba(37, 51, 46, 1)', //    [9] / 900
    ],
    opaque: colorsTuple(['rgba(29, 33, 45, 0.5)', 'rgba(7, 28, 20, 0.7)']),
  },
  breakpoints: {
    xs: '576px',
    sm: '767px',
    md: '992px',
    lg: '1200px',
    xl: '1400px',
  },
  spacing: {
    xxs: rem(4),
    xs: rem(8),
    sm: rem(12),
    md: rem(16),
    lg: rem(18),
    xl: rem(24),
    xxl: rem(28),
    xxxl: rem(32),
  },
  components: {
    Checkbox: {
      styles: (theme: MantineTheme) => {
        return {
          checkbox: {
            div: {
              width: '18px',
              height: '18px',
            },
            input: {
              width: '18px',
              height: '18px',
              '&:checked': {
                backgroundColor: `${theme.colors.neutral[0]} !important`,
                borderColor: `${theme.colors.neutral[2]} !important`,
              },
              '&:hover': {
                borderColor: `${theme.colors.critical[2]} !important`,
              },
            },
            svg: {
              color: `${theme.colors.critical[2]} !important`,
            },
          },
        };
      },
    },
    Switch: {
      styles: (theme: MantineTheme, props: SwitchProps) => {
        const size: string = typeof props.size === 'string' ? props.size : 'sm';
        const colors = {
          thumb: theme.colors.neutral[0],
          checked: props.color
            ? theme.colors[props.color]
            : theme.colors.primary[6],
          unchecked: theme.colors.neutral[6],
        };

        const minWidthMap: Record<string, number> = {
          xs: 24,
          sm: 32,
          md: 36,
          lg: 44,
          xl: 56,
        };

        return {
          thumb: {
            borderColor: colors.thumb,
          },
          track: {
            backgroundColor: props.checked ? colors.checked : colors.unchecked,
            borderColor: props.checked ? colors.checked : colors.unchecked,
            minWidth: rem(minWidthMap[size || 'sm']),
          },
          trackLabel: {
            color: colors.thumb,
            minWidth: 0,
          },
        };
      },
    },
    Modal: {
      styles: () => {
        return {
          title: {
            fontFamily: 'PP Neue Montreal',
            fontSize: '28px',
            fontWeight: 400,
            lineHeight: '34px',
          },
        };
      },
    },
    Progress: {
      defaultProps: {
        color: 'primary.4',
      },
      styles: (theme: MantineTheme) => ({
        root: {
          backgroundColor: theme.colors.neutral[3],
        },
      }),
    },
    Avatar: {
      styles: (theme: MantineTheme) => {
        return {
          root: {
            borderRadius: '100%',
            border: `1px solid ${theme.colors.neutral[0]}`,
            backgroundColor: theme.colors.neutral[3],
          },
          placeholder: {
            color: theme.colors.primary[8],
            background: 'unset',
            fontWeight: 400,
            fontSize: '14px',
          },
        };
      },
    },
    Button: Button.extend({
      defaultProps: {
        size: 'md',
        variant: 'filled',
      },
      styles: getThemedButtonStyles,
    }),
    ActionIcon: ActionIcon.extend({
      styles: getThemedButtonStyles,
    }),
    /**
     * Note: Input and Input.Wrapper effectively target all inputs in Mantine.
     *
     * Input.Wrapper is used to style the label, error, description, and required asterisk
     * Input is used to style the icon, input, rightSection
     *
     * Both provide the "root" or wrapper styling as well but I'm leaving it out because I'm already confused
     * on why they divided them up into 2
     */
    InputWrapper: {
      styles: (theme: MantineTheme) => {
        return {
          label: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: 14,
            lineHeight: rem(20),
            '&[data-error]': {
              color: theme.colors.critical[2],
            },
            marginBottom: 4,
            p: {
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: 14,
              lineHeight: rem(20),
              '&[data-error]': {
                color: theme.colors.critical[2],
              },
              marginBottom: 4,
            },
          },
          error: {
            color: theme.colors.critical[2],
            fontWeight: 400,
            fontSize: rem(12),
            lineHeight: '1rem',
            marginTop: 6,
          },
        };
      },
    },
    Input: Input.extend({
      styles: (theme) => {
        return {
          input: {
            height: 40,
            fontWeight: 500,
            color: theme.colors.neutral[8],
            fontSize: rem(14),
            lineHeight: rem(20),
            borderRadius: theme.defaultRadius,
            background: theme.colors.neutral[0],
            border: `1px solid ${theme.colors.neutral[2]}`,
            '&:focus': { borderColor: theme.colors.primary[8] },
            '&:focus-within': {
              borderColor: theme.colors.primary[8],
            },
            '&[data-error]': {
              color: theme.colors.critical[2],
              borderColor: theme.colors.critical[2],

              '&::placeholder': {
                color: theme.colors.critical[2],
              },
            },
            '&::placeholder': {
              color: theme.colors.neutral[6],
            },
          },
        };
      },
    }),
    PasswordInput: PasswordInput.extend({
      styles: (theme) => {
        return {
          rightSection: {
            paddingRight: '1.2rem', // eyeballing it
            position: 'absolute',
          },
          visibilityToggle: {
            color: theme.colors.neutral[7],
          },
          input: {
            padding: 0,
          },
          label: {
            marginBottom: 0,
            color: theme.colors.neutral[7],
          },
          innerInput: {
            height: '100%',
            fontWeight: 500,
            color: theme.colors.neutral[8],
            fontSize: '14px',
            lineHeight: '16px',
            borderRadius: theme.defaultRadius,
            background: theme.colors.neutral[0],
            position: 'unset', // Mantine's password input is stupid and does not even follow styling of other Mantine inputs
            paddingLeft: '16px',
            paddingRight: '46px',
            width: '100%',
            '&[data-invalid]': {
              color: theme.colors.critical[2],
              borderColor: theme.colors.critical[2],
              '&::placeholder': {
                color: theme.colors.critical[2],
              },
            },
          },
        };
      },
    }),
    Select: {
      styles: (theme: MantineTheme) => ({
        section: { pointerEvents: 'none' },
        option: {
          '&[data-checked]': {
            backgroundColor: theme.colors.primary[1],
            color: theme.colors.primary[8],
          },
          '&:hover': {
            '&:not([data-checked])': {
              backgroundColor: theme.colors.primary[0],
            },
          },
        },
      }),
    },
    MultiSelect: MultiSelect.extend({
      styles: (theme) => ({
        input: {
          display: 'flex',
          alignItems: 'center',
          height: 'fit-content',
          minHeight: 40,
        },
        item: {
          '&:not([data-disabled])': {
            backgroundColor: theme.colors['neutral'][2],
          },
          '&:hover': {
            backgroundColor: theme.colors['neutral'][2],
          },
        },
        pill: {
          borderRadius: 2,
        },
      }),
    }),
    Alert: Alert.extend({
      defaultProps: {
        color: 'red',
        withCloseButton: true,
      },
      styles: (theme, defaultProps) => {
        let iconColor = theme.colors.critical[2];
        let borderColor = theme.colors.critical[1];
        let backgroundColor = theme.colors.critical[0];

        switch (defaultProps.color) {
          case 'red':
            backgroundColor = theme.colors.critical[0];
            borderColor = theme.colors.critical[1];
            iconColor = theme.colors.critical[2];
            break;
          case 'green':
            backgroundColor = theme.colors.primary[0];
            borderColor = theme.colors.primary[1];
            iconColor = theme.colors.primary[3];
            break;
          case 'yellow':
            backgroundColor = theme.colors.warning[2];
            borderColor = theme.colors.warning[0];
            iconColor = theme.colors.warning[1];
            break;
          case 'info':
            backgroundColor = theme.colors.promote[0];
            borderColor = theme.colors.promote[1];
            iconColor = theme.colors.promote[3];
            break;
          case 'warning':
            backgroundColor = theme.colors.warning[0];
            borderColor = theme.colors.warning[1];
            iconColor = theme.colors.warning[2];
            break;
          default:
            backgroundColor = theme.colors.critical[0];
            borderColor = theme.colors.critical[1];
            iconColor = theme.colors.critical[2];
        }

        return {
          root: {
            borderRadius: 2,
            fontWeight: 500,
            backgroundColor,
            fontSize: rem(16),
            lineHeight: rem(24),
            boxSizing: 'border-box',
            color: theme.colors.primary[8],
            border: `1px solid ${borderColor}`,
          },
          icon: {
            color: iconColor,
            fill: iconColor,
          },
          closeButton: {
            color: iconColor,
          },
        };
      },
    }),
    Badge: {
      styles: (theme: MantineTheme) => {
        return {
          root: {
            borderRadius: 4,
            padding: '2px 6px',
            backgroundColor: theme.colors.neutral[2],
          },
          label: {
            fontSize: rem(12),
            fontWeight: 400,
            color: theme.colors.neutral[8],
            textTransform: 'none',
            margin: 0,
            lineHeight: rem(16),
            p: {
              fontSize: 'inherit',
              fontWeight: 'inherit',
              color: 'inherit',
              textTransform: 'none',
              margin: 'inherit',
              lineHeight: 'inherit',
            },
          },
        };
      },
    },
    Menu: Menu.extend({
      styles: (theme) => {
        return {
          item: {
            '&[data-hovered]': {
              backgroundColor: theme.colors.neutral[3],
            },
          },
        };
      },
    }),
    Radio: Radio.extend({
      styles: (theme) => {
        return {
          radio: {
            ':checked': {
              background: theme.colors.primary[6],
              borderColor: theme.colors.primary[6],
            },
          },
        };
      },
    }),
    // I do not know why Mantine has 3 different date pickers nor why they have different styles for each.
    DateInput: DateInput.extend({
      styles: (theme) => ({
        day: {
          '&[data-weekend]': { color: theme.colors.warning[2] },
        },
      }),
    }),
    DatePicker: DatePicker.extend({
      styles: (theme) => ({
        day: {
          '&[data-weekend]': { color: theme.colors.warning[2] },
        },
      }),
    }),
    DatePickerInput: DatePickerInput.extend({
      styles: (theme) => ({
        day: {
          '&[data-weekend]': { color: theme.colors.warning[2] },
        },
      }),
    }),
    TextInput: TextInput.extend({
      styles: (theme) => ({
        label: {
          marginBottom: 0,
          color: theme.colors.neutral[10],
        },
        input: {
          borderRadius: '2px',
          transition: 'all 0.2s ease',
          backgroundColor: theme.colors.neutral[0],
          fontWeight: 400,
          border: `1px solid ${theme.colors.neutral[3]}`,
          '&::placeholder': {
            fontWeight: 400,
            color: theme.colors.neutral[5],
          },
          '&:not(:placeholder-shown)': {
            color: theme.colors.neutral[9],
          },
          '&:focus': {
            borderColor: theme.colors.neutral[9],
            backgroundColor: theme.colors.neutral[0],
          },
          '&:disabled': {
            backgroundColor: theme.colors.neutral[2],
            borderColor: theme.colors.neutral[3],
            color: theme.colors.neutral[5],
            cursor: 'not-allowed',
            '&:section': {
              color: theme.colors.neutral[5],
            },
          },
          '&[data-error]': {
            borderColor: theme.colors.critical[2],
            color: theme.colors.critical[2],
            '&::placeholder': {
              color: theme.colors.critical[2],
            },
            '&:focus': {
              borderColor: theme.colors.critical[3],
            },
          },
        },
        error: {
          color: theme.colors.critical[2],
          borderColor: theme.colors.critical[2],
          fontSize: '14px',
          marginTop: '4px',
        },
        section: {
          '[data-error] &': {
            color: theme.colors.critical[6],
          },
        },
        description: {
          color: theme.colors.neutral[8],
        },
      }),
    }),
  },
};

export const FlexCorporateTheme: MantineThemeOverride =
  createTheme(corporateThemeConfig);
