import { Alert, Box, Button, Group, rem, Text } from '@mantine/core';
import { useStyles } from '../styles';
import { PaymentForm } from 'types/payments';
import { formatCurrency } from 'utilities/formatters';
import { AUTHORIZATIONS } from 'constants/constants';
import AccountSelection from '@common/account-selection';
import { useMediaQuery } from '@mantine/hooks';

type Props = {
  loading: boolean;
  form: PaymentForm;
  onContinue: () => void;
  onBackClick: () => void;
};

const TransferReview = ({ form, loading, onContinue, onBackClick }: Props) => {
  const { classes, theme } = useStyles();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);
  const authorization =
    form.fromAccount?.plaidOrDeposit === 'plaid'
      ? AUTHORIZATIONS.achDebit.contents
      : AUTHORIZATIONS.default.contents;

  const AuthorizationSection = (
    <Box className={classes.authorizationSection} data-testid="disclaimer">
      {authorization}
    </Box>
  );

  return (
    <Box>
      <Box>
        <Box className={classes.amountInputValue}>
          <Text span color={theme.colors.neutral[8]}>
            Amount
          </Text>
          <Box style={{ borderBottom: `1px solid ${theme.colors.neutral[4]}` }}>
            <Text data-testid="amount" fz={rem(48)} lh={1.2}>
              {formatCurrency(form.amount)}
            </Text>
          </Box>
        </Box>

        <Box mt={rem(20)} data-testid="transfer-from">
          <AccountSelection
            label="Transferring from"
            currentAccount={form.fromAccount!}
          />
        </Box>

        <Box mt={rem(20)} data-testid="transfer-to">
          <AccountSelection
            label="Transferring to"
            currentAccount={form.toAccount!}
          />
        </Box>

        {form.fromAccount?.plaidOrDeposit === 'deposit' && (
          <Alert color="info" my="lg" withCloseButton={false}>
            <Text color={theme.colors.neutral[10]}>
              {form.toAccount?.plaidOrDeposit === 'deposit'
                ? 'This transfer will take just a few moments.'
                : 'This transfer will take 3 business days.'}
            </Text>
          </Alert>
        )}

        {useMobileView && AuthorizationSection}

        <Group justify={'space-between'} align="center" mt={rem(20)}>
          <Box flex={1}>{!useMobileView && AuthorizationSection}</Box>
          <Button
            ml={rem(16)}
            variant="neutral-outline"
            onClick={onBackClick}
            data-testid={'button-back'}
          >
            Back
          </Button>
          <Button
            ml={rem(16)}
            loading={loading}
            loaderProps={{ size: 'xs', color: theme.colors.neutral[0] }}
            onClick={onContinue}
            data-testid={'button-next'}
          >
            Transfer Funds
          </Button>
        </Group>
      </Box>
    </Box>
  );
};

export default TransferReview;
