import { NavTab } from '@common/composites/tabs/nav-tabs';
import FlexbaseMainLayout from 'components/layouts/main';
import {
  ApplicationState,
  IsAccountant,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';
import { useRecoilValue } from 'recoil';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import FlexbaseDefaultHeader from '../../components/composites/header/flexbase-header';
import { Box } from '@mantine/core';
import { NoCreditPage } from './components/no-credit.page';
import { useCreditLineContext } from 'providers/credit-line-provider';

const adminTabsLegacy = [
  { label: 'Overview', route: '/cards/overview' },
  { label: 'Credit cards', route: '/cards/details' },
  { label: 'Payments', route: '/cards/pay' },
  { label: 'Statements', route: '/cards/statements' },
];

const tabsLegacy: NavTab[] = [
  { label: 'Overview', route: '/cards/overview' },
  { label: 'Credit cards', route: '/cards/details' },
];

const adminTabs = [
  { label: 'Overview', route: '/credit/overview' },
  { label: 'Credit cards', route: '/credit/details' },
  { label: 'Payments', route: '/credit/pay' },
  { label: 'Statements', route: '/credit/statements' },
];

const tabs: NavTab[] = [
  { label: 'Overview', route: '/credit/overview' },
  { label: 'Credit cards', route: '/credit/details' },
];

const Credit = () => {
  const navigate = useNavigate();
  const { excludedProducts } = useRecoilValue(ApplicationState);
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const onboardingState = useRecoilValue(ApplicationState);
  const appStatus = onboardingState.productStatus.credit.appStatus;
  const creditLimit = onboardingState.productStatus.credit.creditLimit
    ? parseFloat(onboardingState.productStatus.credit.creditLimit)
    : 0;
  const hasCreditLimit = creditLimit > 0;
  const showMainApp = appStatus === 'approved' || hasCreditLimit;
  const { line } = useCreditLineContext();

  if (excludedProducts.includes('CREDIT')) {
    if (!excludedProducts.includes('BANKING')) {
      navigate('/banking', { replace: true });
    }
    return false;
  }

  const isLegacy = !line || line.issuer !== 'marqeta';
  const isElevated = isAdmin || isAccountant;
  const navTabs = isLegacy
    ? isElevated
      ? adminTabsLegacy
      : tabsLegacy
    : isElevated
      ? adminTabs
      : tabs;

  return (
    <FlexbaseMainLayout>
      <Box
        sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}
      >
        <FlexbaseDefaultHeader title={showMainApp ? 'Credit' : undefined} />
        <Box sx={{ flex: 1 }}>
          {showMainApp ? (
            <NavTabContainer tabs={navTabs}>
              <Outlet />
            </NavTabContainer>
          ) : (
            <NoCreditPage />
          )}
        </Box>
      </Box>
    </FlexbaseMainLayout>
  );
};

export default Credit;
