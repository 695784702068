import FlexbaseSelect, {
  FlexbaseSelectProps,
} from '@common/composites/flexbase-select';
import { Button } from '@mantine/core';
import { useRef } from 'react';

type MemberSelectProps = FlexbaseSelectProps & {
  onAddAll?: () => void;
};

export const TeamMemberSelect = ({ onAddAll, ...rest }: MemberSelectProps) => {
  const onAddAllRef = useRef(onAddAll);
  onAddAllRef.current = onAddAll;

  const hasMembers = useRef(rest?.data?.length && rest?.data?.length > 0);
  hasMembers.current = rest?.data?.length && rest?.data?.length > 0;

  return (
    <FlexbaseSelect
      {...rest}
      placeholder="Search or select"
      value={null}
      keepDropdownOpenOnSelect
      searchable
      footer={
        <Button
          variant="primary-subtle"
          leftSection="+"
          w="100%"
          h="auto"
          p="xs"
          onClick={onAddAllRef.current}
        >
          Add All Team Members
        </Button>
      }
    />
  );
};
