import { Button, Group, Text } from '@mantine/core';
import { detailsCardStyles } from './styles';

type Props = { onConfirmClick: () => void; onCloseClick: () => void };

export const CardConfirmCancel = ({ onConfirmClick, onCloseClick }: Props) => {
  const { classes } = detailsCardStyles();
  return (
    <div>
      <Text>Are you sure you want to cancel this card?</Text>
      <Group sx={{ justifyContent: 'space-between' }} mt="md">
        <Button
          variant="neutral-outline"
          className={classes.whiteOutlineButton}
          onClick={onCloseClick}
        >
          Back
        </Button>
        <Button onClick={onConfirmClick}>Cancel card</Button>
      </Group>
    </div>
  );
};
