import {
  Box,
  Button,
  Flex,
  Group,
  rem,
  SegmentedControl,
  Text,
  UnstyledButton,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import FlexPatternFormat from '@common/flex-pattern-format';
import { useStyles } from './edit-recipient-styles';
import { RightContentModal } from '@common/composites/modal/right-content.modal';
import { RECIPIENTS, useUpdateRecipient } from '@queries/use-recipients';
import { Recipient } from 'types/recipient';
import { IndividualOrCompany } from '../../send-payment/international-payments/util/types';
import { showNotification } from '@mantine/notifications';
import { useQueryClient } from '@tanstack/react-query';
import { PiXLight } from 'react-icons/pi';

type Props = {
  closeModal: () => void;
  recipient: Recipient;
};

type RecipientData = {
  name: string;
  email: string;
} & (
  | { type: 'company'; contactName: string | null }
  | { type: 'individual'; firstName: string; lastName: string; dob?: string }
);

const EditRecipientModal = ({ closeModal, recipient }: Props) => {
  const { classes, theme } = useStyles();
  const { mutate: updateRecipient, isPending: isPendingUpdate } =
    useUpdateRecipient();

  const initialValues: RecipientData = {
    name: recipient.name ?? '',
    type: 'individual' as IndividualOrCompany,
    email: recipient.email ?? '',
    contactName: recipient.contactName ?? '',
    firstName: recipient.firstName ?? '',
    lastName: recipient.lastName ?? '',
    dob: recipient.dob ?? '',
  };

  const form = useForm<RecipientData>({
    initialValues,
    validate: {
      name: (value, values) =>
        values.type === 'company' && !value
          ? 'Please enter a business name.'
          : null,
      firstName: (value, values) =>
        values.type === 'individual' && !value
          ? 'Please enter a first name.'
          : null,
      lastName: (value, values) =>
        values.type === 'individual' && !value
          ? 'Please enter a last name.'
          : null,
      email: (value, values) =>
        values.type === 'company' && !value ? 'Please enter a email.' : null,
      contactName: (value, values) =>
        values.type === 'company' && !value
          ? 'Please enter a contact name.'
          : null,
    },
  });

  const isIndividualType = form.values.type === 'individual';
  const queryClient = useQueryClient();

  const editRecipient = (recipientData: RecipientData) => {
    const validationResult = form.validate();

    if (validationResult.hasErrors) {
      return null;
    }

    const optionalFields: Record<string, any> = {};
    if (recipientData.type === 'individual' && recipientData.dob) {
      optionalFields.dob = recipientData.dob;
    }

    const updatedRecipientData: RecipientData =
      recipientData.type === 'individual'
        ? {
            type: 'individual',
            firstName: recipientData.firstName!,
            lastName: recipientData.lastName!,
            name: recipientData.name,
            email: recipientData.email,
            ...optionalFields,
          }
        : {
            type: 'company',
            name: recipientData.name!,
            contactName: recipientData.contactName!,
            email: recipientData.email!,
          };

    updateRecipient(
      {
        id: recipient.id,
        params: updatedRecipientData,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: [RECIPIENTS] });
          showNotification({
            color: 'sage.4',
            message: 'Successfully updated recipient',
          });
          closeModal();
        },
        onError: () => {
          showNotification({
            color: 'red',
            message: 'Unable to update recipient',
          });
        },
      },
    );
  };

  const handleSubmit = (values: RecipientData) => {
    editRecipient(values);
  };

  const hasChanges =
    JSON.stringify(form.values) !== JSON.stringify(initialValues);

  return (
    <RightContentModal>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <RightContentModal.Header
          title={<Text className={classes.title}>Edit recipient</Text>}
          rightTitle={
            <UnstyledButton variant="neutral-outline" onClick={closeModal}>
              <PiXLight size={'2rem'} color={theme.colors.neutral[0]} />
            </UnstyledButton>
          }
        />

        <RightContentModal.Body>
          <Text size="sm" fw="500">
            Recipient type
          </Text>
          <SegmentedControl
            mt="sm"
            fullWidth
            classNames={{
              indicator: classes.select,
              root: classes.selectContainer,
            }}
            data={[
              { value: 'individual', label: 'Person' },
              { value: 'company', label: 'Business' },
            ]}
            {...form.getInputProps('type')}
            disabled={!!recipient.type}
          />
          {isIndividualType ? (
            <>
              <Flex mt="xl" gap="md">
                <TextInput
                  w="100%"
                  label="First name"
                  placeholder="Enter recipient name"
                  {...form.getInputProps('firstName')}
                />
                <TextInput
                  w="100%"
                  label="Last name"
                  placeholder="Enter recipient name"
                  {...form.getInputProps('lastName')}
                />
              </Flex>
              <FlexPatternFormat
                mt={'md'}
                label={
                  <Text>
                    Date of birth{' '}
                    <Text span color={theme.colors.neutral[7]}>
                      (optional)
                    </Text>
                  </Text>
                }
                description="Format: YYYY-MM-DD"
                placeholder="Enter date of birth"
                c="neutral.9"
                format="####-##-##"
                mask="_"
                allowEmptyFormatting
                {...form.getInputProps('dob')}
              />
            </>
          ) : (
            <>
              <TextInput
                mt="xl"
                label="Business name"
                placeholder="Enter recipient name"
                {...form.getInputProps('name')}
              />
              <TextInput
                mt="xl"
                label="Email"
                placeholder="Enter email"
                {...form.getInputProps('email')}
              />
              <TextInput
                mt="xl"
                label="Contact Name"
                placeholder="Enter Contact Name"
                {...form.getInputProps('contactName')}
              />
            </>
          )}
          <Box mt={rem(48)}>
            <Group className={classes.buttonGroup}>
              <Button
                variant="neutral-outline"
                onClick={closeModal}
                bg="neutral.0"
              >
                Back
              </Button>
              <Button
                loading={isPendingUpdate}
                disabled={!hasChanges}
                type="submit"
              >
                Save update
              </Button>
            </Group>
          </Box>
        </RightContentModal.Body>
      </form>
    </RightContentModal>
  );
};

export default EditRecipientModal;
