import { ReactNode, useEffect, useMemo, useState } from 'react';
import {
  CloseButton,
  Combobox,
  ComboboxItem,
  ComboboxItemGroup,
  ComboboxProps,
  InputBase,
  InputBaseProps,
  Loader,
  useCombobox,
} from '@mantine/core';
import { PiCaretDownLight, PiCaretUpLight } from 'react-icons/pi';

/**
 * FlexbaseSelect is a wrapper around Mantine's Combobox component that provides a more flexible API
 * to meet most of the use cases we've needed so far in our application.
 *
 * Most of this code has been adapted from the examples provided by Mantine in their documentation:
 * https://mantine.dev/combobox/
 *
 * Specifically the "Searchable select with groups" and "Select with custom option" examples
 *
 * If you want to add the previous `creatable` functionality, you can use the `nothingFound` prop to render a custom component when no options are found.
 * You can use that prop to add the create functionality. This can be seen in document-upload-modal.tsx
 */

function isGroup<T extends object>(
  item: T | ComboboxItemGroup<T>,
): item is ComboboxItemGroup<T> {
  return !!item && 'group' in item && item.items instanceof Array;
}

export type SelectItem = ComboboxItem & Record<string, unknown>;
export type FlexbaseSelectProps = {
  id?: string;
  value?: string | null;
  label?: ReactNode;
  onChange: (value: string | null) => void;
  data?: SelectItem[];
  groupedData?: ComboboxItemGroup<SelectItem>[];
  placeholder?: string;
  searchable?: boolean;
  filter?: (item: SelectItem, search: string) => boolean;
  nothingFound?: React.FC<{ value: string }>;
  clearable?: boolean;
  maxDropdownHeight?: number; // controls the max height of the dropdown
  itemComponent?: React.FC<SelectItem>;
  comboboxProps?: ComboboxProps;
  inputProps?: React.BaseHTMLAttributes<HTMLInputElement> & InputBaseProps;
  keepDropdownOpenOnSelect?: boolean;
  initiallyOpened?: boolean;
  footer?: ReactNode;
  disabled?: boolean;
  isPending?: boolean;
  autoFocus?: boolean;
  error?: string;
  'data-testid'?: string;
};

const FlexbaseSelect = ({
  id,
  value,
  label,
  onChange,
  data,
  groupedData,
  placeholder,
  searchable,
  nothingFound,
  clearable,
  disabled,
  maxDropdownHeight,
  itemComponent,
  comboboxProps,
  inputProps,
  autoFocus,
  keepDropdownOpenOnSelect,
  initiallyOpened,
  footer,
  error,
  isPending,
  'data-testid': dataTestId,
}: FlexbaseSelectProps) => {
  const ItemComponent = itemComponent;
  const NothingFound = nothingFound;

  const combobox = useCombobox({
    defaultOpened: initiallyOpened,
    onDropdownClose: () => combobox.resetSelectedOption(),
  });
  const flattenedData = useMemo(
    () => data || groupedData?.flatMap((group) => group.items) || [],
    [data, groupedData],
  );
  const selectedOption = flattenedData.find((item) => item.value === value);
  const [search, setSearch] = useState(selectedOption?.label || '');

  const filteredData: (SelectItem | ComboboxItemGroup<SelectItem>)[] =
    useMemo(() => {
      const matchSearch = (item: SelectItem) => {
        if (!search || !searchable) {
          return true;
        }

        const keys = Object.keys(item);
        for (const key of keys) {
          const field = item[key];
          if (
            typeof field === 'string' &&
            field.toLowerCase().includes(search.toLowerCase().trim())
          ) {
            return true;
          }
        }
      };

      const allData = data || groupedData || [];

      if (!searchable) {
        return allData;
      }

      return (
        allData
          // filter grouped items
          .map<SelectItem | ComboboxItemGroup<SelectItem>>((item) => {
            if (!isGroup(item)) {
              return item;
            }

            return {
              group: item.group,
              items: item.items.filter((i) => matchSearch(i)),
            };
          })
          // filter non-grouped items
          .filter((item) => isGroup(item) || matchSearch(item))
      );
    }, [data, groupedData, search]);

  const options = useMemo(() => {
    const optionFor = (item: SelectItem, index: number) => {
      return (
        <Combobox.Option
          id={id ? `${id}-${index}` : undefined}
          value={item.value}
          key={item.value}
        >
          {ItemComponent ? <ItemComponent {...item} /> : item.label}
        </Combobox.Option>
      );
    };

    return filteredData.map((item, index) => {
      if (!isGroup(item)) {
        return optionFor(item, index);
      }

      return (
        <Combobox.Group label={item.group} key={item.group}>
          {item.items.map((groupItem, groupIdx) =>
            optionFor(groupItem, groupIdx),
          )}
        </Combobox.Group>
      );
    });
  }, [filteredData]);

  const handleSearch: React.FormEventHandler<HTMLInputElement> = (event) => {
    combobox.openDropdown();
    combobox.updateSelectedOptionIndex();
    setSearch(event.currentTarget.value);
  };

  const handleBlur = () => {
    combobox.closeDropdown();
    setSearch(selectedOption ? selectedOption.label : search);
  };

  useEffect(() => {
    if (value === null) {
      setSearch('');
    }

    const exists = flattenedData?.find((o) => o.value === value);

    if (exists) {
      setSearch(exists.label);
    }
  }, [value]);

  const renderComboboxTarget = () => {
    /**
     * These need to be rendered as separate components because the prop types
     * change based on the `component` prop passed to `InputBase`. Namely the `placeholder` prop
     */
    if (searchable) {
      return (
        <Combobox.Target>
          <InputBase
            id={id}
            label={label}
            rightSection={
              isPending ? (
                <Loader size="xs" />
              ) : !!value && clearable ? (
                <CloseButton
                  size="sm"
                  onMouseDown={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    onChange(null);
                    setSearch('');
                    combobox.closeDropdown();
                  }}
                  aria-label="Clear value"
                />
              ) : combobox.dropdownOpened ? (
                <PiCaretUpLight />
              ) : (
                <PiCaretDownLight />
              )
            }
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={handleBlur}
            rightSectionPointerEvents={!value || !clearable ? 'none' : 'all'}
            multiline
            onChange={handleSearch}
            value={search}
            placeholder={placeholder}
            error={error}
            data-testid={dataTestId}
            {...inputProps}
          />
        </Combobox.Target>
      );
    }
    return (
      <Combobox.Target>
        <InputBase
          id={id}
          label={label}
          pointer
          rightSection={
            !!value && clearable ? (
              <CloseButton
                size="sm"
                onMouseDown={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onChange(null);
                  setSearch('');
                  combobox.closeDropdown();
                }}
                aria-label="Clear value"
              />
            ) : combobox.dropdownOpened ? (
              <PiCaretUpLight />
            ) : (
              <PiCaretDownLight />
            )
          }
          onClick={() => combobox.toggleDropdown()}
          rightSectionPointerEvents={!value || !clearable ? 'none' : 'all'}
          multiline
          value={selectedOption ? selectedOption.label : ''}
          placeholder={placeholder}
          sx={{ caretColor: 'transparent' }}
          error={error}
          data-testid={dataTestId}
          {...inputProps}
        />
      </Combobox.Target>
    );
  };

  return (
    <Combobox
      store={combobox}
      withinPortal={false}
      disabled={disabled}
      onOptionSubmit={(val) => {
        onChange(val);

        if (searchable) {
          setSearch(
            flattenedData.find((item) => item.value === val)?.label || '',
          );
        }

        if (!keepDropdownOpenOnSelect) {
          combobox.closeDropdown();
        }
      }}
      {...comboboxProps}
    >
      {renderComboboxTarget()}
      <Combobox.Dropdown autoFocus={autoFocus}>
        <Combobox.Options
          mah={maxDropdownHeight || 220}
          sx={{ overflowY: 'auto' }}
        >
          {!options.length ? (
            <Combobox.Empty>
              {NothingFound ? <NothingFound value={search} /> : 'Nothing found'}
            </Combobox.Empty>
          ) : (
            options
          )}
        </Combobox.Options>
        {footer && <Combobox.Footer>{footer}</Combobox.Footer>}
      </Combobox.Dropdown>
    </Combobox>
  );
};

export default FlexbaseSelect;
