import { AsYouType } from 'libphonenumber-js/min';
import { Country } from 'react-phone-number-input';

// TODO checkout https://github.com/google/libphonenumber for future use?

export const formatUSPhoneNumber = (value: string) => {
  if (!value) {
    return value;
  }
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (cvLength < 4) {
    return currentValue;
  }
  if (cvLength < 7) {
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
  }
  return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
    3,
    6,
  )}-${currentValue.slice(6, 10)}`;
};

/**
 * Removes all non-numeric values.
 */
export const formatPhoneForApi = (phoneInput: string): string => {
  if (!phoneInput) {
    return phoneInput;
  }

  return phoneInput.replaceAll(/\D/g, '');
};

/**
 * Returns phone number in international format with plus and country calling code prepended.
 * 
 * @example 
 * formatIntlPhoneForPlatform('4045551234', 'US');     // '+14045551234'
 * formatIntlPhoneForPlatform('(404) 555-1234', 'US'); // '+14045551234'
 * formatIntlPhoneForPlatform('+14045551234', 'US');   // '+14045551234'
 */
export const formatIntlPhoneForPlatform = (phoneNumber: string, countryCode: Country) => {
  const formatter = new AsYouType(countryCode);
  formatter.input(phoneNumber);

  return formatter.getNumberValue() ?? phoneNumber;
};
