import FlexbaseMainLayout from 'components/layouts/main';
import { Outlet, useNavigate } from 'react-router-dom';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import FlexbaseDefaultHeader from '../../components/composites/header/flexbase-header';
import { useEffect } from 'react';
import { useBillPayCreditV2Flag } from '@utilities/feature-flags';

const tabs = [
  { label: 'Overview', route: '/bill-pay-credit/overview' },
  { label: 'Credit payments', route: '/bill-pay-credit/credit-payments' },
  { label: 'Statements', route: '/bill-pay-credit/statements' },
];

const BillPayCredit = () => {
  const hasBillPayCreditV2FeatureFlag = useBillPayCreditV2Flag();
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasBillPayCreditV2FeatureFlag) {
      navigate('/');
    }
  }, [hasBillPayCreditV2FeatureFlag, navigate]);

  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader title={'Bill pay credit'} />
      <NavTabContainer tabs={tabs}>
        <Outlet />
      </NavTabContainer>
    </FlexbaseMainLayout>
  );
};

export default BillPayCredit;
