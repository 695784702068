import {
  InvoiceWizard,
  PaymentSource,
  useInvoiceWizard,
} from '../../invoice-wizard';
import { Box, Card, Flex, rem, Text, useMantineTheme } from '@mantine/core';
import { DateTime } from 'luxon';
import ReviewBodyLoc from './review-body-loc';
import ReviewBodyRecipient from './review-body-recipient';
import ReviewBodyBankingAccount from './review-body-banking-account';

const getDateLabel = (date?: Date) => {
  if (!date) {
    return '';
  }
  return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_MED);
};

const ReviewPayStep = () => {
  const theme = useMantineTheme();
  const { state } = useInvoiceWizard();
  const { sendFrom } = state.paymentInfo || {};
  const { recipient, recipientAccount } = state || {};
  const recipientName =
    recipient.name ?? `${recipient.firstName} ${recipient.lastName}`;
  const invoiceInfo = {
    memo: state.memo,
    invoiceNumber: state.invoiceNumber,
    dueDate: state.invoiceDetails?.dueDate ?? '',
    paymentSource: state.paymentInfo?.paymentSource ?? '',
  };

  const type: Record<'wire' | 'ach', string> = {
    wire: 'Wire',
    ach: 'ACH',
  };

  const recipientAccountType = type[recipientAccount?.type as 'ach' | 'wire'];

  return (
    <InvoiceWizard.Step hideBack hideNext>
      <Card p="xxl" bg="#ddd4c3">
        <Text size="md">
          {recipientAccountType} to {recipientName}
        </Text>
        <Text size={rem(40)} style={{ lineHeight: rem(40) }}>
          {state.invoiceTotal?.formatted}
        </Text>
      </Card>

      <ReviewBodyRecipient
        recipient={recipient}
        recipientName={recipientName}
        recipientAccount={recipientAccount}
        recipientAccountType={recipientAccountType}
      />

      <Box mt={rem(40)}>
        <Text fw="500" size="xl">
          Paying from
        </Text>
        <Box
          mt="xl"
          p="xl"
          bg="neutral.0"
          sx={{ border: `1px solid ${theme.colors.neutral[4]}` }}
        >
          {state.paymentInfo?.paymentSource === PaymentSource.ACCOUNT && (
            <ReviewBodyBankingAccount sourceAccount={sendFrom} />
          )}

          {state.paymentInfo?.paymentSource === PaymentSource.LOC && (
            <ReviewBodyLoc invoiceTotal={state.invoiceTotal} />
          )}

          <Flex mt="xl" justify="space-between" align="center">
            <Box>
              <Text fw={500} size="sm">
                Sending on
              </Text>
              <Text size="sm">{getDateLabel(state.paymentInfo?.sendOn)}</Text>
            </Box>
            <Box>
              <Text size="sm" c="neutral.8">
                ETA 1 day
              </Text>
            </Box>
          </Flex>
        </Box>

        <Box mt={rem(40)}>
          <Text fw="500" size="xl">
            Memo for recipient
          </Text>
          <Text size="md">{invoiceInfo.memo}</Text>
          <Text size="md">
            {invoiceInfo.invoiceNumber && `#${invoiceInfo.invoiceNumber} -`} Due
            by {getDateLabel(DateTime.fromISO(invoiceInfo.dueDate).toJSDate())}
          </Text>
        </Box>
      </Box>
    </InvoiceWizard.Step>
  );
};

ReviewPayStep.stepId = 'review-pay';

export default ReviewPayStep;
