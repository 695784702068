import { Box, Button, Flex, Radio, rem, Text } from '@mantine/core';
import { formatCurrency } from '@utilities/formatters';
import { useState } from 'react';
import { useConfirmAndReviewStyles } from '@utilities/custom-hooks/use-confirm-and-review.styles';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import AccountSelection from '@common/account-selection';
import FlexNumberInput from '@common/flex-number-input';

type Props = {
  delinquentAmount?: number;
  interestDue?: number;
  paymentAmount: number;
  currentBalance: number;
  closeModal: () => void;
  onReviewClick: (data: {
    chip: 'min' | 'max' | 'custom';
    customAmount: number;
  }) => void;
  onPaymentAmountChange: (amount: number) => void;
  paymentAccounts: (PlaidAccount | DepositAccount)[];
  currentAccount: PlaidAccount | DepositAccount;
  onPaymentAccountChange: (account: PlaidAccount | DepositAccount) => void;
  comeCurrentAmount?: number;
  frozen?: boolean;
  minimumDue: number;
  maxPaymentAmount: number;
  minPaymentAmount: number;
  initialData: { chip: 'min' | 'max' | 'custom'; customAmount: number };
};

// TODO: Make sure the payment selection chips function properly with the new amount parameters
const CardPaymentAmount = ({
  delinquentAmount,
  interestDue,
  paymentAmount,
  currentBalance,
  currentAccount,
  closeModal,
  onReviewClick,
  onPaymentAmountChange,
  comeCurrentAmount,
  paymentAccounts,
  onPaymentAccountChange,
  frozen,
  minimumDue,
  maxPaymentAmount,
  minPaymentAmount,
  initialData,
}: Props) => {
  const { classes } = useConfirmAndReviewStyles();
  const [inputDirty, setInputDirty] = useState(false);
  const [chipValue, setChipValue] = useState(initialData.chip);
  const [customValue, setCustomValue] = useState(initialData.customAmount);

  const setAmountFromChip = (value: string) => {
    if (value === 'max') {
      setChipValue('max');
      onPaymentAmountChange(currentBalance);
    } else if (value === 'min') {
      setChipValue('min');
      onPaymentAmountChange(minimumDue);
    } else {
      setChipValue('custom');
      onPaymentAmountChange(customValue);
    }
  };

  const onInputChange = (currentValue: number) => {
    setInputDirty(true);
    setCustomValue(currentValue);
    onPaymentAmountChange(currentValue);
  };

  const getAmountErrorText = (reviewClick = false) => {
    if (reviewClick) {
      setInputDirty(true);
    }

    if (
      currentAccount.plaidOrDeposit === 'deposit' &&
      currentAccount.available < paymentAmount * 100
    ) {
      return `Your payment amount cannot exceed the available balance in your selected payment account.`;
    }

    if (paymentAmount > maxPaymentAmount) {
      return `Your payment amount cannot exceed ${formatCurrency(
        maxPaymentAmount,
      )}`;
    }

    const runValidation = inputDirty || reviewClick;
    const actualMinPayment = minPaymentAmount || 0.01;

    if (runValidation && paymentAmount < actualMinPayment) {
      return `Your payment must be greater than ${formatCurrency(
        actualMinPayment,
      )}`;
    }

    return '';
  };

  const paymentTypeData = [
    {
      label: 'Current balance',
      amount: currentBalance,
      value: 'max',
    },
    {
      label: 'Minimum',
      amount: minimumDue,
      value: 'min',
    },
    { label: 'Custom amount', amount: customValue, value: 'custom' },
  ];

  const frozenBalanceInfo = [
    { label: 'Current balance', balance: currentBalance ?? 0 },
    { label: 'Amount past due', balance: delinquentAmount ?? 0 },
    { label: 'Interest', balance: interestDue ?? 0 },
    { label: 'Minimum to unfreeze account', balance: comeCurrentAmount ?? 0 },
  ];

  return (
    <>
      {frozen && (
        <Box className={classes.paymentInfo}>
          {frozenBalanceInfo.map((item) => (
            <Flex key={item.label} justify="space-between">
              <Text fw={item.label.includes('unfreeze') ? 700 : 300}>
                {item.label}:
              </Text>
              <Text fw={item.label.includes('unfreeze') ? 700 : 300}>
                {formatCurrency(item.balance)}
              </Text>
            </Flex>
          ))}
        </Box>
      )}
      <Box>
        <Text size="lg">Payment type</Text>
        <Radio.Group value={chipValue}>
          {paymentTypeData.map((item) => (
            <Flex
              p="md"
              h={60}
              my="xs"
              gap="sm"
              bg="neutral.0"
              align="center"
              key={item.value}
              data-testid={`payment-type-${item.value}`}
              onClick={() => setAmountFromChip(item.value)}
              sx={(theme) => ({
                cursor: 'pointer',
                borderRadius: rem(8),
                border:
                  chipValue === item.value
                    ? `2px solid ${theme.colors.primary[3]}`
                    : `1px solid ${theme.colors.neutral[4]}`,
              })}
            >
              <Radio value={item.value} />
              {item.value === 'custom' ? (
                <>
                  <Text size="sm">Custom amount</Text>
                  <FlexNumberInput
                    prefix="$"
                    decimalScale={2}
                    placeholder="$0"
                    thousandSeparator
                    value={customValue}
                    allowNegative={false}
                    data-testid="payment-amount-text-field"
                    onValueChange={(v) => onInputChange(v.floatValue ?? 0)}
                  />
                </>
              ) : (
                <>
                  <Text fw={600}>{formatCurrency(item.amount)}</Text>
                  <Text size="sm">{item.label}</Text>
                </>
              )}
            </Flex>
          ))}
        </Radio.Group>
        <Text
          className={
            getAmountErrorText()
              ? classes.currentBalanceError
              : classes.currentBalance
          }
        >
          {getAmountErrorText() ||
            (frozen &&
              `The minimum amount you can pay is ${formatCurrency(
                minPaymentAmount,
              )}. This will not unfreeze your account.`)}
        </Text>
      </Box>
      <Box mt="xxxl">
        <Text mb="xs" size="lg">
          Pay From
        </Text>
        <AccountSelection
          currentAccount={currentAccount}
          accounts={paymentAccounts}
          onAccountChange={(account) =>
            onPaymentAccountChange(
              paymentAccounts.find((p) => p.id === account.id)!,
            )
          }
          showAccountFilters
        />
      </Box>
      <Flex mt="xxxl" justify="space-between">
        <Button
          variant="neutral-outline"
          onClick={() => {
            closeModal();
          }}
          size="lg"
          data-testid="go-back-button"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (!getAmountErrorText(true)) {
              onReviewClick({ chip: chipValue, customAmount: customValue });
            }
          }}
          size="lg"
          data-testid="review-payment-button"
        >
          Review
        </Button>
      </Flex>
    </>
  );
};

export default CardPaymentAmount;
