import {
  Box,
  Button,
  Flex,
  Group,
  rem,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useNavigate } from 'react-router-dom';
import {
  FilterConfig,
  useFilterModal,
} from '@common/utilities/filter/filter-modal';
import { ActiveFiltersReturnType } from '@common/utilities/filter/filters';
import { useBillpayInvoicesModalFilters } from '../bills/use-billpay-bills-filters';
import StatusSelect from './status-select/select';
import { BillpayInvoice } from 'types/bill-pay';
import { UserInfoState } from 'types/user-info';
import { useRecoilValue } from 'recoil';
import { FilterChips } from '@common/composites/filter-chips';
import { PiFunnel, PiMagnifyingGlass, PiPlusBold } from 'react-icons/pi';

type Props = {
  placeholder: string;
  invoices?: BillpayInvoice[];
  filtersData?: FilterConfig[];
  showFilters?: boolean;
  useFilters: () => ActiveFiltersReturnType<any>;
  bills?: boolean;
};

const Header = ({
  placeholder,
  invoices,
  filtersData = [],
  useFilters,
  bills,
}: Props) => {
  const user = useRecoilValue(UserInfoState);
  const isBookkeeper = user.roles.includes('ACCOUNTANT');
  const theme = useMantineTheme();
  const useMobileView = useMediaQuery(`(max-width: ${theme.breakpoints.md})`);

  const filterHook = useFilters();
  const modalFilterHook = useBillpayInvoicesModalFilters();
  const {
    activeFiltersArray,
    removeFilter,
    getFilterByKey,
    addFilter,
    removeAllFilters,
    openResponsiveFilterModal,
  } = useFilterModal({ filterHook, modalFilterHook });

  const navigate = useNavigate();
  const handleCreateInvoice = () => {
    navigate('/bill-pay/new');
  };

  const handleSearchChange = (value: string) => {
    if (!value) {
      removeFilter('search');
      return;
    }

    addFilter('search', {
      key: 'search',
      filterValue: value,
      label: `Includes ${value}`,
      showChip: false,
      fn: (item) => {
        const normalizedFilterText = value.toLowerCase();
        const strValues = Object.values(item).filter(isTruthyString);
        return strValues
          .map((v) => v.toLowerCase())
          .some((v) => v.includes(normalizedFilterText));
      },
    });
  };

  const inputWidth = useMobileView ? '100%' : 368;

  const handleRemoveFilter = (filterKey: string) => {
    removeFilter(filterKey);
  };

  const handleClearFilters = () => {
    removeAllFilters();
  };

  const openModal = () => {
    openResponsiveFilterModal(filtersData);
  };

  return (
    <>
      <Group justify="space-between" mb="md">
        <Group gap="md" sx={{ justifyContent: 'space-between' }}>
          <Box
            sx={(_, helpers) => ({
              display: 'flex',
              gap: theme.spacing.md,
              [helpers.smallerThan('sm')]: {
                flexDirection: 'column',
                width: '100%',
              },
            })}
            mb="md"
          >
            <TextInput
              aria-label={placeholder}
              w={inputWidth}
              placeholder={placeholder}
              leftSection={
                <PiMagnifyingGlass
                  size="1.25rem"
                  color={theme.colors.neutral[8]}
                />
              }
              value={getFilterByKey('search')?.filterValue || ''}
              onChange={(e) => handleSearchChange(e.target.value)}
            />
            {bills && invoices && (
              <Flex>
                <StatusSelect
                  invoices={invoices}
                  useFilters={useFilters}
                  useModalFilters={useBillpayInvoicesModalFilters}
                />
                <Button
                  ml={rem(10)}
                  variant="neutral-outline"
                  rightSection={<PiFunnel />}
                  onClick={() => openModal()}
                  disabled={invoices.length === 0}
                >
                  More filters
                </Button>
              </Flex>
            )}
          </Box>
        </Group>
        {!isBookkeeper && (
          <Group>
            <Button
              type="button"
              bg={'primary.6'}
              leftSection={<PiPlusBold />}
              onClick={handleCreateInvoice}
              data-testId={'add-new-bill-btn'}
            >
              Add New Bill
            </Button>
          </Group>
        )}
      </Group>

      <FilterChips
        filterArray={activeFiltersArray}
        onRemoveFilter={handleRemoveFilter}
        onClearFilters={handleClearFilters}
      />
    </>
  );
};

export default Header;
