/**
 * @deprecated
 *
 * We're moving to the event schema defined here in Notion
 * https://www.notion.so/flexbase/Flex-Data-Standardization-ac158e5bae98466b94443735ec304f29?pvs=4
 *
 * ApplicationEvents enum is following the new, preferred schema
 */
export enum LegacyEvents {
  APPLICATION_ERROR = 'Application Error Occurred',
  APPLICATION_GENERAL_DETAILS_SUBMITTED = 'Application General Details Submitted',
  APPLICATION_ERROR_REGISTER = 'Application Error Registering New User',
  BNPL_TRANACTION_APPROVED = 'BNPL Transaction Approved',
  BNPL_TRANACTION_ERROR = 'BNPL Transaction Error',
  BNPL_CREDIT_UNDERWRITING = 'BNPL Credit Underwriting Generated',
  LOGIN_SUCCESS = 'Login Successful',
  LOGIN_FAILURE = 'Login Unsuccessful',
  LOGIN_PASSWORD_RESET_REQUEST = 'Login Password Reset Requested',
  LOGIN_F2A_VALID = 'Login Valid F2A Submitted',
  LOGIN_F2A_INVALID = 'Login Invalid F2A Submitted',
  CREDIT_LEVEL_TWO_REQUEST = 'Credit Level 2 Requested',
  COMPANY_UPDATED = 'Company Updated',
  COMPANY_CREATED = 'Company Created',
  COMPANY_OWNER_ADDED = 'Company Owner Added',
  COMPANY_OFFICER_ADDED = 'Company Officer Added',
  PROFILE_UPDATED = 'Profile Updated for User',
  CREDIT_UNDERWRITING = 'Credit Underwriting Generated',
  CREDIT_MAKE_PAYMENT = 'Credit Payment Submitted',
  CREDIT_DOWNLOAD_STATEMENT = 'Credit Statement Downloaded',
  CREDIT_DISPUTE_TRANSACTION_EMAIL = 'Credit Disputed a Transaction',
  CREDIT_SYNC_QUICKBOOKS = 'Credit Started Sync with Quickbooks',
  CREDIT_AUTOPAY = 'Credit Switched Autopay Flag',
  CREDIT_DOWNLOAD_CSV_TRANSACTIONS = 'Credit Downloaded Transactions to CSV',
  CARD_SHIPPED = 'Credit Card Shipped',
  REGISTRATION_ATTEMPT = 'Registration Attempt',
  REGISTRATION_VARIANT = 'Marketing Registration Variant',
  LOGIN_ATTEMPT = 'Login Attempt',
  BANKING_APPLICATION_STARTED = 'Banking Application Started',
  BANKING_APPLICATION_SUBMITTED = 'Banking Application Submitted',
  BANKING_APPLICATION_ADDITIONAL_DOCS = 'Banking Application Documents Upload Started',
  BANKING_PAYMENT_SUBMITTED = 'Banking Payment Submitted',
  BANKING_PAYMENT_ERROR = 'Banking Payment Error',
  BANKING_PAYMENT_CANCELLED = 'Banking Payment Cancelled',
  BANKING_EXTERNAL_ACCOUNT_LINKED = 'Banking External Account Linked',
  BANKING_EXTERNAL_ACCOUNT_UNLINKED = 'Banking External Account Unlinked',
  BANKING_STATEMENT_REQUESTED = 'Banking Statement Requested',
  BANKING_DEBIT_CARD_CREATE = 'Banking Debit Card Created',
  BANKING_DEBIT_CARD_UPDATE_LIMITS = 'Banking Debit Card Limits Updated',
  BANKING_DEBIT_CARD_STATE_UPDATE = 'Banking Debit Card State Updated',
  BANKING_SUBMIT_BILL_PAY = 'Bill Pay Submitted',
  BANKING_LIST_BILL_PAY = 'Bill Pay Companies Viewed',
  PAYMENTS_SEND_PAYMENT = 'Payments Sent a Payment',
  PAYMENTS_CREATE_RECIPIENT = 'Payments Created a Recipient',
  PAYMENTS_REMOVE_RECIPIENT = 'Payments Removed a Recipient',
  PAYMENTS_VIEW_RECIPIENT_TRANSACTIONS = 'Payments View Recipient Transactions',
  DOCUMENT_UPLOAD = 'Document Uploaded',
}

export enum ApplicationEvents {
  APPLICATION_START_CLICKED = 'application.start.clicked',
  PHONE_VERIFICATION_SUBMITTED = 'application.phone.verification.submitted',
  DOB_CONFIRM_CLICKED = 'application.dob.confirm.clicked',
  IDENTITY_VERIFY_CLICKED = 'application.identity.verify.clicked',
  JOB_TITLE_SUBMIT_CLICKED = 'application.job_title.submit.clicked',
  BUSINESS_ACTIVITY_SUBMIT_CLICKED = 'application.business_activity.submit.clicked',
  BUSINESS_VERIFY_CLICKED = 'application.business.verify.clicked',
  BUSINESS_OWNER_ADDED = 'application.business_owner.added',
  BUSINESS_OWNERS_SUBMIT_CLICKED = 'application.business_owners.submit.clicked',
  CONTROL_PERSON_SUBMIT_CLICKED = 'application.control_person.submit.clicked',
  EXTERNAL_ACCOUNT_LINKED = 'application.external_account.linked',
  BANK_VERIFICATION_SUBMIT_CLICKED = 'application.bank_verification.submit.clicked',
  APPLICATION_SUBMIT_CLICKED = 'application.submit.clicked',
  APPLICATION_BANKING_COMPLETED = 'application.banking.completed',
  APPLICATION_BANKING_DENIED = 'application.banking.denied',
  APPLICATION_CREDIT_COMPLETED = 'application.credit.completed',
  APPLICATION_CREDIT_DENIED = 'application.credit.denied',
}

export type AnalyticsEvent = LegacyEvents | ApplicationEvents;
