import ValidatorFn from './validator-fn';
import { Country, isPossiblePhoneNumber } from 'react-phone-number-input';

export const validateUSPhoneNumber = (value: string): boolean =>
  /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/.test(value);

export function PhoneNumberValidator(
  errorMessage?: string,
): ValidatorFn<string> {
  return (val) =>
    validateUSPhoneNumber(val)
      ? null
      : (errorMessage ?? 'Must be a valid US phone number');
}

/**
 * Validates a phone number against a country code.
 *
 * Under the hood, isPossiblePhoneNumber() is a simple length check against valid lengths for the given country.
 * We don't want to do anything more than that on the frontend, instead let the backend handle more specific validation.
 */
export const validateIntlPhoneNumber = (
  value: string,
  countryCode: Country,
) => {
  return isPossiblePhoneNumber(value, countryCode);
};
