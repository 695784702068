import { Box, BoxProps, Text, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { CheckBlack } from '../../../assets/svg';
import { ReactNode } from 'react';

export const RadioSelectCard = ({
  selected,
  onClick,
  title,
  description,
  id,
  boxProps,
}: {
  selected: boolean;
  onClick: () => void;
  title: string;
  description: ReactNode;
  id?: string;
  boxProps?: BoxProps;
}) => {
  const { classes } = useStyles(selected);
  return (
    <Box
      className={classes.radioCard}
      onClick={() => onClick()}
      id={`radio-select-card-${id}`}
      data-testid={`radio-select-card-${id}`}
      {...boxProps}
    >
      <Box className={classes.radioIcon}>{selected && <CheckBlack />}</Box>
      <div>
        <Text fz={rem(16)} fw={500} opacity={selected ? 1 : 0.7}>
          {title}
        </Text>
        <Text fz={rem(14)} opacity={selected ? 1 : 0.7}>
          {description}
        </Text>
      </div>
    </Box>
  );
};

const useStyles = createStyles((theme, selected: boolean, helpers) => ({
  radioCard: {
    width: rem(340),
    height: rem(106),
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[4]}`,
    padding: `${theme.spacing.md} ${theme.spacing.lg}`,
    display: 'flex',
    gap: theme.spacing.md,
    alignItems: 'center',
    cursor: 'pointer',
    '&:active': {
      transform: `translate(${rem(-2)})`,
    },
    color: selected ? theme.colors.neutral[3] : theme.colors.primary[8],
    ...(selected && {
      backgroundColor: theme.colors.primary[8],
    }),
    [helpers.smallerThan('sm')]: {
      width: '100%',
    },
  },
  radioIcon: {
    height: rem(24),
    width: rem(24),
    minHeight: rem(24),
    minWidth: rem(24),
    borderRadius: '100%',
    border: `1px solid ${theme.colors.neutral[4]}`,
    backgroundColor: selected ? theme.colors.neutral[0] : '',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
