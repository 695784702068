import { useForm } from '@mantine/form';
import { useState } from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import {
  Alert,
  Box,
  Button,
  Group,
  Select,
  rem,
  useMantineTheme,
  TextInput,
} from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import Multiselect from '@common/custom-multiselect';
import { formatCurrency } from '@utilities/formatters';
import { notifications } from '@mantine/notifications';
import {
  useCardCategory,
  useUpdateCardCategory,
  useUpdateCreditCardMutation,
  useUpdatePersonCard,
} from '@queries/use-credit-cards';
import {
  CardDetailsModel,
  creditCardToDetailsModel,
  platformCardToDetailsModel,
} from '@common/card-details';
import { LIMIT_INTERVALS } from 'constants/limit-intervals';
import FlexNumberInput from '../../../../components/flex-number-input';
import { useCreditBalance } from '@queries/use-credit-balance';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { DateTime } from 'luxon';
import { DatePickerInput } from '@mantine/dates';
import CardCategorySelect from '@common/composites/card-category-select';
import { SpendPlanView } from '@flexbase-eng/types/dist/accounting';
import { Group as CardInfoGroup } from 'constants/card-info';
import { PiCalendarLight } from 'react-icons/pi';
import { Interval, Line } from '@flexbase-eng/sdk-typescript/models/components';
import { RFCDate } from '@flexbase-eng/sdk-typescript/types';

type Props = {
  card: CardDetailsModel;
  creditLine?: Line;
  onCancelClick: () => void;
  onCardUpdated: (updatedCard: CardDetailsModel) => void;
  shouldRenderCategory: boolean;
  connectionId: string;
  spendPlan: SpendPlanView | undefined;
  formatFrequency: (frequency: string) => string;
};

/**
 * Checks if a string is an Interval type, mostly to appease TS without resorting to casting.
 */
function stringIsInterval(value: string): value is Interval {
  return Object.keys({
    annually: null,
    daily: null,
    monthly: null,
    quarterly: null,
    unlimited: null,
    weekly: null,
  } satisfies Record<Interval, null>).includes(value);
}

const EditCard = ({
  card,
  creditLine,
  onCancelClick,
  shouldRenderCategory,
  connectionId,
  onCardUpdated,
  spendPlan,
  formatFrequency,
}: Props) => {
  const theme = useMantineTheme();
  const { classes } = useStyles();
  const { accountId, businessId } = useRecoilValue(ApplicationState);
  const { data } = useCreditBalance(businessId);
  const [errorMessage, setErrorMessage] = useState('');
  const [terminateDate, setTerminateDate] = useState<Date | null>(
    card.suspendDate
      ? DateTime.fromFormat(card.suspendDate, 'yyyy-MM-dd').toJSDate()
      : null,
  );

  const { mutate: updateCard, isPending: isPendingUpdateCard } =
    useUpdatePersonCard();
  const { mutate: updateApiCard, isPending: isPendingUpdateApiCard } =
    useUpdateCreditCardMutation();
  const { mutate: updateCardExpenseCategory } = useUpdateCardCategory();

  const { data: cardCategory } = useCardCategory({
    connectionId,
    cardId: card.id,
  });

  const form = useForm({
    initialValues: {
      cardName: card?.name ?? '',
      amount: card?.limits?.amount ?? 0,
      groups: spendPlan?.id
        ? ((spendPlan?.allowedMccs?.length > 0
            ? spendPlan.allowedMccs
            : spendPlan?.blockedMccs) as CardInfoGroup[])
        : (card?.limits?.groups ?? []),
      interval: card?.limits?.interval ?? 'unlimited',
      categoryId: cardCategory?.card?.category.id ?? '',
    },
    validate: {
      amount: (v, values) => {
        if (values.interval === 'unlimited') {
          return null;
        }
        if (data?.creditLimit && v > data.creditLimit) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            data.creditLimit,
          )}`;
        }
      },
    },
  });

  const isPendingSave = isPendingUpdateApiCard || isPendingUpdateCard;

  const handleSave = () => {
    const validation = form.validate();

    if (!validation.hasErrors) {
      if (creditLine?.issuer === 'marqeta') {
        handleSavePlatform();
      } else {
        handleSaveApi();
      }
    }

    function handleSavePlatform() {
      updateCard(
        {
          accountId,
          personId: card.personId,
          cardId: card.id,
          updateCard: {
            name: form.values.cardName,
            limits: {
              amount:
                form.values.interval === 'unlimited'
                  ? undefined // must be either undefined or >0 in Platform, so can't default to 0 like in API
                  : Number(form.values.amount),
              groups: form.values.groups,
              interval: stringIsInterval(form.values.interval)
                ? form.values.interval
                : undefined,
            },
            suspendDate: terminateDate ? new RFCDate(terminateDate) : undefined,
          },
        },
        {
          onSuccess: (updatedCard) => {
            onCardUpdated(platformCardToDetailsModel(updatedCard));
          },
        },
      );
    }

    function handleSaveApi() {
      updateApiCard(
        {
          id: card.id,
          card: {
            cardName: form.values.cardName,
            expensesTypes: {
              amount:
                form.values.interval === 'unlimited'
                  ? 0
                  : Number(form.values.amount),
              groups: form.values.groups,
              interval:
                form.values.interval === 'unlimited'
                  ? null
                  : form.values.interval,
            },
            notifyUse: card.notifyUse,
            terminateAt:
              terminateDate !== null
                ? DateTime.fromJSDate(terminateDate).toFormat('yyyy-MM-dd')
                : null,
          },
        },
        {
          onSuccess: (cardUpdated) => {
            if (form.values.categoryId && shouldRenderCategory) {
              updateCardExpenseCategory({
                cardId: cardUpdated.card.id,
                categoryId: form.values.categoryId,
                connectionId,
              });
            }
            onCardUpdated(creditCardToDetailsModel(cardUpdated.card));
            notifications.show({
              title: 'Updated',
              message: 'This card was successfully updated',
            });
          },
          onError: () => {
            setErrorMessage(
              `An error occurred while trying to update card with name ${card.name}`,
            );
          },
        },
      );
    }
  };

  const handleOnchangeCategory = (value: string | null) => {
    form.setFieldValue('categoryId', value || '');
  };

  return (
    <Box w="100%">
      <TextInput
        label="Card purpose"
        value={form.values.cardName}
        onChange={(e) => {
          form.setFieldValue('cardName', e.target.value);
        }}
      />
      <Box mt="md">
        <Multiselect form={form} disabled={!!spendPlan} />
      </Box>
      {spendPlan ? (
        <>
          <TextInput
            label="Spend plan"
            value={spendPlan?.name}
            disabled
            mt="md"
          />
          <TextInput
            label="Limit type"
            value={formatFrequency(spendPlan.frequency)}
            disabled
            mt="md"
          />
        </>
      ) : (
        <Select
          label="Limit frequency"
          value={form.values.interval}
          placeholder="Select limit frequency"
          data={LIMIT_INTERVALS}
          onChange={(value) => {
            form.setFieldValue('interval', value!);
          }}
          mt="md"
        />
      )}

      {form.values.interval !== 'unlimited' && !spendPlan && (
        <FlexNumberInput
          style={{ marginTop: `${theme.spacing.md}` }}
          mt={rem(6)}
          variant="default"
          label="Limit"
          radius={rem(8)}
          labelProps={{
            color: theme.colors.neutral[8],
          }}
          leftSection={<>$</>}
          pattern="[0-9]*"
          data-testid="amount"
          thousandSeparator=","
          styles={{
            label: { color: theme.colors.neutral[10] },
            input: {
              '&:focus': { borderColor: theme.primaryColor },
            },
          }}
          onValueChange={(value) => {
            form.setFieldValue('amount', value.floatValue ?? 0);
          }}
          value={form.values.amount}
          error={form.errors.amount}
        />
      )}
      {shouldRenderCategory && (
        <CardCategorySelect
          classNames={{
            label: classes.cardCategoryLabel,
            input: classes.cardCategory,
          }}
          value={form.values.categoryId}
          onChange={handleOnchangeCategory}
        />
      )}
      <DatePickerInput
        leftSection={
          <PiCalendarLight size={'1.25rem'} color={theme.colors.neutral[8]} />
        }
        mt="md"
        minDate={DateTime.fromJSDate(new Date()).plus({ days: 1 }).toJSDate()}
        clearable={true}
        valueFormat="YYYY-MM-DD"
        label="Termination date"
        value={terminateDate}
        onChange={(d) => {
          setTerminateDate(d);
        }}
      />

      {errorMessage && (
        <Box mt="lg">
          <Alert
            icon={<FaExclamationCircle color="red" />}
            color="red"
            withCloseButton
            variant="primary-light"
            onClose={() => setErrorMessage('')}
            styles={() => ({
              closeButton: {
                margin: `auto ${theme.spacing.xxs} auto ${theme.spacing.xs}`,
              },
              icon: { margin: `auto ${theme.spacing.xs} auto 0` },
              message: {
                margin: `auto ${theme.spacing.xs} auto ${theme.spacing.xxs}`,
                color: theme.colors.neutral[8],
              },
            })}
          >
            {errorMessage}
          </Alert>
        </Box>
      )}

      <Group mt="md" sx={{ justifyContent: 'space-between' }}>
        <Button
          variant="neutral-outline"
          onClick={() => onCancelClick()}
          bg="neutral.0"
        >
          Back
        </Button>
        <Button loading={isPendingSave} onClick={handleSave}>
          Save
        </Button>
      </Group>
    </Box>
  );
};

export default EditCard;

export const useStyles = createStyles(() => ({
  cardCategoryLabel: {
    marginTop: rem(16),
  },
  cardCategory: {
    margin: `${rem(3)} 0 !important`,
  },
}));
