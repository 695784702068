import { Text, Avatar, Flex, Box, useMantineTheme } from '@mantine/core';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { PiBank } from 'react-icons/pi';
import {
  capitalizeOnlyFirstLetter,
  centsToFormattedCurrency,
} from '@utilities/formatters';

type ReviewBodyBankingAccountProps = {
  sourceAccount: PlaidAccount | DepositAccount | undefined;
};

const ReviewBodyBankingAccount = ({
  sourceAccount,
}: ReviewBodyBankingAccountProps) => {
  const theme = useMantineTheme();
  const sourceAccountName =
    sourceAccount?.plaidOrDeposit === 'deposit'
      ? (sourceAccount.nickName ?? sourceAccount.name)
      : `${sourceAccount?.accountName ?? sourceAccount?.bankName} ${GetPaddedAccountMask(sourceAccount?.last4 ?? '', 3)}`;

  return (
    <Flex mt="xs" gap="md" align="center">
      <Avatar bg="primary.0">
        <PiBank fill={theme.colors.neutral[10]} size="1.5rem" />
      </Avatar>
      <Box>
        <Text size="sm">{sourceAccountName}</Text>
        <Text size="sm">
          {centsToFormattedCurrency(sourceAccount?.available || 0)} available •{' '}
          {capitalizeOnlyFirstLetter(sourceAccount?.accountType ?? '')}
        </Text>
      </Box>
    </Flex>
  );
};

export default ReviewBodyBankingAccount;
