import {
  Button,
  Flex,
  Group,
  LoadingOverlay,
  rem,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import {
  useApprovePayment,
  useCancelPayment,
  useConfirmPayment,
  useSendCodeConfirmPayment,
} from '@queries/use-payments';
import { UserIdState } from 'recoil-state/application/onboarding-form.state';
import TwoFactorAuth from '@common/composites/two-factor-auth';
import { PropsWithChildren, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { RolesSelector } from 'recoil-state/application/product-onboarding';
import { useStyles } from 'areas/payments/components/payment-details-modal/styles';
import { showNotification } from '@mantine/notifications';
import { createStyles } from '@mantine/emotion';
import {
  PiCheck,
  PiCheckCircle,
  PiWarningCircleBold,
  PiX,
} from 'react-icons/pi';

const useManagePaymentStyles = createStyles((theme) => ({
  buttonOutline: {
    border: `1px solid ${theme.colors.primary[6]} !important`,
  },
  closeIcon: {
    color: 'neutral.7',
  },
  cancelIcon: {
    color: 'critical.6',
  },
  closeIconButton: {
    border: `1px solid ${theme.colors.neutral[4]} !important`,
  },
  compactTwoFactorAuthContainer: {
    minWidth: '220px',
  },
}));

// Payment statuses that can be canceled for *any* payment type
const cancelableStates = [
  'AwaitingApproval',
  'AwaitingConfirmation',
  'Scheduled',
  'Queued',
];

// Payment statuses that can be canceled for ACH payments
const achCancelableStates = ['Pending', 'PendingReview'];

export const getIsCancelable = (status: string, type: string) => {
  return (
    cancelableStates.includes(status) ||
    (type === 'ACH payment' && achCancelableStates.includes(status))
  );
};

type ConditionalContainerProps = PropsWithChildren & {
  isCompact: boolean;
};

const TwoFactorContainer = ({
  isCompact,
  children,
}: ConditionalContainerProps) => {
  const useTwoFactorContainerStyles = createStyles(() => ({
    compactContainer: {
      display: 'flex',
      gap: '.5rem',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
    container: {
      display: 'flex',
      alignItems: 'baseline',
      gap: '.5rem',
    },
  }));
  const { classes } = useTwoFactorContainerStyles();
  const conditionalWrapper = isCompact
    ? classes.compactContainer
    : classes.container;

  return <div className={conditionalWrapper}>{children}</div>;
};

const PrimaryActionContainer = ({
  isCompact,
  children,
}: ConditionalContainerProps) => {
  const { classes } = useStyles();

  return isCompact ? (
    children
  ) : (
    <Flex justify="end" className={classes.detailsContainer}>
      {children}
    </Flex>
  );
};

const getCancellationCopy = (isOwnPayment: boolean) => {
  return isOwnPayment
    ? {
        cancelButtonText: 'Cancel Payment',
        cancelSuccessText: 'Payment request canceled.',
      }
    : {
        cancelButtonText: 'Deny',
        cancelSuccessText: 'Payment request denied.',
      };
};

// Manage the lifecycle of the Payment through Approval, Confirmation, or Cancellation
type ManagePaymentProps = {
  paymentId: string;
  createdBy: string;
  approvedBy?: string;
  status: string;
  type: string;
  isCompact?: boolean;
  onSuccess: () => void;
};

type IntermediaryStepProps = {
  onUndo: () => void;
  onApprove: () => void;
};

const IntermediaryStep = ({ onUndo, onApprove }: IntermediaryStepProps) => {
  const { classes: managePaymentStyles } = useManagePaymentStyles();
  const commonButtonProps = {
    variant: 'neutral-outline' as const,
    className: managePaymentStyles.buttonOutline,
  };

  return (
    <Group gap="xs">
      <Button onClick={onUndo} {...commonButtonProps}>
        Undo
      </Button>
      <Button onClick={onApprove} {...commonButtonProps}>
        Confirm approval
      </Button>
    </Group>
  );
};

const ManagePayment = ({
  paymentId,
  createdBy,
  approvedBy,
  status,
  type,
  isCompact = false,
  onSuccess,
}: ManagePaymentProps) => {
  const theme = useMantineTheme();
  const roles = useRecoilValue(RolesSelector);
  const userId = useRecoilValue(UserIdState);

  const sendCodeRequest = useSendCodeConfirmPayment();
  const confirmRequest = useConfirmPayment();
  const approveRequest = useApprovePayment();
  const cancelRequest = useCancelPayment();
  const { classes: managePaymentStyles } = useManagePaymentStyles();

  const [showIntermediaryStep, setShowIntermediaryStep] = useState(false);
  const [isConfirming, setIsConfirming] = useState(false);

  // initiate a 2FA challenge for the approving user
  const send2FACode = () => {
    if (paymentId) {
      sendCodeRequest.mutateAsync(paymentId).then(() => setIsConfirming(true));
    }
  };

  // handle changes to the 2FA input
  const onChange2FA = (code: string) => {
    if (paymentId && code.length === 6) {
      confirmRequest
        .mutateAsync({
          id: paymentId,
          code,
        })
        .then(() => {
          onSuccess();
          setIsConfirming(false);
          showNotification({
            title: 'Success!',
            message: (
              <Group align="center" gap="xs">
                <PiCheckCircle /> Payment request approved!
              </Group>
            ),
            color: 'sage.4',
          });
        });
    }
  };

  // approve this payment and immediately queue it up for confirmation
  const approvePayment = () => {
    if (paymentId) {
      approveRequest.mutateAsync(paymentId).then((res) => {
        if (res.status === 'AwaitingConfirmation') {
          send2FACode();
        } else {
          onSuccess();
        }
      });
    }
  };

  const handleConditionalApproval = (requiresIntermediaryStep: boolean) => {
    if (requiresIntermediaryStep) {
      setShowIntermediaryStep((prev) => !prev);
    } else {
      approvePayment();
    }
  };

  const handleUndo = () => {
    setShowIntermediaryStep(false);
  };

  // reject this payment via cancelation
  const cancelPayment = () => {
    if (paymentId) {
      cancelRequest.mutateAsync(paymentId).then(() => {
        onSuccess();
        showNotification({
          title: 'Success!',
          message: (
            <Group align="center" gap="xs">
              <PiCheckCircle
                size={'1.563rem'}
                color={theme.colors.primary[2]}
              />{' '}
              {cancelSuccessText}
            </Group>
          ),
          color: 'sage.4',
        });
      });
    }
  };

  // derive important request states from the union of all requests
  const isLoading =
    sendCodeRequest.isPending ||
    confirmRequest.isPending ||
    approveRequest.isPending ||
    cancelRequest.isPending;

  const errorMessage =
    sendCodeRequest.error?.message ||
    approveRequest.error?.message ||
    cancelRequest.error?.message;

  // derive permissions from Payment progenitors and caller role
  const isOwnPayment = userId === createdBy;
  const isApprovedByCaller = userId === approvedBy;
  const canRequest = roles.includes('ADMIN');
  const canApprove = roles.includes('COMPTROLLER');
  const canConfirm = canApprove && (isApprovedByCaller || isOwnPayment);
  const canCancel = (canRequest && isOwnPayment) || canApprove;
  const isCancelable =
    cancelableStates.includes(status) ||
    (type === 'ACH payment' && achCancelableStates.includes(status));

  // derive shorthands for what needs to be done
  const needsApproval = status === 'AwaitingApproval';
  const needsConfirmation = status === 'AwaitingConfirmation';
  const needsIntermediaryStep = type === 'Wire' && needsApproval;
  const { cancelButtonText, cancelSuccessText } =
    getCancellationCopy(isOwnPayment);

  // derive a set of components to render from Payment needs and User abilities
  let primaryAction;

  if (isConfirming) {
    primaryAction = (
      <TwoFactorContainer isCompact={isCompact}>
        <TwoFactorAuth
          hasRetry={false}
          errorMsg={confirmRequest.error?.message || ''}
          onChange={onChange2FA}
          onResendCode={send2FACode}
          loading={isLoading}
          containerStyles={
            isCompact ? managePaymentStyles.compactTwoFactorAuthContainer : ''
          }
        />
        <Button
          onClick={() => setIsConfirming(false)}
          variant="neutral-outline"
          mt={isCompact ? 0 : rem(20)}
          className={managePaymentStyles.closeIconButton}
          size={isCompact ? 'compact-sm' : 'sm'}
        >
          {isCompact ? (
            <PiX className={managePaymentStyles.closeIcon} size={'1.25rem'} />
          ) : (
            'Cancel 2FA'
          )}
        </Button>
      </TwoFactorContainer>
    );
  } else {
    const buttons = [];
    if (isCancelable && canCancel) {
      buttons.push(
        <Button
          onClick={cancelPayment}
          key="cancel"
          variant="neutral-outline"
          c="critical.6"
          sx={() => ({
            border: `1px solid ${theme.colors.critical[6]}`,
          })}
          leftSection={
            <PiX className={managePaymentStyles.cancelIcon} size={'1.25rem'} />
          }
        >
          {cancelButtonText}
        </Button>,
      );
    }

    if (needsConfirmation && canConfirm) {
      buttons.push(
        <Button
          onClick={send2FACode}
          key="confirm"
          leftSection={<PiCheck size={'1.25rem'} color="white" />}
        >
          Confirm Payment
        </Button>,
      );
    }

    if (needsApproval && canApprove) {
      buttons.push(
        <Button
          onClick={() => handleConditionalApproval(needsIntermediaryStep)}
          key="approve"
          leftSection={<PiCheck size={'1.25rem'} color="white" />}
        >
          Approve Payment
        </Button>,
      );
    }

    if (buttons.length > 0) {
      primaryAction = (
        <>
          <LoadingOverlay visible={isLoading} />
          {needsIntermediaryStep && showIntermediaryStep ? (
            <IntermediaryStep onUndo={handleUndo} onApprove={approvePayment} />
          ) : (
            <Group gap="xs">{buttons}</Group>
          )}
          {errorMessage && (
            <Tooltip label={errorMessage} withArrow multiline>
              <Flex ml={rem(16)} mt={isCompact ? 'initial' : rem(8)}>
                <PiWarningCircleBold size={'1.25rem'} color="red" />
              </Flex>
            </Tooltip>
          )}
        </>
      );
    }
  }

  return primaryAction ? (
    <PrimaryActionContainer isCompact={isCompact}>
      {primaryAction}
    </PrimaryActionContainer>
  ) : null;
};

export default ManagePayment;
