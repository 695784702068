import { FlexWizard } from '@common/emerald/wizard/components/flex-wizard';
import {
  Badge,
  Box,
  Group,
  rem,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { FlexIcon } from 'assets/svg';
import { PiClockBold } from 'react-icons/pi';

type OnboardingHeaderProps = {
  progress: number;
  titleText: string;
  subtitleText?: string;
  estimatedTime?: string;
};

const OnboardingHeader = ({
  progress,
  titleText,
  subtitleText,
  estimatedTime,
}: OnboardingHeaderProps) => {
  const theme = useMantineTheme();
  return (
    <Box>
      <Group py="lg" px="sm">
        <FlexIcon width={88} color={theme.colors.neutral[9]} />
      </Group>
      <FlexWizard.Progress value={progress} />
      <Stack
        w={{ base: '100%', sm: rem(525) }}
        mx="auto"
        pt={rem(48)}
        pb="lg"
        px={{ base: 'xxxl', sm: 0 }}
      >
        {estimatedTime && (
          <Badge bg={theme.colors.promote[1]}>
            <Group gap="xxs">
              <PiClockBold color={theme.colors.promote[7]} size={12} />
              <Text c={theme.colors.promote[7]} size="xs">
                {estimatedTime}
              </Text>
            </Group>
          </Badge>
        )}
        <Text ff="Redaction" fz={rem(32)} lh="normal">
          {titleText}
        </Text>
        {subtitleText && <Text lh="normal">{subtitleText}</Text>}
      </Stack>
    </Box>
  );
};

export default OnboardingHeader;
