import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  grid: {
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoRows: 'min-content',
    gap: 32,

    '@media(max-width: 767px)': {
      gridTemplateColumns: '100%',
    },
  },
  creditPayment: {
    gridColumn: 1,
    gridRow: 1,
    '@media(max-width: 767px)': {},
  },
  autopay: {
    gridColumn: 1,
    gridRow: 2,
    '@media(max-width: 767px)': {},
  },
  payments: {
    gridColumn: '1 / 3',
    gridRow: 3,
    '@media(max-width: 767px)': {
      gridColumn: 1,
      gridRow: 4,
    },
  },
  schedule: {
    gridColumn: 2,
    gridRow: '1 / 3',
    backgroundColor: theme.colors.neutral[0],
    width: '100%',
    borderRadius: '0.5rem',
    '@media(max-width: 767px)': {
      gridColumn: 1,
      gridRow: 3,
    },
  },
  container: {
    flex: 1,
    width: '100%',
    height: '100%',
    gap: 24,
    display: 'flex',
    flexDirection: 'column',
  },
  scheduleAndWidgetContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 24,
    '@media(max-width: 767px)': {
      flexDirection: 'column',
    },
  },
  widgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'start',
    gap: 31,
    width: '57%',
    '@media(max-width: 767px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  scheduleContainer: {
    backgroundColor: theme.colors.neutral[0],
    width: '100%',
    maxWidth: '718px',
    borderRadius: theme.defaultRadius,
    border: `1px solid ${theme.colors.neutral[2]}`,
    paddingTop: 10,
    minWidth: 400,
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
      height: 'fit-content',
    },
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: '19px',
    color: '#4B4B4B',
    paddingLeft: 30,
    paddingRight: 25,
    fontFamily: 'PP Neue Montreal',
  },
  activeContainer: {
    backgroundColor: '#E9F9F2',
    height: '30px',
    width: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '100px',
  },
}));
