import CardDetails, { platformCardToDetailsModel } from '@common/card-details';
import { useStyles } from '@common/cards-styles';
import { CardsTableHeader } from '@common/composites/header/cards-table-header/cards-table-header';
import useModal from '@common/composites/modal/modal-hook';
import { IssueCreditCard } from '@common/issue-marqeta-card';
import { FlexbaseTable } from '@common/table';
import { MarqetaCardTableRow } from '@common/utilities/filter/cards-filter/card-table-row.model';
import {
  useMarqetaCardsFilters,
  useMarqetaCardsModalFilters,
} from '@common/utilities/filter/cards-filter/use-cards-filters';
import { Card } from '@flexbase-eng/sdk-typescript/models/components';
import { Avatar, Badge, Box, Text, useMantineTheme } from '@mantine/core';
import {
  useAccountCreditCards,
  usePersonCreditCards,
} from '@queries/use-credit-cards';
import { useMarqetaCardProgram } from '@queries/use-credit-programs';
import { CardExpensesTypes, CardStatus } from '@services/flexbase/card.model';
import { UseQueryResult } from '@tanstack/react-query';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import GetPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import { getInitialsOfNames } from '@utilities/object';
import { useMatchMedia } from '@utilities/url/window-helpers';
import { useCreditLineContext } from 'providers/credit-line-provider';
import { useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  IsAccountant,
  IsAdmin,
} from 'recoil-state/application/product-onboarding';

const cardStatusDisplay: Record<string, string> = {
  active: 'Active',
  suspended: 'Frozen',
  issued: 'Pending',
  requested: 'Pending',
  terminated: 'Canceled',
} satisfies Record<CardStatus, string>;

const useCardsData = ({
  accountId,
  personId,
}: {
  accountId: string;
  personId: string;
}): UseQueryResult<Card[], Error> => {
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const isElevated = isAdmin || isAccountant;
  const accountQuery = useAccountCreditCards(accountId, {
    enabled: isElevated,
  });
  const personQuery = usePersonCreditCards(accountId, personId, {
    enabled: !isElevated,
  });

  return isElevated ? accountQuery : personQuery;
};

export const MarqetaCreditCardsTable = () => {
  const theme = useMantineTheme();
  const {
    openCenterModal,
    openFullModal,
    openRightModalNoOpacity,
    closeModalById,
  } = useModal();
  const isMobile = useMatchMedia(`(max-width: ${theme.breakpoints.sm})`);
  const isAdmin = useRecoilValue(IsAdmin);
  const isAccountant = useRecoilValue(IsAccountant);
  const { accountId, personId } = useRecoilValue(ApplicationState);
  const { classes } = useStyles();
  const { data: cardsData, isPending: isPendingCards } = useCardsData({
    accountId,
    personId,
  });
  const filterHook = useMarqetaCardsFilters();
  const modalFilterHook = useMarqetaCardsModalFilters();
  const { line } = useCreditLineContext();
  const { data: cardProgram } = useMarqetaCardProgram();

  const tableData = useMemo<MarqetaCardTableRow[]>(() => {
    if (!cardsData) {
      return [];
    }

    return cardsData.map<MarqetaCardTableRow>((card) => {
      const expensesTypes = card.limits
        ? {
            amount: card.limits.amount ?? 0,
            interval: card.limits.interval || null,
            groups: (card.limits.groups || []) as CardExpensesTypes['groups'],
          }
        : undefined;

      const holder =
        card.cardHolder.displayName ||
        `${card.cardHolder.givenName} ${card.cardHolder.familyName}` ||
        '';

      const status: CardStatus = (() => {
        switch (card.state) {
          case 'active':
            return 'active';
          case 'inactive':
            return 'issued';
          case 'suspended':
            return 'suspended';
          case 'terminated':
            return 'terminated';
          default:
            return 'issued';
        }
      })();

      return {
        cardName: card.name ?? '',
        cardType: card.form === 'physical' ? 'PHYSICAL' : 'VIRTUAL',
        expensesTypes: expensesTypes,
        holder: holder,
        id: card.id,
        last4: card.last4,
        personId: card.personId,
        status: status,
      };
    });
  }, [cardsData]);

  const badgeColor: Record<string, string> = {
    issued: theme.colors.neutral[0],
    requested: theme.colors.neutral[0],
    active: '#EEEEF3',
    terminated: '#EEEEF3',
    suspended: 'rgba(48, 44, 255, 0.1)',
  };

  const columns: TableColumn<MarqetaCardTableRow>[] = [
    {
      name: 'Cardholder',
      cell: (row) => (
        <>
          {screen.width > 767 && (
            <Avatar radius="34px" className={classes.cardAvatar}>
              {getInitialsOfNames(row.holder)}
            </Avatar>
          )}
          <div className={classes.holderField}>{row.holder}</div>
          <Badge
            className={classes.badge}
            styles={{
              root: {
                border:
                  row.status === 'issued' || row.status === 'requested'
                    ? '1px solid #EEEEF3'
                    : 'unset',
                backgroundColor: badgeColor[row.status],
              },
              inner: { color: `${theme.colors.neutral[10]} !important` },
            }}
          >
            {cardStatusDisplay[row.status]}
          </Badge>
        </>
      ),
      selector: (row) => row.holder,
      sortable: true,
      width: '350px',
    },
    {
      name: 'Card Name',
      selector: (row) => row.cardName,
      sortable: true,
    },
    {
      name: 'Credit Card',
      cell: (row) => (
        <Text fz="sm">
          {row.status !== 'issued' && row.status !== 'requested' && row.last4
            ? GetPaddedAccountMask(row.last4, 4)
            : ''}
        </Text>
      ),
      selector: (row) => row.last4,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => capitalizeOnlyFirstLetter(row.cardType),
      sortable: true,
    },
  ];

  const handleIssueCard = () => {
    if (!cardProgram || !line) {
      return;
    }

    const id = openFullModal(
      <IssueCreditCard
        lineOfCreditId={line.id}
        programId={cardProgram.id}
        onClose={() => closeModalById(id)}
      />,
    );
  };

  const handleRowClicked = (row: MarqetaCardTableRow) => {
    const isOwnCard = row.personId === personId;
    const card = cardsData?.find((c) => c.id === row.id);

    // accountants can only view details for their own cards
    if (isAccountant && !isOwnCard) {
      return;
    } else if (!card) {
      return;
    }

    const model = platformCardToDetailsModel(card);
    const content = <CardDetails card={model} creditLine={line} />;

    isMobile ? openCenterModal(content) : openRightModalNoOpacity(content);
  };

  return (
    <Box
      sx={{
        maxWidth: '1307.5px',
        margin: 'auto',
      }}
    >
      <div className={classes.cardsContainer} data-testid="cards-table">
        <CardsTableHeader
          filters={[]}
          filterHook={filterHook}
          userCanIssueCards={isAdmin}
          onIssueCard={handleIssueCard}
          issueCardLabel="New credit card"
          modalFilterHook={modalFilterHook}
        />
        <FlexbaseTable
          data={tableData}
          columns={columns}
          isFetchingData={isPendingCards}
          onRowClicked={handleRowClicked}
        />
      </div>
    </Box>
  );
};
