import { useSplitClient } from '@splitsoftware/splitio-react';
import { useTreatment } from 'providers/split.provider';

export const useMultiAccountFeatureFlag = () => {
  const featureFlag = useTreatment('multi_account');
  return featureFlag.treatment === 'on';
};

export const useSpendPlansFeatureFlag = () => {
  const featureFlag = useTreatment('spend_plans');
  return featureFlag.treatment === 'on';
};

export const useBillPayCreditFlag = () => {
  const featureFlag = useTreatment('bill_pay_credit');
  return featureFlag.treatment === 'on';
};

export const useBillPayCreditV2Flag = () => {
  const featureFlag = useTreatment('bill_pay_credit_v2');
  return featureFlag.treatment === 'on';
};

export const useEliteFeatureFlag = () => {
  const featureFlag = useTreatment('elite');
  return featureFlag.treatment === 'on';
};

/**
 * If this is true, we are currently undergoing active "maintenance". False is normal operation
 */
export const useMaintenanceFlag = () => {
  const featureFlag = useTreatment('maintenance_flag');
  return featureFlag.treatment === 'on';
};

export const useAdsEcommerceIntegrations = () => {
  const featureFlag = useTreatment('integrations_ads_ecommerce');
  return featureFlag.treatment === 'on';
};

export const useCheatSheetFeatureFlag = () => {
  const featureFlag = useTreatment('cheat_sheet');
  return featureFlag.treatment === 'on';
};

export const useApplicationV2FeatureFlag = () => {
  const splitClient = useSplitClient({ updateOnSdkTimedout: true });
  const featureFlag = useTreatment('application_v2');
  return {
    treatmentOn: featureFlag.treatment === 'on',
    treatmentReady: splitClient.isReady || splitClient.isTimedout,
  };
};

export const useMarqetaUiFeatureFlag = () => {
  const featureFlag = useTreatment('marqeta_ui');
  return featureFlag.treatment === 'on';
};

export const useAutoPayChargeCardFeatureFlag = () => {
  const featureFlag = useTreatment('plaid_auto_pay_charge_account');
  return featureFlag.treatment === 'on';
};
