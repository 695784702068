import { useEffect, useState } from 'react';
import { TermsOfServiceCheckboxPrompt } from '@common/utilities/terms-prompt';
import { useTermsFormContext } from './terms-form-context';
import { Anchor, Checkbox, Text } from '@mantine/core';
import { flexbaseOnboardingClient } from '../../../../services/flexbase-client';
import { PersonalGuarantyResponse } from '@services/flexbase/agreements.model';
import { useModals } from '@mantine/modals';
import { UseHasTermsReturnType } from '../../../../utilities/custom-hooks/use-has-terms';
import { useGetTermsAgreement } from '@queries/use-generated-terms';

type Props = {
  showPreview: boolean;
  showTextAgreements: boolean;
  termsConfig: Omit<
    UseHasTermsReturnType,
    | 'hasActiveBanking'
    | 'hasActiveTreasury'
    | 'hasActiveCredit'
    | 'hasTermsForActiveProduct'
    | 'hasActiveIntnlPayments'
  >;
};

/**
 * An encapsulated "widget" for doing all things terms on the summary screen. Hopefully
 * this doesn't come back to bite us in the ass.
 * @constructor
 */
export const Termsinator = ({
  showPreview,
  showTextAgreements,
  termsConfig,
}: Props) => {
  const formContext = useTermsFormContext();
  const modals = useModals();
  const {
    hasBankingTerms,
    hasFlexbaseTerms,
    hasInternationalPaymentsTerms,
    hasTreasuryTerms,
    hasCreditTerms,
    hasPersonalGuaranty,
    hasPatriotAct,
    hasFicoPull,
    hasFlTermsAgreement,
    hasNyTermsAgreement,
  } = termsConfig;
  const [personalG, setPersonalG] = useState<PersonalGuarantyResponse>();
  const [savingTermsAgreement, setSavingTermsAgreement] = useState(false);
  const { data: flAgreement } = useGetTermsAgreement('fl-credit');
  const { data: nyAgreement } = useGetTermsAgreement('ny-credit');

  const getPersonalGuaranty = async () => {
    try {
      const response = await flexbaseOnboardingClient.getPersonalGuaranty();
      setPersonalG(response);
      if (response.status === 'accepted') {
        formContext.setFieldValue('personalGuarantySigned', true);
      }
    } catch (e) {
      console.error('squashed-error', e);
    }
  };

  useEffect(() => {
    getPersonalGuaranty();
  }, []);

  const onCreditTermsChanged = async (value: boolean) => {
    if (value) {
      setSavingTermsAgreement(true);
      try {
        await flexbaseOnboardingClient.updateUser({
          creditTermsOfServiceSigned: true,
        });
        await getPersonalGuaranty();
      } catch (e) {
        console.error('terminsator.tsx', e);
      } finally {
        setSavingTermsAgreement(false);
      }
    }

    formContext.setFieldValue('creditTermsOfServiceSigned', value);
  };

  const personalGReady =
    personalG?.status === 'pending' && !!personalG?.personalGuaranty?.contents;
  const disablePG = !personalGReady;

  const openWindowWithContent = (htmlContent: string) => {
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const newWindow = window.open(url, '_blank');

    // Clean up the URL object once the window is loaded
    if (newWindow) {
      newWindow.onload = () => {
        URL.revokeObjectURL(url);
      };
    }
  };

  const openViewTermsModal = (contents: string) => {
    modals.openConfirmModal({
      styles: {
        body: { paddingRight: '2rem' },
      },
      confirmProps: {
        style: { display: 'none' },
      },
      labels: { confirm: 'Close', cancel: 'Close' },
      children: (
        <div
          style={{
            width: '1000px',
          }}
          dangerouslySetInnerHTML={{
            __html: contents,
          }}
        ></div>
      ),
    });
  };

  return (
    <>
      {hasFlexbaseTerms && (
        <TermsOfServiceCheckboxPrompt
          type="flexbase"
          size="sm"
          {...formContext.getInputProps('termsOfServiceSigned', {
            type: 'checkbox',
          })}
        />
      )}
      {hasBankingTerms && (
        <TermsOfServiceCheckboxPrompt
          type="banking"
          size="sm"
          {...formContext.getInputProps('bankingTermsOfServiceSigned', {
            type: 'checkbox',
          })}
        />
      )}
      {hasInternationalPaymentsTerms && (
        <TermsOfServiceCheckboxPrompt
          type="internationalPayments"
          size="sm"
          {...formContext.getInputProps(
            'internationalPaymentsTermsOfServiceSigned',
            {
              type: 'checkbox',
            },
          )}
        />
      )}
      {hasTreasuryTerms && (
        <TermsOfServiceCheckboxPrompt
          type="treasury"
          size="sm"
          {...formContext.getInputProps('treasuryTermsOfServiceSigned', {
            type: 'checkbox',
          })}
        />
      )}
      {hasCreditTerms && (
        <TermsOfServiceCheckboxPrompt
          type="credit"
          size="sm"
          {...formContext.getInputProps('creditTermsOfServiceSigned', {
            type: 'checkbox',
          })}
          onChange={(e) => onCreditTermsChanged(e.target.checked)}
          disabled={savingTermsAgreement}
          showPreview={showPreview}
        />
      )}
      {hasPersonalGuaranty && (
        <Checkbox
          disabled={disablePG}
          label={
            <Text fz={12}>
              I have read and accepted the{' '}
              <Anchor
                fz={12}
                sx={(theme) => ({
                  color: theme.colors.blue[7],
                  ...(disablePG && {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                    pointerEvents: 'none',
                  }),
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (personalGReady) {
                    openViewTermsModal(personalG.personalGuaranty.contents);
                  }
                }}
              >
                Personal Guaranty
              </Anchor>
            </Text>
          }
          {...formContext.getInputProps('personalGuarantySigned')}
          id="checkbox-agree-personal-guaranty"
        />
      )}
      {hasNyTermsAgreement && (
        <Checkbox
          label={
            <Text fz={12}>
              I confirm I have viewed and accepted the{' '}
              <Anchor
                underline="always"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (nyAgreement?.status === 'pending') {
                    openWindowWithContent(nyAgreement.agreement.contents);
                  }
                }}
              >
                NY disclosure
              </Anchor>
            </Text>
          }
          {...formContext.getInputProps('nyTermsSigned')}
          id="checkbox-agree-ny-terms"
        />
      )}
      {hasFlTermsAgreement && (
        <Checkbox
          label={
            <Text fz={12}>
              I confirm that I have viewed and accept the{' '}
              <Anchor
                underline="always"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  if (flAgreement?.status === 'pending') {
                    openWindowWithContent(flAgreement.agreement.contents);
                  }
                }}
              >
                FL disclosure
              </Anchor>
            </Text>
          }
          {...formContext.getInputProps('flTermsSigned')}
          id="checkbox-agree-fl-terms"
        />
      )}
      {showTextAgreements && (
        <>
          {hasFicoPull && (
            <TermsOfServiceCheckboxPrompt
              type="ficoPull"
              size="sm"
              {...formContext.getInputProps('ficoPullSigned', {
                type: 'checkbox',
              })}
              styles={{ input: { display: 'none' } }}
            />
          )}
          {hasPatriotAct && (
            <TermsOfServiceCheckboxPrompt
              type="patriotAct"
              size="sm"
              {...formContext.getInputProps('patriotActSigned', {
                type: 'checkbox',
              })}
              styles={{ input: { display: 'none' } }}
            />
          )}
        </>
      )}
    </>
  );
};
