import DetailPageLayout from '@common/layouts/detail-page/detail-page-layout';
import SummaryDetailLayout from '@common/layouts/detail-page/summary-detail-layout';
import { NavTabContainer } from '@common/composites/tabs/nav-tab-container';
import { useGetRecipient } from '@queries/use-recipients';
import type { GetMoneyMovementsParams } from '@services/flexbase/banking.model';
import { formatCurrency } from '@utilities/formatters';
import { useFilteredDates } from '@utilities/custom-hooks/use-filtered-dates';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import AccountsDropdown from './accounts-dropdown';
import PaymentsSummary from './payments-summary/payments-summary';
import RecipientDetailsTitleBar from './recipient-details-title-bar';
import { parseAccountsInfo } from '@utilities/payments-rows';
import { Recipient } from 'types/recipient';
import { useGetBankingPayments } from '@queries/use-payments';
import { useGetUsers } from '@queries/use-users';
import PaymentsSummaryDetails from './payments-summary/payments-summary-details';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import SkeletonLoading from '@common/composites/loading/skeleton-loading';
import { Box, rem } from '@mantine/core';

const basePath = 'recipient-details';

const getTabs = (id: string, hasActiveIntnlPayments: boolean) => {
  const tabs = [
    {
      label: 'Payments',
      route: `/${basePath}/${id}/payments`,
    },
    {
      label: 'Bills',
      route: `/${basePath}/${id}/bills`,
    },
    {
      label: 'Transactions',
      route: `/${basePath}/${id}/transactions`,
    },
    {
      label: 'Account information',
      route: `/${basePath}/${id}/account-information`,
    },
  ];
  if (hasActiveIntnlPayments) {
    tabs.push({
      label: `Int'l accounts`,
      route: `/${basePath}/${id}/international-accounts`,
    });
  }

  return tabs;
};

const useRecipientPayments = (id?: string) => {
  const [counterpartyId, setCounterpartyId] = useState('');
  const { before, after, option } = useFilteredDates();
  const params: GetMoneyMovementsParams = !option
    ? { recipientId: id }
    : { recipientId: id, fromDate: after, toDate: before };

  if (counterpartyId.length > 0) {
    params.counterpartyId = counterpartyId;
  }

  const { data: payments, isError, isLoading } = useGetBankingPayments(params);

  const {
    data: users,
    isError: isUsersError,
    isLoading: isUsersLoading,
  } = useGetUsers();

  const error = isError || isUsersError;
  const loading = isLoading || isUsersLoading;

  const handleAccountChange = (providedId: string) => {
    setCounterpartyId(providedId === 'all' ? '' : providedId);
  };

  return {
    payments,
    users,
    loading,
    error,
    params,
    handleAccountChange,
  };
};

const RecipientDetails = () => {
  const { recipientId = '' } = useParams();
  const { state } = useLocation();
  const [redirectRoute, setRedirectRoute] = useState(null);
  const { payments, loading, error, users, params, handleAccountChange } =
    useRecipientPayments(recipientId);
  const { data: recipientData } = useGetRecipient(recipientId);
  const { productStatus } = useRecoilValue(ApplicationState);

  const hasActiveIntnlPayments =
    productStatus.internationalPayments.status === 'approved';

  const recipient: Recipient | undefined = recipientData
    ? recipientData.recipient
    : undefined;

  useEffect(() => {
    setRedirectRoute(state);
  }, []);

  if (!recipient) {
    return (
      <DetailPageLayout
        linkTitle="Recipients"
        linkPath={redirectRoute ?? '/payments/recipients'}
      >
        <Box mt={rem(20)}>
          <SkeletonLoading />
          <Box mt={rem(25)}>
            <SkeletonLoading withTabs />
          </Box>
        </Box>
      </DetailPageLayout>
    );
  }

  const accountsInfo = parseAccountsInfo([
    ...(recipient.ach ?? []),
    ...(recipient.wire ?? []),
    ...(recipient.book ?? []),
    ...(recipient.internationalWire ?? []),
    ...(recipient.internationalWireUsd ?? []),
  ]);

  const domesticAccounts = accountsInfo.filter((acc) =>
    ['ach', 'wire', 'book'].includes(acc.type),
  );
  const internationalAccounts = accountsInfo.filter((acc) =>
    ['internationalWire', 'internationalWireUSD'].includes(acc.type),
  );

  const tabs = getTabs(recipientId ?? '', hasActiveIntnlPayments);

  const paymentsSum =
    payments?.reduce((acc, curr) => acc + Number(curr.payAmountCents), 0) || 0;

  const mappedPayments =
    payments?.map((payment) => {
      const sender = users?.find((user) => user.id === payment.userId);
      return {
        ...payment,
        sender: sender ? `${sender.firstName} ${sender.lastName}` : '',
      };
    }) ?? [];

  return (
    <DetailPageLayout
      linkTitle="Recipients"
      linkPath={redirectRoute ?? '/payments/recipients'}
    >
      <RecipientDetailsTitleBar
        isRemovedRecipient={recipient.status === 'inactive'}
        title={recipient.name}
        recipientId={recipient.id}
        recipient={recipient}
      />
      <PaymentsSummary
        fromDate={params.fromDate?.toString()}
        toDate={params.toDate?.toString()}
        modalContent={
          <PaymentsSummaryDetails
            users={users ?? []}
            recipientId={recipient.id}
            accountsInfo={accountsInfo}
            recipientName={recipient.name}
            incommingCounterpartyId={params.counterpartyId}
          />
        }
        payments={mappedPayments}
        detailsComponent={
          <SummaryDetailLayout
            headline={
              !loading && !error ? formatCurrency(paymentsSum / 100) : ''
            }
            subtitle="Payments total"
          >
            <AccountsDropdown
              accounts={accountsInfo}
              onChange={handleAccountChange}
            />
          </SummaryDetailLayout>
        }
      />
      <NavTabContainer tabs={tabs}>
        <Outlet
          context={{ domesticAccounts, internationalAccounts, recipient }}
        />
      </NavTabContainer>
    </DetailPageLayout>
  );
};

export default RecipientDetails;
