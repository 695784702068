import { Alert, Box, Button, Group, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { capitalizeOnlyFirstLetter } from '@utilities/formatters';
import { RequiredFieldValidator } from '@utilities/validators';
import { useState } from 'react';
import { detailsCardStyles } from './styles';
import {
  CardDetailsModel,
  creditCardToDetailsModel,
  platformCardToDetailsModel,
} from '@common/card-details';
import {
  useActivatePersonCard,
  useUpdateCreditCardStatusMutation,
} from '@queries/use-credit-cards';
import { Line } from '@flexbase-eng/sdk-typescript/models/components';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

export type CardStatusUpdateValue = 'active' | 'suspended' | 'terminated';

type Props = {
  card: CardDetailsModel;
  creditLine?: Line;
  newStatus: CardStatusUpdateValue;
  prettyStatusLabel: string;
  onCardUpdated: (card: CardDetailsModel) => void;
  onCancel: () => void;
  connectionId: string;
};

export const CardConfirmStatusUpdate = ({
  card,
  creditLine,
  newStatus,
  prettyStatusLabel,
  onCardUpdated,
  onCancel,
}: Props) => {
  const { accountId } = useRecoilValue(ApplicationState);
  const { classes } = detailsCardStyles();
  const form = useForm({
    initialValues: { last4: '' },
    validate: { last4: RequiredFieldValidator() },
  });

  const { mutate: updateStatusApi, isPending: isPendingUpdateStatusApi } =
    useUpdateCreditCardStatusMutation();
  const { mutate: activateCard, isPending: isPendingActivateCard } =
    useActivatePersonCard();

  const [error, setError] = useState('');

  const isPendingSave = isPendingUpdateStatusApi || isPendingActivateCard;

  const updateCardStatus = async () => {
    setError('');
    const validationResult = form.validate();
    if (!validationResult.hasErrors) {
      if (creditLine?.issuer === 'marqeta') {
        handleSavePlatform();
      } else {
        handleSaveApi();
      }
    }

    function handleSavePlatform() {
      switch (newStatus) {
        case 'active':
          activateCard(
            {
              accountId,
              personId: card.personId,
              cardId: card.id,
            },
            {
              onSuccess: (updatedCard) => {
                onCardUpdated(platformCardToDetailsModel(updatedCard));
              },
            },
          );
          break;
      }
    }

    function handleSaveApi() {
      updateStatusApi(
        { cardId: card.id, status: newStatus, last4: form.values.last4 },
        {
          onSuccess: (response) => {
            onCardUpdated(creditCardToDetailsModel(response));
          },
          onError: () => {
            setError(
              `An error occurred while trying to ${prettyStatusLabel} card.`,
            );
          },
        },
      );
    }
  };

  return (
    <Box>
      <TextInput
        maxLength={4}
        placeholder="0000"
        variant="unstyled"
        c="primary.8"
        classNames={{ input: classes.last4Input }}
        {...form.getInputProps('last4')}
      />
      <Text mt="md">Confirm last 4 digits of card to {prettyStatusLabel}</Text>
      {error && (
        <Alert mt="md" onClose={() => setError('')}>
          {error}
        </Alert>
      )}
      <Group sx={{ justifyContent: 'space-between' }} mt="md">
        <Button
          variant="neutral-outline"
          onClick={onCancel}
          disabled={isPendingSave}
          className={classes.whiteOutlineButton}
        >
          Back
        </Button>
        <Button onClick={updateCardStatus} loading={isPendingSave}>
          {capitalizeOnlyFirstLetter(prettyStatusLabel)}
        </Button>
      </Group>
    </Box>
  );
};
