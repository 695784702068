import { useStyles } from '@common/charge-and-credit-cards/credit-transactions.styles';
import { Avatar, Text, useMantineTheme } from '@mantine/core';
import useModal from '@common/composites/modal/modal-hook';
import TransactionDetails, {
  FocusElement,
} from './details/transaction-details';
import { TableColumn } from 'react-data-table-component';
import { getLocaleDate } from '@utilities/formatters/format-date-string';
import getPaddedAccountMask from '@utilities/formatters/get-padded-account-mask';
import ExpenseManagement, {
  ActionStatus,
} from '@common/utilities/expense-management/expense-management';
import { formatCurrency } from '@utilities/formatters';
import {
  mapTransactionStatusToBusinessStatus,
  Transactions,
} from '@services/flexbase/flexbase-onboarding-client';

import { CreditTransactionsTableRow } from './credit-transactions-table-helpers';
import { OverflowTooltip } from '@common/utilities/overflow-tooltip';
import FlexbaseSelect from '@common/composites/flexbase-select';
import { showNotification } from '@mantine/notifications';
import FeeDetails from './details/fee-details';
import PaymentDetails from './details/payment-details';
import InterestDetails from './details/interest-details';
import {
  Category,
  ExpenseLink,
  Expenses,
  ExpensesStatusEnum,
  SyncedExpenses,
} from '@flexbase-eng/types/dist/accounting';
import { useSpendPlansFeatureFlag } from '@utilities/feature-flags';
import { PlatformPersonProvider } from 'providers/platform-person.context';
import { SyncToCategoryChange } from '@common/charge-and-credit-cards/credit-transactions.reducer';
import { LineOfCredit } from '@services/flexbase/credit.model';
import { formatCreditTransactionStatus } from '@utilities/formatters/credit-transactions-utilities';

type Props = {
  syncFeatureEnabled?: boolean;
  onTransactionUpdate: () => void;
  onTransactionSync: (txId: string) => void;
  onTransactionAccountChange: (txId: string, accountId: string | null) => void;
  syncedTransactions: SyncedExpenses | undefined;
  expenseLink?: ExpenseLink;
  lineOfCredit: LineOfCredit;
};

// Don't @ me I just moved this out of credit-transactions
export const useCreditTransactionsTableColumns = ({
  syncFeatureEnabled,
  onTransactionUpdate,
  onTransactionSync,
  onTransactionAccountChange,
  syncedTransactions,
  expenseLink,
  lineOfCredit,
}: Props) => {
  const { classes } = useStyles();
  const theme = useMantineTheme();
  const openTransactionDetails =
    useOpenCreditTransactionDetails(onTransactionUpdate);

  const connectionId = expenseLink?.connectionId;
  const expenseColumnLabel = 'Category';
  const expenseCategories = syncedTransactions?.accounts?.items ?? [];

  const syncedDataMap =
    syncedTransactions?.expenses.reduce<Record<string, Expenses | undefined>>(
      (acc, expense) => {
        acc[expense.transactionId] = expense;
        return acc;
      },
      {},
    ) ?? {};

  const spendPlansEnabled = useSpendPlansFeatureFlag();

  const getSyncActionStatus = (row: CreditTransactionsTableRow) => {
    const syncData = syncedDataMap[row.id];

    // no connectionId means there's no integration to sync to
    // no syncData means this row is unsyncable
    if (!connectionId || !syncData) {
      return ActionStatus.disabled;
    }

    // frontend is syncing
    if (row.selectedCategory?.status === 'loading') {
      return ActionStatus.loading;
    }

    const syncedAccountId = syncData.expense?.account?.id;
    const selectedAccountId = row.selectedCategory?.id;

    // user has selected a new category that has not yet synced
    if (selectedAccountId !== syncedAccountId) {
      return ActionStatus.incomplete;
    }

    // check the backend sync state
    if (row.syncedExpense) {
      // queued means things are processing on the backend, waiting for successful status
      if (row.syncedExpense.status === ExpensesStatusEnum.Queued) {
        return ActionStatus.inProgress;
      }

      if (row.syncedExpense.status === ExpensesStatusEnum.Failure) {
        return ActionStatus.failure;
      }
    }

    // no syncedToAccount means user hasn't made any changes
    if (!selectedAccountId) {
      return ActionStatus.disabled;
    }

    return syncData.status === ExpensesStatusEnum.NotSynced
      ? null
      : ActionStatus.complete;
  };

  const getSyncActionTooltip = (row: CreditTransactionsTableRow) => {
    if (!row.syncedExpense) {
      return;
    }

    switch (row.syncedExpense.status) {
      case ExpensesStatusEnum.Failure:
        return `Transaction sync failed. ${row.syncedExpense.description} Please reach out to customer support for assistance.`;
      default:
        return;
    }
  };

  const columns: TableColumn<CreditTransactionsTableRow>[] = [
    {
      name: 'Date',
      format: (row) => getLocaleDate(row.date, true),
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row) => row.type,
      sortable: true,
      compact: true,
    },
    {
      name: 'Card',
      selector: (row) =>
        (row.type === 'Card Purchase' || row.type === 'BNPL Purchase') &&
        row.sourceLast4.length
          ? getPaddedAccountMask(row.sourceLast4, 4)
          : '',
      sortable: true,
      compact: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      compact: true,
    },
    {
      name: 'To/From',
      cell: (row) => (
        <div
          className={classes.storeName}
          onClick={() => openTransactionDetails(row)}
        >
          {row.logoUrl ? (
            <img src={row.logoUrl} className={classes.storeLogo} alt={'logo'} />
          ) : (
            <Avatar radius="34px" className={classes.storeIcon} size="32px">
              {row.toFrom?.charAt(0) || '$'}
            </Avatar>
          )}
          <OverflowTooltip text={row.toFrom || 'Payment received'} />
        </div>
      ),
      selector: (row) => row.toFrom,
      sortable: true,
      compact: true,
      wrap: false,
      grow: 3,
      minWidth: '160px',
    },
    {
      name: 'Management',
      cell: (row) =>
        row.type === 'Payment' ||
        row.type === 'Interest' ||
        row.type === 'Fee' ? (
          false
        ) : (
          <ExpenseManagement
            type="credit-transactions"
            receiptAction={() => openTransactionDetails(row, 'receipt')}
            memoAction={() => openTransactionDetails(row, 'memo')}
            receiptActionStatus={row.docId ? ActionStatus.complete : null}
            memoActionStatus={row.description ? ActionStatus.complete : null}
            syncActionStatus={getSyncActionStatus(row)}
            syncActionTooltip={getSyncActionTooltip(row)}
            syncAction={() => {
              if (
                !connectionId ||
                !syncFeatureEnabled ||
                !row.selectedCategory?.id
              ) {
                return;
              }

              onTransactionSync(row.id);
            }}
          />
        ),
      center: false,
      compact: true,
      width: '128px',
      omit: lineOfCredit === 'unit',
    },
    {
      name: 'Spend plan',
      selector: (row) => row.spendName ?? '',
      sortable: true,
      compact: true,
      omit: !spendPlansEnabled || lineOfCredit === 'unit',
    },
  ];

  if (syncFeatureEnabled && connectionId && expenseCategories.length) {
    const categoryLabel = expenseColumnLabel || 'Accounts';
    const data = expenseCategories.map((i) => ({
      value: i.id,
      label: i.displayName ?? i.name,
    }));

    columns.push({
      name: categoryLabel,
      width: '232px',
      cell: (row) => {
        if (!row.syncedExpense) {
          return null;
        }

        const hasSynced =
          row.syncedExpense.status !== ExpensesStatusEnum.NotSynced;
        const isReadOnly = !expenseLink.modifiable && hasSynced;

        return isReadOnly ? (
          <Text
            onClick={() => {
              showNotification({
                title: `Info`,
                color: 'neutral.2',
                message: `Please visit your integration provider to make changes to the ${categoryLabel}.`,
              });
            }}
          >
            {data.find(
              (account) =>
                row.selectedCategory &&
                account.value === row.selectedCategory.id,
            )?.label || 'Unknown value'}
          </Text>
        ) : (
          <FlexbaseSelect
            data={data}
            searchable
            inputProps={{
              disabled:
                row.syncedExpense.lock ||
                row.selectedCategory?.status === 'loading',
              styles: {
                input: {
                  border: 'none',
                  backgroundColor: 'inherit',
                  color: theme.colors.primary[8],
                  '&[data-disabled]': {
                    backgroundColor: 'inherit',
                  },
                  textOverflow: 'ellipsis',
                },
              },
            }}
            placeholder={`Choose ${categoryLabel}`}
            value={
              row.selectedCategory?.id || row.syncedExpense.expense?.account?.id
            }
            onChange={(value) => onTransactionAccountChange(row.id, value)}
          />
        );
      },
    });
  }

  columns.push(
    {
      name: 'Amount',
      cell: (row) => {
        const amountAsNum = Number(row.amount);
        return (
          <Text
            style={{
              color:
                // Reversed transactions do not affect the credit balance sheet, but settled credits (negative amounts) do
                amountAsNum < 0
                  ? theme.colors.primary[2]
                  : row.status === 'Declined'
                    ? '#ff0000'
                    : '#5F5F5F',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '21px',
            }}
          >
            {amountAsNum < 0 && '+ '}
            {formatCurrency(Math.abs(amountAsNum)) || 'N/A'}
          </Text>
        );
      },
      selector: (row) => Number(row.amount),
      sortable: true,
      compact: true,
    },
    {
      name: 'Status',
      cell: (row) => {
        const status = formatCreditTransactionStatus(row);

        return status ? (
          <Text className={classes.status}>{status}</Text>
        ) : (
          false
        );
      },
      compact: true,
    },
  );

  return columns;
};

export const useOpenCreditTransactionDetails = (
  onTransactionUpdate: () => void,
) => {
  const modal = useModal();
  const theme = useMantineTheme();

  return (row: CreditTransactionsTableRow, focusElement?: FocusElement) => {
    let view;
    let modalId = '';

    const handleCloseModal = () => {
      modal.closeModalById(modalId);
    };

    switch (row.type) {
      case 'Interest':
        view = (
          <InterestDetails
            createdAt={row.date}
            total={row.amount}
            type={row.type}
            status={mapTransactionStatusToBusinessStatus(row)}
            closeModal={handleCloseModal}
          />
        );
        break;
      case 'Fee':
        view = (
          <FeeDetails
            feeName={row.toFrom}
            createdAt={row.date}
            total={row.amount}
            type={row.type}
            status={mapTransactionStatusToBusinessStatus(row)}
            closeModal={handleCloseModal}
          />
        );
        break;
      case 'Payment':
        view = (
          <PaymentDetails
            createdAt={row.date}
            sourceType={row.sourceType || row.toFrom}
            total={row.amount}
            employee={row.name}
            type={row.type}
            last4={row.sourceLast4}
            paymentType={row.paymentType || ''}
            sourceAccountName={row.sourceAccountName}
            status={mapTransactionStatusToBusinessStatus(row)}
            closeModal={handleCloseModal}
            paymentId={row.id}
            isChargePay={row.lineOfCredit === 'unit'}
          />
        );
        break;
      default:
        view = (
          <PlatformPersonProvider>
            <TransactionDetails
              tableRow={row}
              lineOfCredit={row.lineOfCredit}
              focusElement={focusElement}
              closeModal={handleCloseModal}
              onTransactionUpdate={onTransactionUpdate}
            />
          </PlatformPersonProvider>
        );
        break;
    }

    modalId = modal.openRightModal(view, theme.colors.neutral[3]);
  };
};

export const mapCreditTransactionToTableRow = (
  transaction: Transactions,
  index: number,
  {
    syncedExpense,
    expenseCategories,
  }: {
    syncedExpense?: Expenses;
    expenseCategories?: Category[];
  } = {},
  lineOfCredit: LineOfCredit,
  txAccountChange?: SyncToCategoryChange,
) => {
  let transactionType = '';

  const credebit = parseInt(transaction.amount) < 0 ? 'debit' : 'credit';

  switch (transaction.type) {
    case 'bnpl':
      transactionType = 'BNPL Purchase';
      break;
    case 'card':
    case 'charge':
      transactionType = 'Card Purchase';
      break;
    case 'manual':
      transactionType = 'Payment';
      break;
    case 'autopay':
      transactionType = 'Payment';
      break;
    case 'interest':
      transactionType = 'Interest';
      break;
    case 'badPayment':
      transactionType = 'Fee';
      break;
    default: // This should probably never happen
      transactionType = 'Payment';
      break;
  }

  const syncedCategoryId =
    txAccountChange?.data?.accountId || syncedExpense?.expense?.account?.id;
  const syncedCategoryName = expenseCategories?.find(
    (c) => c.id === syncedCategoryId,
  )?.name;
  const selectedCategory = syncedCategoryId
    ? {
        id: syncedCategoryId,
        name: syncedCategoryName,
        status: txAccountChange?.status,
      }
    : undefined;

  const row: CreditTransactionsTableRow = {
    id: transaction.id,
    date: transaction.date,
    purchaseDate: transaction.purchaseDate,
    settledAt: transaction.settledAt,
    type: transactionType,
    paymentType: transaction.type === 'manual' ? 'Manual payment' : 'Auto pay',
    name: transaction.who ?? 'Manual repayment',
    toFrom: transaction.transaction,
    amount: transaction.amount,
    status: transaction.status,
    description: transaction.description,
    docId: transaction.docId,
    logoUrl: transaction.logoUrl,
    cardName: transaction.cardName,
    storeId: transaction.storeId,
    storeCategory: transaction.storeCategory,
    storeCity: transaction.storeCity,
    storePostalCode: transaction.storePostalCode,
    storeState: transaction.storeState,
    keyField: transaction.id + index,
    authInfo: transaction.authInfo,
    creditOrDebit: credebit,
    // Differs based on if it's a card transaction or payment
    sourceLast4: transaction.sourceLast4 || transaction.last4 || '',
    sourceType: transaction.sourceType || '',
    syncedExpense: syncedExpense,
    selectedCategory: selectedCategory,
    spendName: transaction.spendName,
    lineOfCredit,
  };

  return row;
};
