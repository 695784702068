import { Box, Button, Group, rem, Stack } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useIsMobile } from '@utilities/custom-hooks/use-is-mobile';
import OnboardingHeader from 'areas/onboarding-v2/components/onboarding-header';
import { CreditBankingFooter } from 'areas/onboarding-v2/credit-banking.footer';
import { ReactNode } from 'react';
import { PiArrowLeftBold, PiArrowRightBold } from 'react-icons/pi';

type Props = {
  titleText: string;
  subtitleText?: string;
  estimatedTime?: string;
  progress: number;
  handleContinueClick?: () => void;
  continueLoading?: boolean;
  handleBackClick?: () => void;
  children?: ReactNode;
};

const QualificationPageShell = ({
  titleText,
  subtitleText,
  estimatedTime,
  progress,
  handleContinueClick,
  continueLoading,
  handleBackClick,
  children,
}: Props) => {
  const { theme, classes } = useStyles();
  const isMobile = useIsMobile();
  return (
    <>
      <Stack bg={theme.colors.neutral[3]} mih="100vh" pos="relative">
        <OnboardingHeader
          titleText={titleText}
          subtitleText={subtitleText}
          progress={progress}
          estimatedTime={estimatedTime}
        />
        <Stack
          my="xl"
          flex={{ base: 1, sm: 'unset' }}
          w={{ base: '100%', sm: rem(525) }}
          mx="auto"
        >
          {children}
          {isMobile && (
            <CreditBankingFooter mt={rem(128)} className={classes.footer} />
          )}
        </Stack>
        <Group
          bg={theme.colors.neutral[3]}
          bd={{ base: `1px solid ${theme.colors.neutral[4]}`, sm: 'none' }}
          px={{ base: 'md', sm: 0 }}
          h={rem(72)}
          align="center"
          justify="space-between"
          pos={{ base: 'sticky', sm: 'unset' }}
          bottom={0}
          w={{ base: '100%', sm: rem(525) }}
          mx="auto"
        >
          {handleBackClick ? (
            <Button
              size="md"
              onClick={handleBackClick}
              leftSection={
                <PiArrowLeftBold
                  color={theme.colors.neutral[6]}
                  width={16}
                  height={16}
                />
              }
              fz="md"
              variant="neutral-outline"
            >
              Back
            </Button>
          ) : (
            <Box />
          )}
          <Button
            bg={theme.colors.primary[8]}
            size="md"
            onClick={handleContinueClick}
            rightSection={<PiArrowRightBold width={16} height={16} />}
            fz="md"
            disabled={!handleContinueClick}
            loading={continueLoading}
          >
            Continue
          </Button>
        </Group>
      </Stack>
      {!isMobile && (
        <CreditBankingFooter
          bg={theme.colors.neutral[3]}
          className={classes.footer}
        />
      )}
    </>
  );
};

const useStyles = createStyles((theme) => ({
  footer: {
    p: {
      color: theme.colors.neutral[7],
      lineHeight: 'normal',
      letterSpacing: '0.05px',
    },
    a: {
      color: `${theme.colors.primary[6]} !important`,
      lineHeight: 'normal',
      letterSpacing: '0.05px',
    },
  },
}));

export default QualificationPageShell;
