import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useEffect, useState } from 'react';
import { Box, rem, Text } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import {
  flexbaseBankingClient,
  flexbaseOnboardingClient,
} from 'services/flexbase-client';
import Frame from 'assets/images/flex-background-text.png';
import Yolo from 'assets/images/img.png';
import OnboardingSubmittingScreen from '../components/onboarding-submitting-screen';
import {
  BankApplicationStatusIsApplicationResponse,
  BankApplicationStatusIsAwaitingDocuments,
  BankingApplicationStatus,
} from '@services/flexbase/banking.model';
import AuthLoader from '@common/auth-loader';
import { useApplicationFlowContext } from '../onboarding-hooks';
import { useSardineCustomer } from '@queries/use-application';

const EndPage = () => {
  const { classes } = useStyles();
  const status = useRecoilValue(ApplicationState);
  const [loading, setLoading] = useState(true);
  const sardineCustomer = useSardineCustomer();
  const [bankingStatus, setBankingStatus] =
    useState<BankingApplicationStatus>('Unused');
  const [hasOutstandingOwners, setHasOutstandingOwners] = useState(false);
  const { refreshProductOnboardingStatus, applyingForProducts } =
    useApplicationFlowContext();
  const hasPromoCode = !!status.user.promoCode;

  const endOnboardingV2 = async () => {
    setLoading(true);
    try {
      // First, refresh the status so we are 100% sure what we have to do
      // (since having just one endpoint to submit a new application is NO GOOD apparently)
      const onboardingStatus = await refreshProductOnboardingStatus();

      if (onboardingStatus.user.id) {
        await sardineCustomer.fetchQuery(
          onboardingStatus.user.id,
          onboardingStatus.user.id,
        );
      }

      if (onboardingStatus.company.createdBy !== onboardingStatus.user.id) {
        return;
      }

      const promises: Promise<any>[] = [];

      if (onboardingStatus.productStatus.banking.status === 'pending') {
        const bankingApplication =
          await flexbaseBankingClient.getApplicationStatus();
        if (
          BankApplicationStatusIsApplicationResponse(bankingApplication) &&
          bankingApplication.status === 'Incomplete'
        ) {
          promises.push(flexbaseBankingClient.createApplication());
        }
      }

      // Do we have anything we need to do for credit?
      // Pending is actually a poor way to determine if it needs to be submitted but.. wygd
      if (
        onboardingStatus.productStatus.credit.status === 'pending' ||
        (onboardingStatus.productStatus.credit.status !== 'unused' &&
          hasPromoCode)
      ) {
        promises.push(flexbaseOnboardingClient.underwritingSubmit());
      }

      await Promise.allSettled(promises);
      const refreshResult = await Promise.allSettled([
        refreshProductOnboardingStatus(),
        flexbaseOnboardingClient.getOnboardingOverview(),
      ]);
      if (refreshResult[1].status === 'fulfilled') {
        const overviewResponse = refreshResult[1].value;
        const allOwnersComplete = overviewResponse.users
          .filter((o) => o.roles.includes('OWNER'))
          .every((o) => o.isComplete);
        setHasOutstandingOwners(!allOwnersComplete);
      }
      if (onboardingStatus.optedProducts.includes('BANKING')) {
        const bankingApplicationStatus =
          await flexbaseBankingClient.getApplicationStatus();
        if (
          BankApplicationStatusIsApplicationResponse(
            bankingApplicationStatus,
          ) ||
          BankApplicationStatusIsAwaitingDocuments(bankingApplicationStatus)
        ) {
          setBankingStatus(bankingApplicationStatus.status);
        }
      }
    } catch (e) {
      console.error('end.page.tsx', e);
    } finally {
      setLoading(false);
    }
  };

  let userType: 'user' | 'owner' | 'applicant' | 'officer';
  switch (true) {
    case status.userIsApplicant:
      userType = 'applicant';
      break;
    case status.user.roles.includes('OWNER'):
      userType = 'owner';
      break;
    case status.user.roles.includes('OFFICER'):
      userType = 'officer';
      break;
    default:
      userType = 'user';
  }

  useEffect(() => {
    endOnboardingV2();
  }, []);

  return (
    <Box className={classes.container}>
      {loading ? (
        <AuthLoader
          classNames={{ loadingContainer: classes.loader }}
          label={
            <Text className={classes.loaderLabel}>
              {status.userIsApplicant
                ? 'Submitting Application'
                : 'Saving Information'}
            </Text>
          }
        />
      ) : (
        <OnboardingSubmittingScreen
          endScreenFor={userType}
          id="idk"
          graphic={Yolo}
          productStatus={status.productStatus}
          bankingStatus={bankingStatus}
          hasOutstandingOwners={hasOutstandingOwners}
          hasPromoCode={hasPromoCode}
          applyingForProducts={applyingForProducts}
        />
      )}
    </Box>
  );
};

const useStyles = createStyles((theme) => ({
  container: {
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundImage: `url(${Frame})`,
    width: '100%',
    height: '100vh',
    backgroundColor: theme.colors.neutral[3],
    '@media(max-width: 767px)': {
      backgroundImage: 'unset',
    },
  },
  loader: {
    backgroundColor: 'transparent',
    color: theme.colors.primary[2],
  },
  loaderLabel: {
    color: theme.colors.primary[8],
    fontSize: rem(32),
  },
}));

export default EndPage;
