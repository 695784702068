/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';
// Progress
import { createStyles } from '@mantine/emotion';
import { Text, rem } from '@mantine/core';
import { formatCurrency } from '../../../../utilities/formatters';

type Props = {
  title: ReactNode;
  totalAvailableBalance: number;
  currentAmount: number;
  currentAmountPercent?: boolean;
  classNames?: Partial<{ card: string; endContent: string }>;
  children?: ReactNode;
};

const ProgressPercent = ({
  title,
  totalAvailableBalance,
  currentAmountPercent,
  classNames,
  currentAmount,
  children,
}: Props) => {
  const { classes, cx, theme } = useStyles();
  const amountPercent =
    totalAvailableBalance > 0
      ? (currentAmount / totalAvailableBalance) * 100
      : 0;

  return (
    <div className={cx(classes.card, classNames?.card)}>
      <Text fz={rem(16)} c={theme.colors.neutral[8]} fw={500}>
        {title}
      </Text>
      <Text fz={26} color="#000000" fw={500} className={classes.pp}>
        {formatCurrency(currentAmount)}
        {currentAmountPercent && (
          <>
            {' / '}
            {amountPercent.toFixed(2)}%
          </>
        )}
      </Text>
      {children}
    </div>
  );
};

export default ProgressPercent;

const useStyles = createStyles((theme) => ({
  card: {
    width: 'auto',
    height: rem(200),
    backgroundColor: theme.colors.neutral[0],
    borderRadius: rem(8),
    padding: rem(24),
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.colors.neutral[2]}`,
  },
  pp: {
    fontFamily: 'PP Neue Montreal',
  },
  endContent: {
    marginTop: 'auto',
  },
  flexGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  spacingTop: {
    marginTop: rem(16),
  },
}));
