import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useSelfServiceStyles = createStyles((theme, _, helpers) => ({
  actionItemCard: {
    backgroundColor: theme.colors.neutral[0],
    borderRadius: theme.defaultRadius,
    color: theme.colors.primary[9],
    padding: '28px 32px',
    width: '100%',
  },
  actionItemIcon: { minWidth: '2rem', maxWidth: '2rem' },
  rotateIcon: { transform: 'rotate(180deg)' },
  actionItemText: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    gap: theme.spacing.lg,
  },
  actionItemContainer: {
    height: 'fit-content',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing.md,
    gap: theme.spacing.xl,
    maxWidth: 867,
    width: '100%',
  },
  actionItemContainerFullWidth: {
    width: '100%',
  },
  actionItemContainerRestrictWidth: {
    width: 867,
  },
  requiredDocumentItem: {
    display: 'grid',
    gridTemplateColumns: '20px 1fr',
    marginTop: rem(4),
    alignItems: 'center',
  },
  linkedAccountListHeader: {
    marginBottom: '4px',
  },
  linkedAccountSep: {
    width: rem(350),
    height: '2px',
    borderBottom: '1px solid #EBEBEB',
    '@media(max-width: 767px)': {
      width: '100%',
    },
  },
  linkedAccountListItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '1.5rem',
    borderRadius: 8,
    gap: theme.spacing.sm,
    border: `2px solid ${theme.colors.neutral[4]}`,
  },
  primaryAccount: {
    border: `2px solid ${theme.colors.primary[6]}`,
  },
  gridTable: {
    display: 'grid',
    borderTop: `1px solid ${theme.colors.neutral[2]}`,
    minHeight: 50,
  },
  gridTableHeader: {
    textTransform: 'uppercase',
    alignItems: 'center',
    height: 50,
    fontSize: 12,
    color: theme.colors.neutral[7],
  },
  gridTableRow: {
    borderTop: `1px solid ${theme.colors.neutral[2]}`,
    minHeight: 50,
    fontSize: 14,
    color: theme.colors.neutral[8],
  },
  outstandingOwnersTable: {
    gridTemplateColumns: 'repeat(3, 1fr)',
    alignItems: 'center',
  },
  requestedDocumentsTable: {
    gridTemplateColumns: '1fr 1fr 104px 235px',
    alignItems: 'center',
  },
  requestedDocumentsTableHeader: {
    [helpers.smallerThan(767)]: {
      gridTemplateColumns: 'repeat(2, 1fr) 0 0',
      gap: theme.spacing.xs,
    },
  },
  requestedDocumentsRow: {
    [helpers.smallerThan(767)]: {
      gridTemplateColumns: '1fr 1fr',
      gap: theme.spacing.xs,
      padding: '16px 0',
    },
  },
  requestedDocumentsRowMultiItem: {
    [helpers.largerThan(767)]: {
      alignItems: 'start',
      paddingTop: theme.spacing.sm,
      paddingBottom: theme.spacing.sm,
    },
  },
  requestedDocName: {
    [helpers.smallerThan(767)]: {
      gridRow: '1/3',
      alignSelf: 'center',
    },
  },
  requestedDocBadge: {
    [helpers.smallerThan(767)]: {
      gridColumn: 2,
      gridRow: 2,
    },
  },
  requestedDocFiles: {
    [helpers.smallerThan(767)]: {
      gridRow: 3,
      gridColumn: '1/3',
    },
  },
  primaryButton6: {
    backgroundColor: theme.colors.primary[6],
    '&:not([data-disabled])': {
      '&:hover': {
        backgroundColor: theme.colors.primary[7],
      },
    },
  },
}));
