import { SegmentedControl } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useState } from 'react';

export type CriteriaMultiSelectOption = {
  label: string;
  filterCriteria: string | string[];
  key: string;
};

type Props = {
  options: CriteriaMultiSelectOption[];
  selectedOption: CriteriaMultiSelectOption | null;
  onChange: (selected: CriteriaMultiSelectOption | null) => void;
};

export const SegmentedFilter = ({
  options,
  selectedOption: propSelectedOption,
  onChange,
}: Props) => {
  const initialSelectedValue = propSelectedOption
    ? propSelectedOption.label
    : 'All';
  const [selectedValue, setSelectedValue] =
    useState<string>(initialSelectedValue);
  const { classes } = useStyles();

  return (
    <SegmentedControl
      style={{ overflowX: 'auto' }}
      fullWidth
      classNames={{ indicator: classes.select, root: classes.selectContainer }}
      value={selectedValue}
      onChange={(value) => {
        setSelectedValue(value);
        const selectedOption =
          options.find((option) => option.label === value) || null;
        onChange(selectedOption);
      }}
      data={[
        { value: 'All', label: 'All' },
        ...options.map((option) => ({
          value: option.label,
          label: option.label,
        })),
      ]}
    />
  );
};

export const useStyles = createStyles((theme) => ({
  select: {
    background: theme.colors.neutral[3],
    borderRadius: 8,
  },
  selectContainer: {
    background: theme.colors.neutral[0],
    borderRadius: 8,
  },
}));
