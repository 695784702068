import { Button, Flex, Text, useMantineTheme } from '@mantine/core';
import { EmptyBillTableIcon, PlusSignIcon } from 'assets/svg';
import { useNavigate } from 'react-router-dom';

type Props = {
  headingText: string;
};

const EmptyBillTable = ({ headingText }: Props) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const handleCreateInvoice = () => {
    navigate('/bill-pay/new');
  };
  return (
    <Flex
      bg={theme.colors.neutral[0]}
      justify="center"
      direction="column"
      align="center"
      p="xl"
    >
      <EmptyBillTableIcon />
      <Text c="primary.8" fw={500} mt="xl">
        {headingText}
      </Text>

      <Button
        mt="xl"
        color="primary.6"
        onClick={handleCreateInvoice}
        leftSection={<PlusSignIcon width={10} />}
        data-testId={'add-new-bill-btn'}
      >
        Add new Bill
      </Button>
    </Flex>
  );
};

export default EmptyBillTable;
