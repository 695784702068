import { Alert, Container, Text, Textarea, TextInput } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import PaymentStep from '../payment-step';
import FbSelect from '@common/composites/flexbase-select';
import { DateInput } from '@mantine/dates';
import { CalendarIcon } from 'assets/svg';
import { GiAlarmClock } from 'react-icons/gi';
import { FaFileUpload } from 'react-icons/fa';
import { ChangeEvent, useRef, useState } from 'react';
import { useForm } from '@mantine/form';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { DateTime } from 'luxon';

type HandleInputChange = ChangeEvent<HTMLInputElement>;
type FileName = {
  fileName: string;
};
type FormProps = {
  sendPaymentTo: string;
  name: string;
  email: string;
  amount: string;
  dueDate: string;
  companyName: string;
  destinationAccount: string;
  notes: string;
  attachments: FileName[];
};
type Props = {
  onPaymentRequestDetailsNext: () => void;
};

const PaymentRequest = ({ onPaymentRequestDetailsNext }: Props) => {
  const { classes } = useStyles();
  const [diffDays, setDiffDays] = useState<number>();
  const [editContact, setEditContact] = useState<boolean>(false);
  const paymentRequestForm = useForm<FormProps>({
    initialValues: {
      sendPaymentTo: '',
      name: '',
      email: '',
      amount: '',
      dueDate: '',
      companyName: '',
      destinationAccount: '',
      notes: '',
      attachments: [],
    },
  });

  const uploadDocRef = useRef<HTMLInputElement>(null);

  const setFilePicker = () => {
    const evt = new MouseEvent('click', {
      bubbles: true,
      cancelable: false,
    });
    uploadDocRef?.current?.dispatchEvent(evt);
  };

  const uploadPicture = async ({ target: { files } }: HandleInputChange) => {
    if (files) {
      paymentRequestForm.setValues({
        ...paymentRequestForm.values,
        attachments: [
          ...paymentRequestForm.values.attachments,
          { fileName: files[0].name },
        ],
      });
    }
  };

  const testArray = [
    {
      label: 'Charles Bonito',
      value: 'testId',
    },
    {
      label: '+ Create new contact',
      value: 'newContact',
    },
  ];

  const diffTotalDays = (val: any) => {
    const date = DateTime.fromJSDate(val);
    const diff: any = date.diffNow('days');
    if (diff) {
      setDiffDays(parseInt(diff.values.days, 10));
    }
  };

  const selectSendPayment = (value: string | null) => {
    if (!value) return;
    if (value === 'newContact') {
      setEditContact(false);
    } else {
      setEditContact(true);
    }

    const result = testArray.find(
      (item: { value: string }) => item.value === value,
    );

    paymentRequestForm.setValues({
      ...paymentRequestForm.values,
      sendPaymentTo: value,
      name: result?.label && value !== 'newContact' ? result.label : '',
    });
  };

  return (
    <PaymentStep
      titleText="Create a Payment Request"
      showNextButton={true}
      showBackButton={false}
      nextButtonLabel="Review"
      onNextClick={onPaymentRequestDetailsNext}
    >
      <Container p="0">
        <span>Contact details</span>
        <FbSelect
          inputProps={{ mt: '1rem' }}
          {...paymentRequestForm.getInputProps('sendPaymentTo')}
          onChange={(value: string | null) => selectSendPayment(value)}
          data={testArray}
          label="Send payment request to:"
          placeholder="Choose or create a contact"
        />
        {editContact ? (
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Alert
              mt="1rem"
              p={5}
              onClick={() => setEditContact(false)}
              className={classes.alert}
              color="gray"
            >
              Edit Contact Details
            </Alert>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem',
            }}
          >
            <TextInput
              {...paymentRequestForm.getInputProps('name')}
              style={{ width: '49%' }}
              label="Name"
              placeholder="Jhon Noe"
            />
            <TextInput
              {...paymentRequestForm.getInputProps('email')}
              style={{ width: '49%' }}
              label="Email"
              placeholder="example@gmail.com"
            />
          </div>
        )}
      </Container>
      <Container p="0" mt="1rem">
        <span>Request details</span>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1rem',
          }}
        >
          <TextInput
            {...paymentRequestForm.getInputProps('amount')}
            style={{ width: '49%' }}
            placeholder="$0.00"
            label="Amount"
          />
          {/* TODO: Fix DateInput once app compiles*/}
          <DateInput
            {...paymentRequestForm.getInputProps('dueDate')}
            onChange={(value) => diffTotalDays(value)} // fix
            className={classes.datePicker}
            style={{ width: '49%' }}
            rightSection={
              <CalendarIcon fill="#5F5F5F" height="20px" width="20" />
            }
            valueFormat="MM/DD/YYYY"
            label="Due Date"
            placeholder="MM/DD/YYYY"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {diffDays && <>{diffDays} days from now</>}
        </div>
        <FbSelect
          inputProps={{ mt: '1rem' }}
          {...paymentRequestForm.getInputProps('companyName')}
          data={[]}
          label="Company name to show on request"
        />
        <FbSelect
          inputProps={{ mt: '1rem' }}
          {...paymentRequestForm.getInputProps('destinationAccount')}
          data={[]}
          label="Destination account"
          placeholder="Select Account"
        />
        <Text size="xs" mt="1vh">
          Incoming payments are placed in a secure receiving account to keep
          destination account details anonymous.
        </Text>
        <Alert mt="1rem" style={{ background: '#efeffd' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <GiAlarmClock size={18} />
            Payment link expires on September 17, 2023
          </div>
        </Alert>
      </Container>
      <Container p="0" mt="1rem" mb="1rem">
        <Text size="sm">Notes / Attachments (optional)</Text>
        <Text size="sm">
          Will be visible to the recipient and via the payment link.
        </Text>
        <Textarea
          mt="1rem"
          label="Notes"
          className={classes.textarea}
          {...paymentRequestForm.getInputProps('notes')}
          placeholder="Invoice #114, Payment for Research Project, etc."
        />
        <div style={{ marginTop: '1rem' }}>
          <Text size="sm">Attachments</Text>
          <input
            style={{ visibility: 'hidden', height: 0 }}
            type="file"
            accept=".png, .jpg, .jpge"
            ref={uploadDocRef}
            onChange={uploadPicture}
            aria-multiline
          />
          <Alert
            onClick={setFilePicker}
            color="gray"
            style={{ cursor: 'pointer' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FaFileUpload />
              <Text size="sm" ml="1vh">
                Drag and drop Attachments or click to upload
              </Text>
            </div>
          </Alert>
          <Container p={0} mt="1rem">
            {(paymentRequestForm.values.attachments || []).map(
              (item: FileName, index) => (
                <Alert
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    background: 'white',
                    border: '1px solid #efeffd',
                  }}
                >
                  <BsFillCheckCircleFill /> <span>{item.fileName}</span>
                </Alert>
              ),
            )}
          </Container>
        </div>
      </Container>
    </PaymentStep>
  );
};

const useStyles = createStyles((theme) => ({
  datePicker: {
    '.mantine-DatePicker-input': {
      color: '#5F5F5F',
      fontWeight: 500,
      border: '1px solid #E6E7E9',
      ':focus': {
        border: `1px solid ${theme.primaryColor}`,
      },
      marginBottom: 10,
      height: 41,
      borderRadius: 8,
    },
    '.mantine-DatePicker-label': {
      fontSize: 14,
      color: '#5F5F5F',
      padding: 0,
      marginBottom: 2,
    },
    '.mantine-DatePicker-rightSection': {
      right: 8,
    },
  },
  textarea: {
    borderRadius: 8,
  },
  alert: {
    borderRadius: 8,
    width: '40%',
    textAlign: 'center',
    cursor: 'pointer',
  },
}));

export default PaymentRequest;
