import { createStyles } from '@mantine/emotion';
import { rem } from '@mantine/core';

export const useStyles = createStyles((theme) => ({
  LastTransactionContainer: {
    position: 'relative',
    borderRadius: rem(8),
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colors.neutral[0],
    height: rem(200),
    width: rem(420),
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
    border: `1px solid white`,
    '&:hover': {
      border: `1px solid ${theme.primaryColor}`,
    },
    cursor: 'pointer',
  },
  TransactionLabel: {
    fontSize: rem(16),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(19),
    color: theme.colors.neutral[8],
  },
  totalTransaction: {
    marginTop: rem(8),
    fontSize: rem(26),
    fontWeight: 500,
    fontStyle: 'normal',
    lineHeight: rem(31),
    color: theme.colors.neutral[10],
    fontFamily: 'PP Neue Montreal',
  },
  totalTransactionLabel: {
    marginTop: rem(80),
    borderColor: '#c8d6e5',
    width: rem(420),
    '@media(max-width: 767px)': {
      width: '100%',
      minWidth: 'unset',
    },
    margin: rem(24),
  },
  storeText: {
    fontSize: rem(16),
    lineHeight: rem(19),
    color: '#A2A2A2',
    fontWeight: 500,
  },
}));
