import { Stack, Text, useMantineTheme } from '@mantine/core';
import { Dropzone, FileRejection, FileWithPath } from '@mantine/dropzone';
import { useState } from 'react';
import { useInvoiceWizard } from '../../invoice-wizard';
import { useCreateInvoiceDocument } from '@queries/use-documents';
import { PiUploadSimple } from 'react-icons/pi';
import { formatCurrency } from '@utilities/formatters';
import { DateTime } from 'luxon';

const ACCEPTABLE_UPLOAD_TYPES = ['image/png', 'image/jpeg', 'application/pdf'];
const MAX_SIZE = 10 * 1024 * 1024; // 10 MB in bytes

const useRejectionErrors = () => {
  // yanked from @mantine/dropzone, which uses react-dropzone under the hood
  enum ErrorCode {
    FileInvalidType = 'file-invalid-type',
    FileTooLarge = 'file-too-large',
    FileTooSmall = 'file-too-small',
    TooManyFiles = 'too-many-files',
  }

  const errorCodeToMessage: Record<ErrorCode, string> = {
    [ErrorCode.FileInvalidType]: 'File type is not supported',
    [ErrorCode.FileTooLarge]: 'File is too large',
    [ErrorCode.FileTooSmall]: 'File is too small',
    [ErrorCode.TooManyFiles]: 'Too many files selected',
  };

  const [rejectErrors, setRejectErrors] = useState<string[]>([]);

  const handleReject = (rejections: FileRejection[]) => {
    const errorMessages = rejections.map((r) => {
      const errorCode = r.errors[0].code as ErrorCode;
      const name = r.file.name;
      return `${name}: ${errorCodeToMessage[errorCode]}`;
    });

    setRejectErrors(errorMessages);
  };

  const clearRejectErrors = () => {
    setRejectErrors([]);
  };

  return { rejectErrors, handleReject, clearRejectErrors };
};

type Props = {
  onUpload: (file: FileWithPath[]) => void;
};

const InvoiceUpload = ({ onUpload }: Props) => {
  const theme = useMantineTheme();
  const { state, setState, onNext, goToNextStep, onEvent } = useInvoiceWizard();
  const { isInvoiceDraft, isActionDisabled } = state;
  const {
    mutate: mutateCreateInvoiceDocument,
    mutateAsync: mutateCreateInvoiceDocumentAsync,
    error: createInvoiceDocumentError,
    isPending: isPendingCreateInvoiceDocument,
  } = useCreateInvoiceDocument();
  const { rejectErrors, handleReject, clearRejectErrors } =
    useRejectionErrors();

  const handleDrop = (file: FileWithPath[]) => {
    onUpload(file);
    clearRejectErrors();
  };

  onNext(() => {
    if (state.uploadedDocument && !state.existingDocumentId) {
      mutateCreateInvoiceDocument(
        {
          file: state.uploadedDocument,
          type: 'billpay',
          runOcr: true,
          formatType: 'invoice',
        },
        {
          onSuccess: (data) => {
            const { document, ocrRun } = data;

            const parseNumberStringsToCents = (val?: string | number) => {
              switch (typeof val) {
                case 'string':
                  return Number(val.replace(/,/g, '')) * 100;
                case 'number':
                  return val * 100;
                default:
                  return 0;
              }
            };

            const isValidDateWithCorrectFormat = (dateString: string) => {
              // validates 'MM/DD/YYYY'
              const dateRegex =
                /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;

              if (!dateRegex.test(dateString)) {
                return false;
              }

              // Parse the date parts to integers
              const [day, month, year] = dateString.split('/').map(Number);

              // Check for valid day ranges based on the month
              const daysInMonth = new Date(year, month, 0).getDate();
              return day >= 1 && day <= daysInMonth;
            };
            /*
            This is a hack to get the dueDate to be in the correct format, a ticket has been
            created to fix this at the API level. (DM)

            https://linear.app/flexbase/issue/TEAM1-1969/update-documentsocrrun=true-endpoints-duedate-field
            */
            const parsedDueDate =
              ocrRun.dueDate && isValidDateWithCorrectFormat(ocrRun.dueDate)
                ? DateTime.fromFormat(ocrRun.dueDate, 'MM/dd/yyyy').toFormat(
                    'yyyy-MM-dd',
                  ) + 'T00:00:00'
                : undefined;

            setState((prev) => ({
              ...prev,
              existingDocumentId: document.id,
              invoiceDetails: {
                // lineItems: ocrRun.lineItems.map((item) => ({
                //   ...item,
                //   status: 'active',
                //   accountingInfo: {},
                //   quantity: item.quantity ?? 1,
                //   total: parseNumberStringsToCents(item.total),
                // })),
                lineItems: [],
                // credits: 0,
                dueDate: parsedDueDate,
                // tax: Number(ocrRun.tax),
              },
              invoiceNumber: ocrRun.invoiceNumber,
              invoiceTotal: {
                cents: parseNumberStringsToCents(ocrRun.total),
                formatted: formatCurrency(ocrRun.total),
              },
            }));
            goToNextStep();
          },
        },
      );
    }
    // if we already have an existing documentId (no-reupload), go to the next step
    if (
      (state.existingDocument && state.existingDocumentId) ||
      (state.uploadedDocument && state.existingDocumentId)
    ) {
      goToNextStep();
    }
  });

  onEvent('onSaveEdits', async () => {
    if (state.uploadedDocument && !state.existingDocumentId) {
      try {
        const data = await mutateCreateInvoiceDocumentAsync({
          file: state.uploadedDocument,
          type: 'billpay',
          runOcr: true,
          formatType: 'invoice',
        });
        const { document, ocrRun } = data;
        const parsedDueDate = ocrRun.dueDate
          ? DateTime.fromFormat(ocrRun.dueDate, 'MM/dd/yyyy').toFormat(
              'yyyy-MM-dd',
            )
          : undefined;
        setState((prev) => ({
          ...prev,
          existingDocumentId: document.id,
          invoiceDetails: {
            //   lineItems: ocrRun.lineItems.map((item) => ({
            //     ...item,
            //     status: 'active',
            //     accountingInfo: {},
            //     quantity: item.quantity ?? 1,
            //     total: Number(item.total) * 100,
            //   })),
            //   credits: 0,
            dueDate: parsedDueDate,
            //   tax: Number(ocrRun.tax),
            lineItems: [],
          },
          invoiceTotal: {
            cents: Number(ocrRun.total) * 100,
            formatted: ocrRun.total,
          },
        }));
        return {
          existingDocumentId: data.document.id,
        };
      } catch (err) {
        return false;
      }
    }
  });

  return (
    <>
      <Dropzone
        disabled={!isInvoiceDraft || isActionDisabled}
        onDrop={handleDrop}
        onReject={handleReject}
        maxSize={MAX_SIZE}
        maxFiles={1}
        accept={ACCEPTABLE_UPLOAD_TYPES}
        my={'lg'}
        loading={isPendingCreateInvoiceDocument}
      >
        <Stack gap="md" align="center" my={'xl'} mx={'md'}>
          <PiUploadSimple size={'1.25rem'} color={theme.colors.neutral[6]} />
          <Text fw={600}>Drag &amp; drop or click to choose a file</Text>
          <Text size="xs" color="dimmed">
            PNG, JPG, and PDF accepted (Size limit: 10mb)
          </Text>
        </Stack>
      </Dropzone>

      {rejectErrors.length > 0 &&
        rejectErrors.map((error, index) => (
          <Text
            size="xs"
            color={theme.colors.red[6]}
            mt={index > 0 ? undefined : 'md'}
            key={`${index}-${error}`}
          >
            {error}
          </Text>
        ))}
      {createInvoiceDocumentError && (
        <Text size="xs" color={theme.colors.red[7]}>
          Error uploading document: {createInvoiceDocumentError.message}
        </Text>
      )}
    </>
  );
};

export default InvoiceUpload;
