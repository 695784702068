import { Line } from '@flexbase-eng/sdk-typescript/models/components';
import { useCreditLines } from '@queries/use-credit-lines';
import { useMarqetaUiFeatureFlag } from '@utilities/feature-flags';
import { createContext, PropsWithChildren, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';

// shorthand until we create an actual type in the SDK
type CreditLineIssuer = Line['issuer'];

type CreditLineContextType = {
  issuer: CreditLineIssuer;
  line: Line | undefined;
};

const CreditLineContext = createContext<CreditLineContextType>({
  issuer: 'lithic',
  line: undefined,
});

export const useCreditLineContext = () => {
  const ctx = useContext(CreditLineContext);

  return ctx;
};

type CreditLineProviderProps = PropsWithChildren<{
  issuer: CreditLineIssuer;
}>;

export const CreditLineProvider = ({
  children,
  issuer,
}: CreditLineProviderProps) => {
  const { businessId } = useRecoilValue(ApplicationState);
  const {
    data: creditLines,
    isPending: isPendingCreditLines,
    isError: isErrorCreditLines,
  } = useCreditLines(businessId);
  const mqEnabled = useMarqetaUiFeatureFlag();

  // don't expose marqeta routes yet
  if (!mqEnabled && issuer === 'marqeta') {
    return <Navigate to="/cards" />;
  }

  if (isPendingCreditLines) {
    // TODO
    return <div></div>;
  } else if (isErrorCreditLines) {
    // TODO
    console.error('Unable to load credit lines. Defaulting to lithic.');
  }

  const value: CreditLineContextType = {
    issuer,
    line: creditLines?.find((line) => line.issuer === issuer),
  };

  return (
    <CreditLineContext.Provider value={value}>
      {children}
    </CreditLineContext.Provider>
  );
};
