import { useState } from 'react';
import GetPaddedAccountMask from '../../../utilities/formatters/get-padded-account-mask';
import { createStyles } from '@mantine/emotion';
import { PiEyeBold, PiEyeSlashBold } from 'react-icons/pi';

export const AccountMaskCell = ({
  accountNumber,
}: {
  accountNumber: string;
}) => {
  const { classes, theme } = useStyles();
  const [hideAccount, setHideAccount] = useState(true);

  return (
    <div
      className={classes.inlineCell}
      onClick={() => setHideAccount((p) => !p)}
      style={{ cursor: 'pointer' }}
    >
      <div className={classes.contentText}>
        {hideAccount ? GetPaddedAccountMask(accountNumber, 5) : accountNumber}
      </div>
      {hideAccount ? (
        <PiEyeBold color={theme.colors.neutral[8]} size={'1rem'} />
      ) : (
        <PiEyeSlashBold color={theme.colors.neutral[8]} size={'1rem'} />
      )}
    </div>
  );
};

export const useStyles = createStyles(() => ({
  inlineCell: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 16,
    flexShrink: 0,
  },
  contentText: {
    fontFamily: 'Inter, sans-serif',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '21px',
    color: '#5f5f5f',
  },
}));

export default AccountMaskCell;
