import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../recoil-state/application/product-onboarding';
import { useForm } from '@mantine/form';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { TextInput } from '@mantine/core';
import { validateUrl } from '../../../../../utilities/validators/validate-url';
import { OptionalFieldValidator } from '../../../../../utilities/validators/validate-required';
import { useApplicationFlowContext } from '../../../onboarding-hooks';

type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryWebsiteForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ company }, setStatus] = useRecoilState(ApplicationState);
  const { applyingForProducts } = useApplicationFlowContext();
  const form = useForm({
    initialValues: {
      website: company.website || '',
    },
    validate: {
      website: (value) => {
        return applyingForProducts.includes('BANKING')
          ? OptionalFieldValidator(
              'You must enter a valid URL for your company website',
              validateUrl,
              value,
            )
          : validateUrl(value)
            ? null
            : 'You must enter a valid URL for your company website';
      },
    },
  });
  const onSave = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const { website } = form.values;
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        website,
      });
      setStatus((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          website,
        },
      }));
    } catch (e) {
      throw 'An error occurred while trying to update company website';
    }
  };

  return (
    <SummaryEditBox
      label="Website"
      value={company.website}
      classNames={classNames}
      savePromise={onSave}
      editInputs={
        <div className={classes.inputContainer}>
          <TextInput
            placeholder="Company website (eg www.flex.one)"
            label="Website"
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
            {...form.getInputProps('website')}
            id="summary-business-name"
          />
        </div>
      }
    />
  );
};

export default SummaryWebsiteForm;
