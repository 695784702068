import FlexbaseDefaultHeader from '@common/composites/header/flexbase-header';
import FlexbaseMainLayout from 'components/layouts/main';
import BillpayPage from './billpay.page';
import { Badge, Flex, rem, Text } from '@mantine/core';

const Billpay = () => {
  return (
    <FlexbaseMainLayout>
      <FlexbaseDefaultHeader
        title={
          <Flex gap="xs" align="center">
            <Text ff="Redaction" size={rem(28)}>
              Bill pay
            </Text>
            <Badge
              radius={4}
              bg="primary.0"
              sx={(theme) => ({
                border: `1px solid ${theme.colors.primary[3]}`,
              })}
            >
              Beta
            </Badge>
          </Flex>
        }
      />
      <BillpayPage />
    </FlexbaseMainLayout>
  );
};

export default Billpay;
