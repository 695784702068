import { createStyles } from '@mantine/emotion';
import { Flex, Text, UnstyledButton, rem } from '@mantine/core';
import { useEffect, useState } from 'react';
import { NavTab } from './nav-tabs';

type ClassNames = {
  tabsContainer: string;
  tabOptionContainer: string;
  tabOptionContainerSelected: string;
  button: string;
};

type Props = {
  tabs: NavTab[];
  selectedTab: string;
  classNames?: Partial<ClassNames>;
  onTabClick: (tab: NavTab) => void;
};

/**
 * This currently delegates tab selection to the parent component,
 * so that it works with the existing "nav-tabs" component
 * @param tabs Array of tabs in {value: string, label: string} format
 * @param selectedTab The currently selected tab
 * @param onTabClick A function that is called whenever a tab is clicked
 * @param classNames CSS classname overrides, styles will be combined
 */
export const UnderlinedTabs = ({
  tabs,
  selectedTab,
  onTabClick,
  classNames,
}: Props) => {
  const { classes, cx } = useStyles();

  const [sliderPosition, setSliderPosition] = useState({
    left: '0px',
    width: '0px',
  });

  const updateSliderPosition = () => {
    const activeElement = document.querySelector(
      `[data-tab-value="${selectedTab}"]`,
    ) as HTMLElement;
    if (activeElement) {
      setSliderPosition({
        left: `${activeElement.offsetLeft}px`,
        width: `${activeElement.offsetWidth}px`,
      });
    }
  };

  useEffect(() => {
    updateSliderPosition();
  }, [selectedTab]);

  return (
    <div className={cx(classes.tabsContainer, classNames?.tabsContainer)}>
      {tabs.map((tab) => (
        <div
          key={tab.route}
          className={cx(
            classes.tabOptionContainer,
            classNames?.tabOptionContainer,
          )}
          data-tab-value={tab.route} // data attribute for finding the element
        >
          <UnstyledButton
            disabled={tab.disabled ?? false}
            className={cx(classes.button, classNames?.button)}
            onClick={() => onTabClick(tab)}
            data-testid={tab.label.replace(/\s+/g, '-')}
          >
            <Flex align={'center'}>
              {tab.icon}
              {tab.label}{' '}
              <Text
                component={typeof tab.count === 'string' ? 'p' : 'div'}
                ml="xxs"
              >
                {tab.count}
              </Text>
            </Flex>
          </UnstyledButton>
        </div>
      ))}
      <div className={classes.slider} style={{ ...sliderPosition }}></div>
    </div>
  );
};

const useStyles = createStyles((theme) => ({
  tabsContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing.xl,
    width: '100%',
    borderBottom: `1px solid ${theme.colors.neutral[2]}`,
  },
  button: {
    color: theme.colors.primary[7],
    cursor: 'pointer',
    fontSize: theme.fontSizes.md,
    fontWeight: 500,
    ':disabled': {
      opacity: 0.5,
      cursor: 'unset',
    },
  },
  tabOptionContainer: {
    paddingBottom: theme.spacing.md,
  },
  slider: {
    position: 'absolute',
    bottom: '0',
    height: rem(3),
    backgroundColor: `${theme.colors.primary[7]}`,
    transition: 'all 0.2s ease',
  },
}));
