import { UseFormReturnType } from '@mantine/form';
import { Box, Button, Flex, rem, Text } from '@mantine/core';
import FlexNumberInput from '@common/flex-number-input';
import AccountSelection from '@common/account-selection';
import { DepositAccount, PlaidAccount } from 'types/move-funds.model';
import { createStyles } from '@mantine/emotion';

type BankTransferProps = {
  isLoadingRequest: boolean;
  plaidAccounts: PlaidAccount[];
  depositAccounts: DepositAccount[];
  transferForm: UseFormReturnType<{
    amount: number;
    toAccount: DepositAccount;
    fromAccount: PlaidAccount;
  }>;
  onMakeTransfer: () => void;
  onLinkingCompleted: () => void;
};

const TransferFunds = ({
  transferForm,
  plaidAccounts,
  depositAccounts,
  isLoadingRequest,
  onMakeTransfer,
  onLinkingCompleted,
}: BankTransferProps) => {
  const transferFormValues = transferForm.values;
  const isPlaidAccUnlinked = transferFormValues.fromAccount.unlinked;
  const { classes } = useInputStyles({ amount: transferFormValues.amount });

  const transferFrom = (value: PlaidAccount) => {
    transferForm.setFieldValue('fromAccount', value);
  };

  const transferTo = (value: DepositAccount) => {
    transferForm.setFieldValue('toAccount', value);
  };

  const handleTransferFunds = () => {
    const validationResult = transferForm.validate();
    if (!isPlaidAccUnlinked && !validationResult.hasErrors) {
      onMakeTransfer();
    }
  };

  return (
    <Box>
      <Box
        p="xl"
        bg="neutral.0"
        sx={(theme) => ({ border: `1px solid ${theme.colors.neutral[4]}` })}
      >
        <Text size="sm">Amount</Text>
        <FlexNumberInput
          prefix="$"
          decimalScale={2}
          thousandSeparator
          variant="unstyled"
          classNames={classes}
          allowNegative={false}
          error={transferForm.errors.amount}
          value={transferFormValues.amount}
          onValueChange={(v) =>
            transferForm.setFieldValue('amount', v.floatValue ?? 0)
          }
        />
      </Box>

      <Box mt="xl">
        <Text size="sm">Transferring from</Text>
        <AccountSelection
          accounts={plaidAccounts}
          onLinkingCompleted={onLinkingCompleted}
          currentAccount={transferFormValues.fromAccount}
          onAccountChange={(c) => transferFrom(c as PlaidAccount)}
        />
      </Box>

      <Box mt="xl">
        <Text size="sm">Transferring to</Text>
        <AccountSelection
          accounts={depositAccounts}
          currentAccount={transferFormValues.toAccount}
          onAccountChange={(c) => transferTo(c as DepositAccount)}
        />
      </Box>

      {isPlaidAccUnlinked && (
        <Box
          mt="xl"
          bg="promote.0"
          p="md"
          sx={(theme) => ({ border: `1px solid ${theme.colors.promote[1]}` })}
        >
          <Text size="sm">
            Re-link your account to continue with the transfer.
          </Text>
        </Box>
      )}

      <Flex justify="space-between" gap="lg" mt="xl">
        <Text size="sm">
          {`By clicking "Transfer funds", I authorize Flex to initiate the transaction
          detailed above.`}
        </Text>
        <Flex gap="xl">
          <Button variant="neutral-outline">Cancel</Button>
          <Button loading={isLoadingRequest} onClick={handleTransferFunds}>
            Transfer funds
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TransferFunds;

const useInputStyles = createStyles(
  (theme, { amount }: { amount: number }) => ({
    input: {
      border: 'none',
      borderRadius: 0,
      fontSize: rem(40),
      height: 'fit-content',
      borderBottom: `1px solid ${theme.colors.neutral[4]}`,
      color: amount === 0 ? theme.colors.neutral[6] : theme.colors.neutral[10],
    },
  }),
);
