import { Box, Button, Grid } from '@mantine/core';
import FullScreenModalContainer from './composites/modal/full-screen-modal-container';
import {
  IssueCreditCardForm,
  useIssueCreditCardForm,
} from 'areas/cards/issue-card/issue-credit-card-form';
import { useRecoilValue } from 'recoil';
import { ApplicationState } from 'recoil-state/application/product-onboarding';
import { useCreditBalance } from '@queries/use-credit-balance';
import { useGetUsers } from '@queries/use-users';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import {
  useCreateCardCategory,
  useIssueCard,
  useNet60CreditCards,
} from '@queries/use-credit-cards';
import { formatCurrency } from '@utilities/formatters';
import { RequiredFieldValidator } from '@utilities/validators';
import { RFCDate } from '@flexbase-eng/sdk-typescript/types';
import { useStyles } from './cards-styles';
import { v4 as uuidv4 } from 'uuid';
import {
  CardFulfillment,
  SpendRestriction,
} from '@flexbase-eng/sdk-typescript/models/components';
import { isTruthyString } from '@utilities/validators/validate-string';
import { useCardTypes } from '@queries/use-credit-programs';

type IssueCreditCardProps = {
  /**
   * The company's card program to which the card will be associated.
   */
  programId: string;

  /**
   * The line of credit to which the card will be issued.
   *
   * eg. marqeta, lithic, unit, etc.
   */
  lineOfCreditId: string;

  onClose: () => void;
};

export const IssueCreditCard = ({
  lineOfCreditId,
  programId,
  onClose,
}: IssueCreditCardProps) => {
  const { classes } = useStyles();
  const { accountId, businessId, personId } = useRecoilValue(ApplicationState);
  const { connectionId = '' } = useActiveExpenseLink();
  const { data: users = [] } = useGetUsers();
  const { data: creditBalance } = useCreditBalance(businessId);
  const { data: creditCards } = useNet60CreditCards();
  const { data: cardTypes } = useCardTypes(personId);
  const { mutate: issueCard } = useIssueCard();
  const { mutate: addCardExpenseCategory } = useCreateCardCategory();

  const form = useIssueCreditCardForm({
    initialValues: {
      userId: '',
      cardType: 'virtual',
      cardName: '',
      spendingLimit: 0,
      spendingLimitInterval: undefined,
      groups: [],
      categoryId: '',
      terminateAt: null,
      limitMerchantCategories: 'no',
      autoExpire: 'no',
    },
    validate: {
      spendingLimit: (value, values) => {
        if (values.spendingLimitInterval !== 'unlimited' && value <= 0) {
          return 'Spending limit must be greater than 0 unless limit type is unlimited.';
        }
        if (creditBalance?.creditLimit && value > creditBalance.creditLimit) {
          return `Limit amount cannot exceed credit limit of ${formatCurrency(
            creditBalance.creditLimit,
          )}`;
        }
        return null;
      },
      cardName: RequiredFieldValidator('Card name is required.'),
      cardType: (value, values) => {
        if (value !== 'physical') {
          return null;
        }

        const [memberName] =
          users
            .filter((u) => u.id === values.userId)
            .map((u) => `${u.firstName} ${u.lastName}`) || [];
        const activePhysicalCards =
          creditCards?.filter(
            (c) =>
              c.userId === values.userId &&
              c.cardType === 'PHYSICAL' &&
              c.status !== 'terminated',
          ) || [];

        return activePhysicalCards.length
          ? `${memberName || 'Selected member'} already has an active physical card. You must cancel that card before ordering a new one.`
          : null;
      },
      userId: RequiredFieldValidator('A user must be selected for this card'),
      spendingLimitInterval: RequiredFieldValidator('Select a limit type'),
    },
  });

  const handleClose = () => {
    onClose();
  };

  const handleSave = form.onSubmit((formValues) => {
    const cardTypeId =
      cardTypes?.find((ct) => {
        return (
          ct.programId === programId && ct.corporeality === formValues.cardType
        );
      })?.id || '';

    const fulfillment: CardFulfillment | undefined = (() => {
      // only required when issuing a physical card
      if (formValues.cardType !== 'physical') {
        return undefined;
      }

      const user = users.find((u) => (u.id = formValues.userId));

      if (!user) {
        return undefined;
      }

      return {
        shippingAddress: {
          address: {
            street: [user.address, user.addressLine2].filter(isTruthyString),
            locality: user.city || '',
            region: user.state || '',
            postalCode: user.postalCode || '',
            country: user.country || '',
          },
        },
      };
    })();

    const limits: SpendRestriction = (() => {
      const isUnlimited = formValues.spendingLimitInterval === 'unlimited';

      return {
        amount: isUnlimited ? undefined : formValues.spendingLimit,
        interval: formValues.spendingLimitInterval,
        groups: formValues.groups,
      };
    })();

    issueCard(
      {
        accountId,
        personId: formValues.userId,
        issueCard: {
          programId,
          lineOfCreditId,
          cardTypeId,
          idempotencyKey: uuidv4(),
          name: formValues.cardName,
          form: formValues.cardType,
          suspendDate: formValues.terminateAt
            ? new RFCDate(formValues.terminateAt)
            : undefined,
          fulfillment: fulfillment,
          limits: limits,
        },
      },
      {
        onSuccess: (issuedCard) => {
          if (formValues.categoryId) {
            addCardExpenseCategory(
              {
                connectionId,
                categoryId: formValues.categoryId,
                cardName: issuedCard.name || '',
                userId: issuedCard.personId,
              },
              {
                onSuccess: () => {
                  onClose();
                },
              },
            );
          } else {
            onClose();
          }
        },
      },
    );
  });

  return (
    <FullScreenModalContainer closeModal={handleClose}>
      <Box
        className={classes.issueCardContainer}
        w="100%"
        data-testid="issue-card"
      >
        <IssueCreditCardForm form={form} />

        <Grid
          className="buttons-container"
          justify="space-between"
          align="center"
          mt="1rem"
          gutter={0}
        >
          <Grid.Col span={12}>
            <Button
              loading={false}
              onClick={() => handleSave()}
              fullWidth
              className="btn-create-card"
            >
              Create Card
            </Button>
          </Grid.Col>
        </Grid>
      </Box>
    </FullScreenModalContainer>
  );
};
