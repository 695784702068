import { rem, Select, Stack } from '@mantine/core';
import QualificationPageShell from './components/qualification-page-shell';

import { Country } from 'country-state-city';
import { useNavigate } from 'react-router-dom';
import { PiMagnifyingGlass } from 'react-icons/pi';
import { useState } from 'react';
const countries = Country.getAllCountries().map((c) => ({
  label: c.name,
  value: c.isoCode,
}));

const QualificationCompanyLocationPage = () => {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState<string | null>();

  const handleContinueClick = () => {
    navigate('/qualification/company-revenue');
  };

  const handleBackClick = () => {
    navigate('/qualification');
  };

  return (
    <QualificationPageShell
      titleText="Where is your company based?"
      subtitleText="Either your headquarters, principal place of business, or where most of your employees are located."
      progress={25}
      handleContinueClick={selectedCountry ? handleContinueClick : undefined}
      handleBackClick={handleBackClick}
    >
      <Stack px={{ base: 'xxxl', sm: 0 }}>
        <Select
          label="Country of primary operation"
          styles={{ label: { fontSize: `${rem(16)} !important` } }}
          placeholder="Select country"
          leftSection={<PiMagnifyingGlass />}
          searchable
          value={selectedCountry}
          onChange={(value) => setSelectedCountry(value)}
          data={countries}
        />
      </Stack>
    </QualificationPageShell>
  );
};

export default QualificationCompanyLocationPage;
