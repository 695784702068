import { Flex, Grid, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';
import { useMediaQuery } from '@mantine/hooks';

type StylesProps = {
  borderBottomSolid: boolean;
};

const useStyles = createStyles((theme, { borderBottomSolid }: StylesProps) => ({
  container: {
    padding: rem(12),
    backgroundColor: theme.colors.neutral[0],
    borderRadius: theme.defaultRadius,
    maxWidth: rem(1365),
    margin: 'auto',
    marginTop: rem(20),
  },
  header: {
    borderBottom: borderBottomSolid ? 'solid' : 'none',
    borderBottomColor: theme.colors.neutral[2],
    borderBottomWidth: rem(0.5),
    padding: rem(16),
  },
}));

type Props = {
  leftHeaderContent: React.ReactNode;
  rightHeaderContent: React.ReactNode;
  table: React.ReactNode;
  borderBottomSolid?: boolean;
};

const TabTableLayout = ({
  leftHeaderContent,
  rightHeaderContent,
  table,
  borderBottomSolid = true,
}: Props) => {
  const { classes } = useStyles({ borderBottomSolid });
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Grid
          gutter={'xs'}
          justify="space-between"
          mb={isMobile ? rem(10) : rem(24)}
        >
          <Grid.Col span={isMobile ? 12 : 6}>{leftHeaderContent}</Grid.Col>
          <Grid.Col span={isMobile ? 12 : 6}>
            <Flex justify={isMobile ? 'flex-start' : 'flex-end'}>
              {rightHeaderContent}
            </Flex>
          </Grid.Col>
        </Grid>
      </div>
      {table}
    </div>
  );
};

export default TabTableLayout;
