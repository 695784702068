import { rem, Text } from '@mantine/core';

import { ChartIcon } from 'assets/svg';
import { useStyles } from './styles';

const BusinessChecking = () => {
  const { classes } = useStyles();

  return (
    <div className={classes.card}>
      <div className={classes.rowContent}>
        <div>
          <Text fw={500} fz={rem(12)}>
            Cash over time
          </Text>
          <Text fw={400} fz={rem(8)} color="neutral.6">
            Aug 1, 2023 - Aug 31, 2023
          </Text>
        </div>

        <div>
          <Text fw={500} fz={rem(12)} color="primary.2">
            +$2,415.83
          </Text>
          <Text fw={400} fz={rem(8)} color="neutral.6">
            Last month return
          </Text>
        </div>
      </div>

      <ChartIcon />
    </div>
  );
};

export default BusinessChecking;
