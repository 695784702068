import { queryOptions, useQuery } from '@tanstack/react-query';
import { platformSdk } from '@services/platform-sdk';
import { Line } from '@services/flexbase/credit.model';
import { platformClient } from '@services/platform/platform-client';

const creditLinesOptions = (businessId: string) => {
  return queryOptions({
    queryKey: ['credit', 'lines', 'list', businessId],
    queryFn: async (): Promise<Line[]> => {
      try {
        // TODO: replace with SDK once types are fixed in the backend
        const response = await platformClient.getLinesOfCredit(businessId);
        // const response = await platformSdk.entries.getLinesByBusiness({
        //   businessId,
        // });

        return response.data;
      } catch {
        const response = await platformSdk.entries.getLinesByBusiness({
          businessId,
        });

        return response.data;
      }
    },
    meta: {
      errorMessage: 'Could not retrieve card programs at this time.',
    },
    enabled: !!businessId,
  });
};

export const useCreditLines = (businessId: string) => {
  return useQuery(creditLinesOptions(businessId));
};

export const useUnitCreditLines = (businessId: string) => {
  return useQuery({
    ...creditLinesOptions(businessId),
    select: (lines) => lines.filter((line) => line.issuer === 'unit'),
  });
};

export const useBillpayCreditLines = (businessId: string) => {
  return useQuery({
    ...creditLinesOptions(businessId),
    select: (lines) => lines.filter((line) => line.issuer === 'billpay'),
  });
};
