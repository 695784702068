import Money from 'currency.js';
import FlagIcon from 'areas/payments/components/send-payment/flag-icon';
import { Alert, Box, Text, rem } from '@mantine/core';
import { useState } from 'react';
import FlexNumberInput from '../../../../../components/flex-number-input';
import { AvailableCurrencies } from '../international-payments/util/types';
import { PiInfoLight } from 'react-icons/pi';
import { useGetCurrencyConversionRate } from '@queries/use-international-payments';
import { useDebouncedValue } from '@mantine/hooks';
import { supportedCurrenciesData } from '@utilities/international-payments';

type CurrencyDetailsProps = {
  currency: AvailableCurrencies;
};

const CurrencyDetails = ({ currency }: CurrencyDetailsProps) => {
  const currencyInfo = supportedCurrenciesData.find(
    (c) => c.value === currency,
  );
  const flag = currencyInfo?.code ?? 'US';
  const abbreviatedName = currencyInfo?.value ?? 'USD';
  return (
    <Box mr="3rem" display="flex" style={{ alignItems: 'center' }}>
      <FlagIcon flagNationCode={flag} />
      <Text ml="0.3rem">{abbreviatedName}</Text>
    </Box>
  );
};

type Props = {
  currency: Omit<AvailableCurrencies, 'USD'>;
  onChange: (num: number) => void;
  error?: React.ReactNode;
  value?: number;
};

const CurrencyConversionInputs = ({
  currency,
  onChange,
  error,
  value,
}: Props) => {
  const [usd, setUsd] = useState<number | undefined>();
  const [debounced] = useDebouncedValue(value, 300);
  const { data, isLoading } = useGetCurrencyConversionRate({
    toCurrency: currency as AvailableCurrencies,
    // only ask for an amount if the USD cache is unset
    amount: usd ? undefined : debounced || undefined,
  });

  const currencySymbol =
    supportedCurrenciesData.find((c) => c.value === currency)?.symbol ?? '';

  // When the recipient's amount changes, unset the USD cache and emit a change event
  const handleSelectedCurrencyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUsd(undefined);
    onChange(Money(event.target.value).value);
  };

  // When the sender's amount changes, cache the USD amount and emit an approximate recipient value
  const handleUSDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const nextUsd = Money(event.target.value);
    setUsd(nextUsd.value);
    onChange(nextUsd.multiply(data?.rate ?? 1).value);
  };

  return (
    <>
      <FlexNumberInput
        label="Recipient gets"
        placeholder="e.g. 20,000"
        value={value === 0 ? '' : value}
        thousandSeparator=","
        leftSection={currencySymbol}
        onChange={handleSelectedCurrencyChange}
        rightSection={
          <CurrencyDetails currency={currency as AvailableCurrencies} />
        }
        decimalScale={2}
        data-testid="recipient-receives"
      />
      <FlexNumberInput
        label="You send"
        placeholder="e.g. 20,000"
        value={data?.amount ?? (usd === 0 ? '' : usd) ?? ''}
        thousandSeparator=","
        leftSection={'$'}
        onChange={handleUSDChange}
        rightSection={<CurrencyDetails currency={'USD'} />}
        decimalScale={2}
        error={error}
        disabled={isLoading}
        data-testid="customer-sends"
      />
      <Alert
        variant="primary-light"
        mt="1rem"
        color="info"
        icon={<PiInfoLight style={{ width: rem(60), height: rem(60) }} />}
        withCloseButton={false}
      >
        <Text size={'sm'} data-testid="conversion-alert">
          This is the best estimate we have on the USD to {currency} exchange
          rate. The recipient amount is fixed, and the total USD needed to send
          that amount will be finalized when the payment is processed. We
          partner with CurrencyCloud to provide competitive exchange rates for
          most currencies.
        </Text>
      </Alert>
    </>
  );
};

export default CurrencyConversionInputs;
