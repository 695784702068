import { rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

export const useStyles = createStyles((theme) => ({
  card: {
    height: rem(200),
    color: theme.primaryColor,
    backgroundColor: theme.colors.neutral[0],
  },
  alingCenter: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  rowContent: {
    display: 'flex',
    padding: rem(10),
    justifyContent: 'space-between',
  },
  witheCard: {
    gap: rem(12),
    display: 'flex',
    height: rem(65),
    borderRadius: rem(8),
    padding: rem(10),
    maxWidth: rem(270),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.neutral[0],
  },
  iconContainer: {
    width: rem(32),
    height: rem(32),
    display: 'flex',
    alignItems: 'center',
    borderRadius: '100%',
    justifyContent: 'center',
    backgroundColor: theme.primaryColor,
  },
  backCard: {
    height: rem(12),
    width: rem(200),
    borderBottomLeftRadius: rem(6),
    borderBottomRightRadius: rem(6),
    backgroundColor: '#798580', // not in theme
  },
  backSecondCard: {
    height: rem(12),
    width: rem(180),
    borderBottomLeftRadius: rem(5),
    borderBottomRightRadius: rem(5),
    backgroundColor: '#44544E', // not in theme
  },
}));
