import {
  Box,
  Collapse,
  ComboboxItem,
  Group,
  Radio,
  Stack,
  Title,
  useMantineTheme,
  Select,
  TextInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { useMemo } from 'react';
import { Group as CardInfoGroup } from 'constants/card-info';
import { CustomMantineStyles } from '@common/cards-styles';
import { CreditCardInterval, LIMIT_INTERVALS } from 'constants/limit-intervals';
import { CalendarIcon } from '../../../assets/svg';
import { DatePickerInput, DateValue } from '@mantine/dates';
import { DateTime } from 'luxon';
import CardCategorySelect from '@common/composites/card-category-select';
import FlexNumberInput from '../../../components/flex-number-input';
import { useActiveExpenseLink } from '@utilities/integrations/accounting';
import Multiselect from '@common/custom-multiselect';
import { useGetUsers } from '@queries/use-users';
import { NumberFormatValues } from 'react-number-format';

type CardType = 'physical' | 'virtual';
type YesNo = 'yes' | 'no';

type IssueCreditCardFormType = {
  userId: string;
  cardType: CardType;
  cardName: string;
  spendingLimit: number;
  spendingLimitInterval: CreditCardInterval | undefined;
  groups: CardInfoGroup[];
  categoryId?: string;
  terminateAt: string | null;
  limitMerchantCategories: YesNo;
  autoExpire: YesNo;
};

const STACK_SPACING = 'lg';
const RADIO_SPACING = 'lg';

type IssueCardFormProps = {
  form: UseFormReturnType<IssueCreditCardFormType>;
  title?: React.ReactNode;
  withMemberSelect?: boolean;
};

export const useIssueCreditCardForm = useForm<IssueCreditCardFormType>;

export const IssueCreditCardForm = ({
  form,
  title = 'Create a new credit card',
  withMemberSelect = true,
}: IssueCardFormProps) => {
  const theme = useMantineTheme();
  const { data: users } = useGetUsers();
  const { expenseLink } = useActiveExpenseLink();

  const teamMemberOptions: ComboboxItem[] = useMemo(() => {
    // check if still fetching data
    if (!users) {
      return [];
    }

    // only consider active onboarded users
    const activeUsers = users.filter(
      (u) =>
        u.completedOnboarding &&
        !u.completedOffboarding &&
        u.status === 'active',
    );

    // map to select options
    const availableUsers: ComboboxItem[] = activeUsers.map((u) => ({
      value: u.id!,
      label: `${u.firstName} ${u.lastName}`,
    }));

    return availableUsers;
  }, [users]);

  const minimumTerminationDate = DateTime.now().plus({ days: 1 }).toJSDate();

  const handleFrequencyChange = (e: string | null) => {
    form.setFieldValue('spendingLimit', 0);

    if (e) {
      form.setFieldValue('spendingLimitInterval', e as CreditCardInterval);
    }
  };

  const handleLimitAmountChange = (value: NumberFormatValues) => {
    form.setFieldValue('spendingLimit', value.floatValue ?? 0);
  };

  const handleTerminationDateChange = (d: DateValue) => {
    if (d) {
      const date = DateTime.fromJSDate(d);
      form.setFieldValue('terminateAt', date.toFormat('yyyy-MM-dd'));
    } else {
      form.setFieldValue('terminateAt', null);
    }
  };

  return (
    <Stack gap={STACK_SPACING}>
      <Title
        style={{
          textAlign: 'start',
          fontSize: '28px',
          fontFamily: 'PP Neue Montreal',
          fontWeight: 400,
        }}
      >
        {title}
      </Title>

      {!!withMemberSelect && (
        <Select
          label="Team member"
          searchable
          data={teamMemberOptions}
          radius={8}
          maxDropdownHeight={280}
          data-testid="select-employee"
          placeholder="Select employee"
          {...form.getInputProps('userId')}
        />
      )}

      <TextInput
        label="Card purpose"
        data-testid="card-name"
        placeholder="e.g. Advertising expenses"
        {...form.getInputProps('cardName')}
      />

      <Radio.Group
        label="Card type"
        size="sm"
        {...form.getInputProps('cardType')}
        color={theme.primaryColor}
        aria-label="card-type"
      >
        <Group mt="xxs" gap={RADIO_SPACING}>
          <Radio
            styles={{ body: CustomMantineStyles().issueCard.radio.body }}
            aria-label="physical-card"
            value="physical"
            label="Physical"
          />
          <Radio
            styles={{ body: CustomMantineStyles().issueCard.radio.body }}
            aria-label="virtual-card"
            value="virtual"
            label="Virtual"
          />
        </Group>
      </Radio.Group>

      <Select
        label="Limit frequency"
        placeholder="Select frequency"
        data={LIMIT_INTERVALS}
        radius={8}
        maxDropdownHeight={280}
        data-testid="select-limit-type"
        {...form.getInputProps('spendingLimitInterval')}
        onChange={handleFrequencyChange}
      />

      {form.values.spendingLimitInterval !== 'unlimited' && (
        <FlexNumberInput
          label="Limit"
          variant="default"
          radius={8}
          leftSection={<>$</>}
          pattern="[0-9]*"
          thousandSeparator=","
          data-testid="amount"
          onValueChange={handleLimitAmountChange}
          value={form.values.spendingLimit}
          error={form.errors.spendingLimit}
        />
      )}

      {expenseLink?.enabledExpenses && (
        <CardCategorySelect {...form.getInputProps('categoryId')} />
      )}

      <Box>
        {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
        <Radio.Group
          label="Restrict spending to specific categories?"
          size="sm"
          color={theme.primaryColor}
          {...form.getInputProps('limitMerchantCategories')}
        >
          <Group gap={RADIO_SPACING} mt="xxs">
            <Radio
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="categoriesYes"
              value="yes"
              label="Yes"
            />
            <Radio
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="categoriesNo"
              value="no"
              label="No"
            />
          </Group>
        </Radio.Group>

        <Box>
          {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
          <Collapse in={form.values.limitMerchantCategories === 'yes'}>
            <Box mt={STACK_SPACING}>
              <Multiselect form={form} />
            </Box>
          </Collapse>
        </Box>
      </Box>

      <Box>
        {/** Wrap in <Box /> so the Stack gap is correct when the child <Collapse /> is closed */}
        <Radio.Group
          label="Set termination date?"
          size="sm"
          color={theme.primaryColor}
          {...form.getInputProps('autoExpire')}
        >
          <Group gap={RADIO_SPACING} mt="xxs">
            <Radio
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="autoExpireYes"
              value="yes"
              label="Yes"
            />
            <Radio
              styles={{ body: CustomMantineStyles().issueCard.radio.body }}
              aria-label="autoExpireNo"
              value="no"
              label="No"
            />
          </Group>
        </Radio.Group>

        <Box>
          {' '}
          {/** Wrap <Collapse/> in a <Box/> because the animation doesn't play well when parent is flex */}
          <Collapse in={form.values.autoExpire === 'yes'}>
            <DatePickerInput
              leftSection={
                <CalendarIcon
                  color={theme.colors.neutral[8]}
                  height="20px"
                  width="20px"
                />
              }
              mt={STACK_SPACING}
              clearable
              valueFormat="YYYY-MM-DD"
              label="Termination date"
              minDate={minimumTerminationDate}
              onChange={handleTerminationDateChange}
            />
          </Collapse>
        </Box>
      </Box>
    </Stack>
  );
};
