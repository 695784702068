import { rem, Select, Stack, useMantineTheme } from '@mantine/core';
import QualificationPageShell from './components/qualification-page-shell';
import { useNavigate } from 'react-router-dom';

const BUSINESS_ANNUAL_REVENUE_QUALIFICATION = [
  '$0 - $120,000',
  '$120,000 - $250,000',
  '$250,000 - $500,000',
  '$500,000 - $2,000,000',
  '$2,000,000 - $5,000,000',
  'Over $5,000,000',
];
const BUSINESS_REVENUE_AGE = [
  '0 - 6 months',
  '6 - 12 months',
  '12 - 18 months',
  '18+ months',
];

const QualificationCompanyRevenuePage = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const handleBackClick = () => {
    navigate('/qualification/company-location');
  };

  return (
    <QualificationPageShell
      titleText="Revenue"
      progress={25}
      handleBackClick={handleBackClick}
    >
      <Stack px={{ base: 'xxxl', sm: 0 }}>
        <Select
          label="How long have you been generating revenue?"
          description="This helps us understand the age of your business."
          styles={{
            label: {
              fontSize: `${rem(16)} !important`,
            },
            description: {
              color: theme.colors.neutral[7],
              fontSize: `${rem(14)} !important`,
            },
          }}
          placeholder="Select"
          data={BUSINESS_REVENUE_AGE.map((r) => ({ label: r, value: r }))}
        />
        <Select
          label="What was your revenue over the past 12 months?"
          description="Gross revenue helps us better understand the overall size and scale of your business."
          styles={{
            label: {
              fontSize: `${rem(16)} !important`,
            },
            description: {
              color: theme.colors.neutral[7],
              fontSize: `${rem(14)} !important`,
            },
          }}
          placeholder="Select"
          data={BUSINESS_ANNUAL_REVENUE_QUALIFICATION.map((r) => ({
            label: r,
            value: r,
          }))}
        />
      </Stack>
    </QualificationPageShell>
  );
};

export default QualificationCompanyRevenuePage;
