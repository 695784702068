import { MantineTheme, rem } from '@mantine/core';
import { createStyles } from '@mantine/emotion';

/*
Base styles to use for the core @mantine components.
*/
export const useStyles = createStyles({
  mainContainer: {
    minHeight: '100vh',
    paddingLeft: rem(32),
    paddingRight: rem(32),
    '@media(max-width: 767px)': {
      padding: 0,
      minHeight: `calc(100vh - ${rem(76)})`, // Header height + padding on appshell
      height: `max(100vh - ${rem(76)}, 100%)`, // so height: 100% works
    },
  },
});

// Note: we use react-data-table for tables with the formatting used in
// customStyles: https://react-data-table-component.netlify.app/?path=/docs/api-custom-styles--page
export const baseTableStyles = (theme?: MantineTheme) => {
  return {
    table: {
      style: {
        borderTop: `${rem(1)} solid ${theme?.colors.neutral[2]}`,
        color: theme?.colors.neutral[8],
        fontSize: rem(14),
        lineHeight: rem(21),
        width: '100%',
        button: {
          border: 0,
          display: 'flex',
          cursor: 'pointer',
          backgroundColor: 'unset',
        },
        '@media(max-width: 767px)': {
          width: 'max-content',
        },
        accentColor: theme?.colors.primary[6],
      },
    },
    cells: {
      style: {
        color: theme?.colors.neutral[8],
        fontSize: rem(14),
        fontWeight: 400,
        lineHeight: rem(21),
        alignItems: 'center',
      },
    },
    headCells: {
      style: {
        color: theme?.primaryColor,
        fontWeight: 500,
        display: 'flex',
      },
    },
    rows: {
      style: {
        fontSize: rem(14),
        fontWeight: 400,
        color: theme?.colors.neutral[8],
        lineHeight: rem(21),
        minHeight: rem(68),
        '&:hover': {
          background: theme?.colors.neutral[3],
          cursor: 'pointer',
        },
      },
      stripedStyle: {
        backgroundColor: 'unset',
      },
    },
    highlightOnHoverStyle: {
      backgroundColor: theme?.colors.neutral[3],
      span: {
        color: theme?.colors.neutral[3],
      },
      svg: {
        fill: theme?.colors.neutral[3],
      },
    },
    pagination: {
      style: {
        '@media(max-width: 767px)': {
          fontSize: rem(12),
          paddingTop: rem(12),
          '> span:first-of-type': {
            width: rem(288),
            margin: `0 ${rem(10)}`,
          },
          '> span': {
            width: rem(393),
            margin: `0 ${rem(1)}`,
          },
        },
      },
      pageButtonsStyle: {
        '@media(max-width: 767px)': {
          height: rem(40),
          width: rem(25),
        },
      },
    },
  };
};
