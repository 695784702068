import {
  Button,
  Divider,
  Group,
  Progress,
  useMantineTheme,
} from '@mantine/core';
import AddressVerification from '../steps/address-verification/address-verification';
import { useParams } from 'react-router-dom';
import VerifyIdentity from '../steps/verify-identity/verify-identity';
import VerifyPhone from '../steps/verify-phone/verify-phone';
import Summary from '../steps/summary/summary';
import BusinessType from '../steps/business-type/business-type';
import BusinessActivity from '../steps/business-activity/business-activity';
import VerifyBusiness from '../steps/verify-business/verify-business';
import { useMediaQuery } from '@mantine/hooks';
import VerifyBank from '../steps/verify-bank';
import BeneficialOwnersNew from '../steps/owners/beneficial-owners-new';
import ErrorEndScreen from './end-states/error-end-screen';
import DocUpload from '../steps/doc-upload';
import { OnboardingRouteNames } from '../../../recoil-state/application/product-onboarding.models';
import TreasuryAllocation from '../steps/treasury-allocation';
import { ContainerWithFooter } from '@common/utilities/footer/footer-container';
import ChangePasswordPage from '../steps/change-password/change-password';
import { CheckCircleProgress, LockIcon, LogOutIcon } from '../../../assets/svg';
import JobTitle from '../steps/job-title';
import ControlPerson from '../steps/control-person/control-person';
import FlexIconLink from '@common/composites/flex-icon-link';
import { AdditionalIdentity } from '../steps/additional-identity/additional-identity';
import { useApplicationFlowContext } from '../onboarding-hooks';
import { IntendedCountries } from '../steps/intended-countries';
import Officers from '../steps/officers/officers';
import { useRouteSectionContext } from '../../../providers/route-context';
import { useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ApplicationState,
  OptedProduct,
} from '../../../recoil-state/application/product-onboarding';
import { SardineContext } from '@sardine-ai/react-js-wrapper';
import { useAuthToken } from 'providers/auth.provider';
import { AddFactorsProvider } from '../../../providers/add-factors.context';
import { useOnboardingStepperStyles } from '@utilities/custom-hooks/use-onboarding-stepper-styles';

type CurrentStepProps = { step?: OnboardingRouteNames };
const CurrentStep = ({ step }: CurrentStepProps) => {
  switch (step) {
    case 'change-password':
      return <ChangePasswordPage />;
    case 'user-address':
      return <AddressVerification />;
    case 'verify-identity':
      return <VerifyIdentity />;
    case 'verify-phone':
      return (
        <AddFactorsProvider>
          <VerifyPhone />
        </AddFactorsProvider>
      );
    case 'additional-identity':
      return <AdditionalIdentity />;
    case 'intended-countries':
      return <IntendedCountries />;
    case 'business-officers':
      return <Officers />;
    case 'business-type':
      return <BusinessType />;
    case 'business-activity':
      return <BusinessActivity />;
    case 'verify-business':
      return <VerifyBusiness />;
    case 'verify-bank':
      return <VerifyBank />;
    case 'summary':
      return <Summary />;
    case 'business-owners':
      return <BeneficialOwnersNew />;
    case 'control-person':
      return <ControlPerson />;
    case 'doc-upload-id':
      return <DocUpload documentType={'id'} key={'id'} />;
    case 'doc-upload-tax':
      return <DocUpload documentType={'tax'} key={'tax'} />;
    case 'doc-upload-csa':
      return <DocUpload documentType={'csa'} key={'csa'} />;
    case 'treasury-allocation':
      return <TreasuryAllocation />;
    case 'job-title':
      return <JobTitle />;
    default:
      return <ErrorEndScreen />;
  }
};

const OnboardingV2 = () => {
  const { logout } = useAuthToken();
  const theme = useMantineTheme();
  const { applyingForProducts } = useApplicationFlowContext();
  const { user } = useRecoilValue(ApplicationState);
  const { updateSardineConfig } = useContext(SardineContext);
  const { step } = useParams() as CurrentStepProps;
  const { classes, cx } = useOnboardingStepperStyles();
  const isMobileScreenSize = useMediaQuery('(max-width: 767px)');
  const isTooWide = useMediaQuery('(max-width: 550px)');
  const { progress: currentProgress } = useApplicationFlowContext();
  const { setSectionAndNavigate } = useRouteSectionContext();
  const { completedOnboarding, productStatus } =
    useRecoilValue(ApplicationState);

  const showNoImpactToCredit =
    step !== 'product-select' &&
    step !== 'change-password' &&
    !isMobileScreenSize;

  const showProgressSaved =
    step !== 'product-select' && step !== 'change-password' && !isTooWide;

  const showExit =
    !!completedOnboarding &&
    (!!productStatus.credit.creditLimit ||
      productStatus.banking.status === 'approved');

  const handleExitClick = () => {
    if (showExit) {
      setSectionAndNavigate('main', '/');
    } else {
      logout();
    }
  };

  const generateApplicationName = (optedProducts: OptedProduct[]): string => {
    if (optedProducts.length === 0) {
      return '';
    } else {
      let prefix = 'APPLICATION';
      if (optedProducts.includes('CREDIT')) {
        prefix += '-CREDIT';
      }
      if (optedProducts.includes('BANKING')) {
        prefix += '-BANKING';
      }
      return prefix;
    }
  };

  useEffect(() => {
    if (generateApplicationName(applyingForProducts)) {
      updateSardineConfig({
        customerId: user.email,
        sessionKey: user.id,
        flow: generateApplicationName(applyingForProducts),
        enableBiometrics: true,
      });
    }
  }, []);

  return (
    <ContainerWithFooter
      disclaimer2={true}
      className={classes.footerContainer}
      classNames={{
        container: classes.footerContainer,
        footerDiscloure: classes.footerDisclosure,
        scrollContainer: classes.scrollContainer,
      }}
    >
      <div className={classes.header}>
        <FlexIconLink width={68} />
        {showProgressSaved && (
          <div className={classes.headerActions}>
            <div className={classes.notices}>
              <Group gap="sm" className={classes.creditImpactNotice}>
                <CheckCircleProgress color={theme.colors.primary[6]} />
                <div className={cx(classes.headerText, classes.progressSaved)}>
                  Progress saved!
                </div>
              </Group>
            </div>
          </div>
        )}
        {step !== 'summary' && (
          <div className={classes.containerLogout}>
            {showNoImpactToCredit && (
              <>
                <div className={classes.headerText}>
                  <LockIcon /> No impact to credit
                </div>
                <Divider orientation="vertical" size="0.15rem" />
              </>
            )}
            <Button
              onClick={handleExitClick}
              leftSection={<LogOutIcon color="black" />}
              variant="neutral-outline"
              c="black"
            >
              {showExit ? 'Exit' : 'Log Out'}
            </Button>
          </div>
        )}
      </div>
      <Progress
        size="xs"
        color={theme.colors.primary[2]}
        value={currentProgress}
        classNames={{ root: classes.progressRoot }}
        h={2}
      />
      <div
        className={cx(
          classes.webContainer,
          isMobileScreenSize && classes.mobileContainer,
        )}
      >
        <CurrentStep step={step} />
      </div>
    </ContainerWithFooter>
  );
};

export default OnboardingV2;
