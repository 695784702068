import { useRecoilState } from 'recoil';
import { ApplicationState } from '../../../../../recoil-state/application/product-onboarding';
import { useForm } from '@mantine/form';
import { validateRequired } from '../../../../../utilities/validators/validate-required';
import { SummaryEditBox, SummaryEditBoxClassnames } from '../summary-edit-box';
import { useStyles } from '../summary.styles';
import { useOnboardingStyles } from '../../../onboarding.styles';
import { flexbaseOnboardingClient } from '../../../../../services/flexbase-client';
import { TextInput } from '@mantine/core';
type Props = { classNames?: SummaryEditBoxClassnames };

const SummaryBusinessNameForm = ({ classNames }: Props) => {
  const { classes } = useStyles();
  const { classes: onboardingClasses } = useOnboardingStyles();
  const [{ company }, setStatus] = useRecoilState(ApplicationState);
  const form = useForm({
    initialValues: {
      companyName: company.companyName,
    },
    validate: {
      companyName: (val) =>
        validateRequired(val) ? null : 'Business name is required',
    },
  });
  const onSave = async () => {
    const validationResult = form.validate();
    if (validationResult.hasErrors) {
      throw 'Please correct all errors before saving';
    }
    try {
      const { companyName } = form.values;
      await flexbaseOnboardingClient.updateCompany({
        id: company.id,
        companyName,
      });
      setStatus((prev) => ({
        ...prev,
        company: {
          ...prev.company,
          companyName,
        },
      }));
    } catch (e) {
      throw 'An error occurred while trying to update company name';
    }
  };

  return (
    <SummaryEditBox
      label={'Business name'}
      value={company.companyName}
      classNames={classNames}
      savePromise={onSave}
      editInputs={
        <div className={classes.inputContainer}>
          <TextInput
            placeholder="Business name"
            label="Business name"
            classNames={{ label: onboardingClasses.inputLabel }}
            className={classes.input}
            {...form.getInputProps('companyName')}
            id="summary-business-name"
          />
        </div>
      }
    />
  );
};

export default SummaryBusinessNameForm;
